import IDTOReservation from '@/api/api-reservations-dto';
import { IReservation } from '@/api/api-update';
import {
  dateFromDateTimeString, dateTimeStringFromDate, dateStringFromDate, dateFromUTCDateTimeSecondsString,
  minutesFromDate, slotFromMinutes, getDateHoursMinutes, setDateHoursMinutes, dateByAddingDays,
  timeIndexFromMinutes, dateTimeIndexFromDateAndTimeIndex, dateIndexFromDate, dateByAddingMinutes,
} from '@/services/time-utils';
import { boolFromValue } from '@/api/api-utils';
import { endDateForCheckedOut, endDateForReservation } from '@/services/reservation-utils';
import { fixedPaymentInfo } from '@/services/billing-utils';
import Tab from './Tab';
import TabItem from './TabItem';
import Contact, { ContactMapping } from './Contact';
import { cloneModel, toModelEntities } from './model-utils';
import Service from './Service';
import Label from './Label';
import Employee from './Employee';
import Country from './Country';
import DurationRule from './DurationRule';
import Attachment from './Attachment';

export enum ReservationSource {
  User = 'user',
  Online = 'online',
}

export enum ReservationType {
  Reservation = 'reservation',
  Walkin = 'walkin',
  Block = 'block',
  Waitlist = 'waitlist',
}

export enum ReservationStatus {
  Planned = 'PLANNED',
  Seated = 'SEATED',
  CheckedIn = 'CHECKED_IN',
  CheckedOut = 'CHECKED_OUT',
  Confirmed = 'CONFIRMED',
  NonConfirmed = 'NON_CONFIRMED',
  WaitingList = 'WAITING_LIST',
  Cancelled = 'CANCELLED',
  NoShow = 'NO_SHOW',
  Spam = 'SPAM',
  Deleted = 'DELETED',

  Separator = 'SEPARATOR',
  All = 'ALL',
  Valid = 'VALID',
}

export interface ReservationMapping {
  tabsById: Map<number, Tab>;
  tabItemsById: Map<number, TabItem>;
  servicesById: Map<number, Service>;
  employeesById: Map<number, Employee>;
  labelsByText: Map<string, Label>;
  countryByCode: Map<string, Country>;
}

export default class Reservation {
  id: number;

  tab: Tab | null;

  partySize: number;

  // dateAnte: Date | null;

  dateBegin: Date;

  dateEnd: Date;

  capDateEnd: Date;

  fixedEnding: boolean;

  holdTabItems: boolean = false;

  contact: Contact;

  reservationType: ReservationSource;

  bookingType: ReservationType;

  code: string | null;

  status: ReservationStatus;

  label: Label | null;

  service: Service | null;

  isStarred: boolean;

  isFlagged: boolean;

  isUnread: boolean;

  employeeCreated: Employee | null;

  employeeEdited: Employee | null;

  dateCreated: Date | null;

  dateEdited: Date | null;

  notes: string | null;

  tabItems: TabItem[];

  paymentInfo?: string;

  dateIndex: number = 0;

  timeBegin: number = 0;

  timeEnd: number = 0;

  capTimeEnd: number = 0;

  slotBegin: number = 0;

  slotEnd: number = 0;

  capSlotEnd: number = 0;

  reservationCampaign: string | null;

  isDeleted: boolean = false; // never deleted, to satisfy IWithDateTimeIndex

  attachments: Attachment[] = [];

  rating: number | null;

  constructor(o?: IReservation, m?: ReservationMapping) {
    this.id = o?.reservationId ?? 0;
    this.partySize = o?.partySize ?? 0;
    // this.dateAnte = dateFromDateTimeString(o?.dtAnte);
    this.dateBegin = dateFromDateTimeString(o?.dtBegin) ?? new Date();
    this.dateEnd = dateFromDateTimeString(o?.dtEnd) ?? new Date();
    this.capDateEnd = this.dateEnd;
    this.fixedEnding = boolFromValue(o?.fixedEnding) ?? false;
    this.holdTabItems = boolFromValue(o?.holdTabItems) ?? false;
    this.fixedEnding = boolFromValue(o?.fixedEnding) ?? false;
    this.reservationType = o?.reservationType as ReservationSource;
    // this.bookingType = o?.bookingType as ReservationType;
    this.code = o?.reservationCode ?? null;
    this.status = o?.reservationStatus as ReservationStatus ?? ReservationStatus.Planned;
    this.notes = o?.notes ?? null;
    this.isStarred = boolFromValue(o?.isStarred) ?? false;
    this.isFlagged = boolFromValue(o?.isFlagged) ?? false;
    this.isUnread = boolFromValue(o?.isUnread) ?? false;
    this.dateCreated = dateFromUTCDateTimeSecondsString(o?.dtCreate) ?? null;
    this.dateEdited = dateFromUTCDateTimeSecondsString(o?.dtEdit) ?? null;
    this.paymentInfo = o?.paymentInfo ? fixedPaymentInfo(o.paymentInfo) : undefined;

    this.tab = m?.tabsById.get(o?.tabId ?? NaN) ?? null;
    this.service = m?.servicesById.get(o?.serviceId ?? NaN) ?? null;
    this.employeeCreated = m?.employeesById.get(o?.employeeIdCreate ?? NaN) ?? null;
    this.employeeEdited = m?.employeesById.get(o?.employeeIdEdit ?? NaN) ?? null;
    this.reservationCampaign = o?.reservationCampaign ?? null;

    this.label = o?.reservationLabel
      ? m?.labelsByText.get(o?.reservationLabel) ?? new Label({ labelText: o?.reservationLabel })
      : null;

    const reservedTabItems = Array.isArray(o?.reservedTabItems) ? o?.reservedTabItems ?? [] : [];
    this.tabItems = reservedTabItems.flatMap((ti) => m?.tabItemsById?.get(ti) ?? []);
    this.tabItems.sort((o1, o2) => o1.order - o2.order);

    this.contact = new Contact(o?.contact, m as ContactMapping);
    this.attachments = (o?.attachments ?? [])?.map((a) => new Attachment(a));

    this.rating = o?.rating ?? null;

    this.bookingType = ReservationType.Reservation;
    this.updateBookingType();

    this.updateSlotsAndTimes();
  }

  static emptyReservation(): Reservation {
    const r = new Reservation();
    r.bookingType = ReservationType.Reservation;
    r.partySize = 2;
    return r;
  }

  refresh(m?: ReservationMapping) {
    this.tab = m?.tabsById.get(this.tab?.id ?? NaN) ?? null;
    this.service = m?.servicesById.get(this.service?.id ?? NaN) ?? null;
    this.employeeCreated = m?.employeesById.get(this.employeeCreated?.id ?? NaN) ?? null;
    this.employeeEdited = m?.employeesById.get(this.employeeEdited?.id ?? NaN) ?? null;

    this.label = m?.labelsByText.get(this.label?.text ?? '') ?? this.label;

    this.tabItems = this.tabItems.flatMap((ti) => m?.tabItemsById?.get(ti.id) ?? []);
    this.tabItems.sort((o1, o2) => o1.order - o2.order);

    this.contact.refresh(m);

    this.updateSlotsAndTimes();
  }

  updateBookingType() {
    const zstr = (s: string|undefined|null) => !s || s === '';

    if (this.partySize === 0) {
      this.bookingType = ReservationType.Block;
    } else if (zstr(this.contact.name) && zstr(this.contact.phone) && zstr(this.contact.email)) {
      this.bookingType = ReservationType.Walkin;
    } else if (this.status === ReservationStatus.WaitingList) {
      this.bookingType = ReservationType.Waitlist;
    } else {
      this.bookingType = ReservationType.Reservation;
    }
  }

  updateSlotsAndTimes() {
    this.dateIndex = dateIndexFromDate(this.dateBegin);
    this.timeBegin = minutesFromDate(this.dateBegin);
    this.timeEnd = minutesFromDate(this.dateEnd);
    this.slotBegin = slotFromMinutes(this.timeBegin);
    this.slotEnd = slotFromMinutes(this.timeEnd - 1);

    const bookingCap = this.partySize === 0 ? 0 : this.tab?.bookingCap ?? 0;
    this.capDateEnd = dateByAddingMinutes(this.dateEnd, bookingCap);
    this.capTimeEnd = minutesFromDate(this.capDateEnd);
    this.capSlotEnd = slotFromMinutes(this.capTimeEnd - 1);
  }

  updateEndDateForReservation(durationRules: DurationRule[]) {
    this.dateEnd = endDateForReservation(this.dateBegin, this.tab, this.partySize, durationRules);
    this.updateSlotsAndTimes();
  }

  // getters
  get nameText() { return this.contact && this.contact.name; }

  get notesText() { return this.notes; }

  get partySizeText() { return (this.partySize && `${String(this.partySize)}`) || ''; }

  get statusText() { return this.status; }

  get tabText() { return this.tab && this.tab.name; }

  get tablesText() {
    if (this.tabItems.length === 0) return undefined;
    return this.tabItems.map((ti) => ti.no).join(',');
  }

  get seats(): number {
    return this.tabItems.map((o) => o.seats).reduce((a, b) => a + b, 0);
  }

  get isBlock(): boolean {
    return this.bookingType === ReservationType.Block || this.partySize === 0;
  }

  get isWalkin(): boolean {
    return this.bookingType === ReservationType.Walkin;
  }

  get isNew(): boolean {
    return this.id <= 0;
  }

  get dateTimeIndex(): number {
    return dateTimeIndexFromDateAndTimeIndex(this.dateIndex, timeIndexFromMinutes(this.timeBegin));
  }

  get slot(): number {
    return this.slotBegin;
  }

  toDto(): IDTOReservation {
    const dto: IDTOReservation = {
      reservationId: this.id,
      tabId: this.tab?.id,
      partySize: this.partySize,
      dtBegin: dateTimeStringFromDate(this.dateBegin) ?? undefined,
      dtEnd: dateTimeStringFromDate(this.dateEnd) ?? undefined,
      fixedEnding: this.fixedEnding,
      holdTabItems: this.holdTabItems,
      reservationCode: this.code ?? undefined,
      reservationStatus: this.status,
      reservationLabel: this.label?.text,
      // bookingType: this.bookingType,
      serviceId: this.service?.id,
      isStarred: this.isStarred,
      isFlagged: this.isFlagged,
      isUnread: this.isUnread,
      notes: this.notes ?? undefined,
      reservedTabItems: this.tabItems.map((o) => o.id).join(','),
      reservationCampaign: this.reservationCampaign ?? undefined,

      // reservation unrelated
      employeeId: undefined,
      employeeName: undefined,
      forgetTabItems: undefined,

      // contact
      contactId: this.contact?.id || undefined,
      name: this.contact?.name ?? undefined,
      phone: this.contact?.phone ?? undefined,
      email: this.contact?.email,
      address: this.contact?.address,
      postalCode: this.contact?.postalCode,
      city: this.contact?.city,
      state: this.contact?.state,
      country: this.contact?.country?.code,
      companyName: this.contact?.companyName,
      isVIP: this.contact?.isVIP,
      preferredLanguage: this.contact?.preferredLanguage,
      dateOfBirth: dateStringFromDate(this.contact?.dateOfBirth) ?? undefined,
      newsletterSubscription: this.contact?.newsletterSubscription,
      contactNotes: this.contact?.notes,
      title: this.contact?.title,

      // attachments
      attachments: this.attachments.map((o) => o.toDto()),
    };
    return dto;
  }

  clone(): Reservation {
    const copy = new Reservation();
    Object.assign(copy, this);
    copy.tabItems = this.tabItems.map((o) => cloneModel(o));
    copy.contact = cloneModel(this.contact);
    return copy;
  }

  hasTabItem(tabItem: TabItem) {
    return this.tabItems.find((ti) => ti.id === tabItem.id) !== undefined;
  }

  addTabItem(tabItem: TabItem) {
    console.log('addTabItem');
    if (!this.tab || !this.tab.tabItems.find((ti) => ti.id === tabItem.id)) return;
    if (this.tabItems.find((ti) => ti.id === tabItem.id)) return;
    this.tabItems.push(tabItem);
    this.tabItems.sort((o1, o2) => o1.order - o2.order);
  }

  removeTabItem(tabItem: TabItem) {
    console.log('removeTabItem');
    const index = this.tabItems.findIndex((ti) => ti.id === tabItem.id);
    if (index >= 0) this.tabItems.splice(index, 1);
  }

  setTabItems(tabItems: TabItem[]) {
    console.log('setTabItems');
    if (!this.tab) return;
    this.tabItems = tabItems.filter((ti) => this.tab!.tabItems.find((tti) => tti.id === ti.id) !== undefined);
    this.tabItems.sort((o1, o2) => o1.order - o2.order);
  }

  setDate(date: Date) {
    const { h: bh, m: bm } = getDateHoursMinutes(this.dateBegin);
    const dateBegin = setDateHoursMinutes(date, bh, bm);
    this.dateBegin = dateBegin;

    const { h: eh, m: em } = getDateHoursMinutes(this.dateEnd);
    let dateEnd = setDateHoursMinutes(date, eh, em);
    if (eh < 6) dateEnd = dateByAddingDays(dateEnd, +1);
    this.dateEnd = dateEnd;
  }

  setStatus(status: ReservationStatus) {
    if (this.status !== ReservationStatus.CheckedOut && status === ReservationStatus.CheckedOut) {
      this.dateEnd = endDateForCheckedOut(this) ?? this.dateEnd;
      this.fixedEnding = false;
    }
    this.status = status;
  }
}
