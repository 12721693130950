/* eslint-disable no-param-reassign */
import GstplnApp, { GstplnAppCategory } from '@/components/apps/app';
import AppStoreApp from '@/model/AppStoreApp';
import Vue from 'vue';

import CampaignFilterApp from '@/components/apps/campaign-filter/campaign-filter';
import ExportApp from '@/components/apps/export/export';
import StripeApp from '@/components/apps/stripe/stripe';
import StripeConnectApp from '@/components/apps/stripe-connect/stripe-connect';
import DyflexisApp from '@/components/apps/dyflexis/dyflexis';
import BooqPlannerApp from '@/components/apps/booq-planner/booq-planner';
import SimpleCampaignApp from '@/components/apps/simpleCampaignApp/simpleCampaignApp';
import GiftcardsApp from '@/components/apps/giftcards/giftcards';
import AnnoncerApp from '@/components/apps/annoncer/annoncer';
import BookDinnersApp from '@/components/apps/bookdinners/bookdinners';

let apps: GstplnApp[] = [];

export function createApps(vue: Vue) {
  apps = [
    new CampaignFilterApp(vue),
    new ExportApp(vue),

    new SimpleCampaignApp(vue, 'app.actievandedag', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),

    new SimpleCampaignApp(vue, 'app.diningcity', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),

    new SimpleCampaignApp(vue, 'app.heerlijk', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),

    new SimpleCampaignApp(vue, 'app.socialdeal', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),

    new SimpleCampaignApp(vue, 'app.hightea', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),
    new SimpleCampaignApp(vue, 'app.gault-millau', {
      categories: [GstplnAppCategory.BookingPartners],
    }),
    new AnnoncerApp(vue),
    new BookDinnersApp(vue),
    new SimpleCampaignApp(vue, 'app.breeze-social', { categories: [GstplnAppCategory.BookingPartners] }),
    new SimpleCampaignApp(vue, 'app.nappkin', { categories: [GstplnAppCategory.BookingPartners] }),
    new SimpleCampaignApp(vue, 'app.my-lucky-table', { categories: [GstplnAppCategory.BookingPartners] }),

    new SimpleCampaignApp(vue, 'app.shiftbase', { categories: [GstplnAppCategory.Integrations] }),
    new SimpleCampaignApp(vue, 'app.workfeed', { categories: [GstplnAppCategory.Integrations] }),
    new SimpleCampaignApp(vue, 'app.mice', { categories: [GstplnAppCategory.Integrations] }),

    new StripeApp(vue),
    new StripeConnectApp(vue),
    new DyflexisApp(vue),
    new BooqPlannerApp(vue),
    new GiftcardsApp(vue),
  ];
}

export function installApps(asas: AppStoreApp[]) {
  apps.forEach((app) => {
    let isInstalled = false;

    const a = asas.find((asa) => asa.name === app.apiName);
    if (a) isInstalled = a.isInstalled;

    // if (process.env.VUE_APP_SHOW_ALL_APPS === 'true') isInstalled = true;

    try {
      if (app.installed && !isInstalled) app.uninstall();
      if (!app.installed && isInstalled) app.install();
    } catch (e) {
      console.log('installApps error', e);
    }
  });
}

export function activeApps(asas: AppStoreApp[]): GstplnApp[] {
  if (process.env.VUE_APP_SHOW_ALL_APPS === 'true') return apps;

  const disabledApps = process.env.VUE_APP_HIDE_APPS?.split(',') || [];
  return apps.flatMap((app) => {
    const a = asas.find((asa) => asa.name === app.apiName && !disabledApps.includes(app.id));
    return a === undefined ? [] : app;
  });
}
