export enum PaymentWizardMode {
  BillingDetails = 'billing_details', // edit customer billing details
  Subscription = 'subscription', // purchase subscription from selector or price
  Invoice = 'invoice', // pay invoice
  Result = 'result', // show payment result or setup result (new payment method)
  NewPaymentMethod = 'new-payment-method', // add new payment method
  Prices = 'prices', // pay for product prices
}

export enum PaymentWizardStepKey {
  PlanTable = 'plan-table',
  BillingDetails = 'billing-details',
  PaymentDetails = 'payment-details',
  PaymentSuccess = 'payment-success',
  NewPaymentMethod = 'new-payment-method',
}

// billing wizard step
export interface PaymentWizardStep {
  key: PaymentWizardStepKey;
  label: string;
  complete: boolean;

  hideCancel?: boolean;
  submitText?: string;
  submitColor?: string;
  prepareAction?: () => Promise<boolean>;
  submitAction?: () => Promise<any>;
  cancelAction?: () => Promise<any>;
  reset?: () => Promise<boolean>;
}
