/* eslint-disable max-len */
/* eslint-disable vue/max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

export enum ProductType {
  None = 'none',
  SMS = 'sms',
  Subscription = 'sub',
}

export interface Product {
  ID?: string;
  name?: string;
  description?: string;
  type?: ProductType;
  prices?: Price[];
}

export enum PriceInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum Currency {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
}

export interface Price {
  ID?: string;
  amount?: number;
  currency?: string;
  interval?: string;
  intervalCount?: number;
  quantity?: number;
  productID?: number;
  product?: Product;
  discount?: Discount;
}

export enum CustomerBalanceStatus {
  None = 'none',
  Forbidden = 'forbidden',
  Allowed = 'allowed',
  Granted = 'granted',
}

export interface Customer {
  ID?: string;
  name?: string;
  email?: string;
  city?: string;
  country?: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  state?: string;
  locale?: string;
  currency?: string;

  subscription?: Subscription;
  paymentMethod?: PaymentMethod;
  discount? :Discount;

  vatNumber?: string;
  vatNumberStatus?: string;

  balance?: number;
  cashBalances?: CashBalance[];

  customerBalanceStatus?: string;
}

export interface CashBalance {
  amount?: number;
  currency?: string;
}

export enum SubscriptionStatus {
  None = 'none',
  Active = 'active',
  AwaitingPayment = 'awaiting_payment',
  Canceled = 'canceled',
  Ended = 'ended',
}

export enum CollectionMethod {
  ChargeAutomatically = 'charge_automatically',
  SendInvoice = 'send_invoice',
}

export interface Subscription {
  ID?: string;
  created?: number;
  periodStart?: number;
  periodEnd?: number;
  canceledAt?: number;
  paidUntil?: number;
  status?: SubscriptionStatus,
  price?: Price;
  paymentMethod?: PaymentMethod;
  invoice?: Invoice;
  setupIntent?: SetupIntent;
  paymentMethodTypes?: PaymentMethodType[];
  collectionMethod?: CollectionMethod;
  discount? :Discount;
}

export interface InvoiceLine {
  ID?: string;
  amount?: number;
  currency?: string;
  description?: string;
  price?: Price;
  quantity?: number;
}

export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}

export enum PaymentMethodType {
  Unknown = 'unknown',
  CustomerBalance = 'customer_balance',
  Card = 'card',
  SepaDebit = 'sepa_debit',
  Ideal = 'ideal',
  Bancontact = 'bancontact',
  Sofort = 'sofort',
}
export enum PaymentMethodCardBrand {
  Unknown = 'unknown',
  Amex = 'amex',
  Diners = 'diners',
  Discover = 'discover',
  JCB = 'jcb',
  Mastercard = 'mastercard',
  Unionpay = 'unionpay',
  Visa = 'visa',
}

export interface Invoice {
  ID?: string;
  number?: string;
  total?: number;
  subtotal?: number;
  startingBalance?: number;
  endingBalance?: number;
  amountDue?: number;
  amountPaid?: number;
  amountRemaining?: number;
  currency?: string;
  status?: InvoiceStatus,
  created?: number;
  url?: string;
  pdf?: string;
  paymentIntent?: PaymentIntent;
  lines?: InvoiceLine[];
  tax?: number;
  paymentMethodTypes?: PaymentMethodType[];
  collectionMethod?: CollectionMethod;
  discount? :Discount;
}

export enum IntentStatus {
  None = '',
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded',
}

export enum IntentUsage {
  OffSession = 'off_session',
}

export interface Mandate {
  type?: string;
  reference?: string;
  url?: string;
}

export interface PaymentIntent {
  ID?: string;
  amount?: number;
  clientSecret?: string;
  usage?: IntentUsage;
  status?: IntentStatus;
  paymentMethodTypes?: PaymentMethodType[];
  nextAction?: PaymentIntentNextAction;

  lastError?: string;
  lastErrorType?: string;
  lastErrorCode?: string;
  lastDeclineCode?: string;

  mandate?: Mandate;
}

export interface SetupIntent {
  ID?: string;
  clientSecret?: string;
  usage?: IntentUsage;
  status?: IntentStatus;
  paymentMethodTypes?: PaymentMethodType[];

  lastError?: string;
  lastErrorType?: string;
  lastErrorCode?: string;
  lastDeclineCode?: string;

  mandate?: Mandate;
}

export interface PaymentMethod {
  ID?: string;
  type?: string;
  card?: Card;
  sepaDebit?: SepaDebit;

  mandate?: Mandate;
}

export interface Card {
  brand?: string;
  expMonth?: number;
  expYear?: number;
  last4?: string;
  wallet?: string;
}

export interface SepaDebit {
  bankCode?: string;
  branchCode?: string;
  country?: string;
  fingerprint?: string;
  last4?: string;
  generatedFromType?: string;
}
export interface Country {
  city?: string;
  country?: string;
  region?: string;
  countryCode?: string;
  postalCode?: string;
  timeZone?: string;
  timeZoneID?: number;
  isEU?: string;
}

export interface SMSProduct {
  balance?: number;
  sender?: string;
  autorenewPriceID?: string;
  balanceNotificationThreshold?: number;
  balanceNotificationEnabled?: boolean;
  latestInvoiceStatus?: string;
  latestInvoiceError?: string;
}

export interface PremiumBalanceTransaction {
  ID?: string;
  created?: number;
  amount?: number;
  currency?: string;
  description?: string;
  endingBalance?: number;
  type?: string;

  oldExpireTime?: number;
  newExpireTime?: number;
}

export interface PaymentIntentNextAction {
  displayIBANBankTransferInstructions?: NextActionDisplayIBANBankTransferInstructions;
}

export interface NextActionDisplayIBANBankTransferInstructions {
  amountRemaining?: number;
  currency?: string;
  hostedInstructionsURL?: string;
  reference?: string;
  accountHolderName?: string;
  BIC?: string;
  country?: string;
  IBAN?: string;
}

export enum DiscountDuration {
  Once = 'once',
  Repeating = 'repeating',
  Forever = 'forever',
}

export interface Discount {
  start?: number;
  end?: number;
  name?: string;
  amountOff?: { [key: string]: number };
  percentOff?: number;
  duration?: DiscountDuration;
  durationInMonths?: number;
  expiresAt?: number;
  originalAmount?: number;
  discountedAmount?: number;
  currency?: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class BillingConvert {
  public static toProducts(json: string): Product[] {
    const products = cast(JSON.parse(json), a(r('Product'))) as Product[];
    products.forEach((product) => {
      product.prices?.forEach((price) => { price.product = product; });
    });
    return products;
  }

  public static toCustomer(json: string): Customer | null {
    const val = JSON.parse(json);
    if (val === null) return null;
    return cast(val, r('Customer'));
  }

  public static customerToDto(value: Customer) {
    return {
      ...value,
      currency: undefined,
      subscription: undefined,
      paymentMethod: undefined,
      vatNumberStatus: undefined,
      balance: undefined,
    };
  }

  public static customerToJson(value: Customer): string {
    return JSON.stringify(uncast(BillingConvert.customerToDto(value), r('Customer')), null, 2);
  }

  public static toPaymentMethod(json: string): PaymentMethod | null {
    const val = JSON.parse(json);
    if (val === null) return null;
    return cast(val, r('PaymentMethod'));
  }

  public static toSetupIntent(json: string): SetupIntent | null {
    const val = JSON.parse(json);
    if (val === null) return null;
    return cast(val, r('SetupIntent'));
  }

  public static toPaymentIntent(json: string): PaymentIntent | null {
    const val = JSON.parse(json);
    if (val === null) return null;
    return cast(val, r('PaymentIntent'));
  }

  public static toInvoice(json: string): Invoice {
    return cast(JSON.parse(json), r('Invoice'));
  }

  public static toInvoices(json: string): Invoice[] {
    return cast(JSON.parse(json), a(r('Invoice')));
  }

  public static toPaymentMethods(json: string): PaymentMethod[] {
    return cast(JSON.parse(json), a(r('PaymentMethod')));
  }

  public static toSMSProduct(json: string): SMSProduct | null {
    const val = JSON.parse(json);
    if (val === null) return null;
    return cast(val, r('SMSProduct'));
  }

  public static smsProductToJson(value: SMSProduct): string {
    return JSON.stringify(uncast(value, r('SMSProduct')), null, 2);
  }

  public static toCountry(json: string): Country {
    return cast(JSON.parse(json), r('Country'));
  }

  public static toPremiumBalanceTransaction(json: string): PremiumBalanceTransaction {
    return cast(JSON.parse(json), r('PremiumBalanceTransaction'));
  }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
  if (key) {
    throw Error(`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`);
  }
  throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) { }
    }
    return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue('array', val);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue('Date', val);
    }
    return d;
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== 'object' || Array.isArray(val)) {
      return invalidValue('object', val);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, prop.key);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = val[key];
      }
    });
    return result;
  }

  if (typ === 'any') return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val);
  }
  if (typ === false) return invalidValue(typ, val);
  while (typeof typ === 'object' && typ.ref !== undefined) {
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === 'object') {
    return typ.hasOwnProperty('unionMembers') ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty('arrayItems') ? transformArray(typ.arrayItems, val)
        : typ.hasOwnProperty('props') ? transformObject(getProps(typ), typ.additional, val)
          : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== 'number') return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  Product: o([
    { json: 'ID', js: 'ID', typ: u(undefined, '') },
    { json: 'name', js: 'name', typ: u(undefined, '') },
    { json: 'description', js: 'description', typ: u(undefined, '') },
    { json: 'type', js: 'type', typ: u(undefined, '') },
    { json: 'prices', js: 'prices', typ: u(undefined, a(r('Price'))) },
  ], false),
  Price: o([
    { json: 'ID', js: 'ID', typ: u(undefined, '') },
    { json: 'amount', js: 'amount', typ: u(undefined, 0) },
    { json: 'currency', js: 'currency', typ: u(undefined, '') },
    { json: 'interval', js: 'interval', typ: u(undefined, '') },
    { json: 'intervalCount', js: 'intervalCount', typ: u(undefined, 0) },
    { json: 'quantity', js: 'quantity', typ: u(undefined, 0) },
    { json: 'productID', js: 'productID', typ: u(undefined, '') },
    { json: 'discount', js: 'discount', typ: u(undefined, r('Discount')) },
  ], false),
  Customer: o([
    { json: 'ID', js: 'ID', typ: u(undefined, '') },
    { json: 'name', js: 'name', typ: u(undefined, '') },
    { json: 'email', js: 'email', typ: u(undefined, '') },
    { json: 'city', js: 'city', typ: u(undefined, '') },
    { json: 'country', js: 'country', typ: u(undefined, '') },
    { json: 'addressLine1', js: 'addressLine1', typ: u(undefined, '') },
    { json: 'addressLine2', js: 'addressLine2', typ: u(undefined, '') },
    { json: 'postalCode', js: 'postalCode', typ: u(undefined, '') },
    { json: 'state', js: 'state', typ: u(undefined, '') },
    { json: 'locale', js: 'locale', typ: u(undefined, '') },
    { json: 'currency', js: 'currency', typ: u(undefined, '') },
    { json: 'subscription', js: 'subscription', typ: u(undefined, r('Subscription')) },
    { json: 'paymentMethod', js: 'paymentMethod', typ: u(undefined, r('PaymentMethod')) },
    { json: 'discount', js: 'discount', typ: u(undefined, r('Discount')) },
    { json: 'vatNumber', js: 'vatNumber', typ: u(undefined, '') },
    { json: 'vatNumberStatus', js: 'vatNumberStatus', typ: u(undefined, '') },
    { json: 'balance', js: 'balance', typ: u(undefined, 0) },
    { json: 'cashBalances', js: 'cashBalances', typ: u(undefined, a(r('CashBalance'))) },
    { json: 'customerBalanceStatus', js: 'customerBalanceStatus', typ: u(undefined, '') },
  ], false),
  CashBalance: o([
    { json: 'currency', js: 'currency', typ: u(undefined, '') },
    { json: 'amount', js: 'amount', typ: u(undefined, 0) },
  ], false),
  Subscription: o([
    { json: 'ID', js: 'ID', typ: u(undefined, '') },
    { json: 'created', js: 'created', typ: u(undefined, 0) },
    { json: 'periodStart', js: 'periodStart', typ: u(undefined, 0) },
    { json: 'periodEnd', js: 'periodEnd', typ: u(undefined, 0) },
    { json: 'paidUntil', js: 'paidUntil', typ: u(undefined, 0) },
    { json: 'canceledAt', js: 'canceledAt', typ: u(undefined, 0) },
    { json: 'status', js: 'status', typ: u(undefined, '') },
    { json: 'price', js: 'price', typ: u(undefined, r('Price')) },
    { json: 'invoice', js: 'invoice', typ: u(undefined, r('Invoice')) },
    { json: 'paymentMethod', js: 'paymentMethod', typ: u(undefined, r('PaymentMethod')) },
    { json: 'setupIntent', js: 'setupIntent', typ: u(undefined, r('SetupIntent')) },
    { json: 'paymentMethodTypes', js: 'paymentMethodTypes', typ: u(undefined, a('')) },
    { json: 'collectionMethod', js: 'collectionMethod', typ: u(undefined, '') },
    { json: 'discount', js: 'discount', typ: u(undefined, r('Discount')) },
  ], false),
  SubscriptionInfo: o([
    { json: 'bData', js: 'data', typ: u(undefined, '') },
    { json: 'bSubscriptionInfo', js: 'status', typ: u(undefined, '') },
    { json: 'bProTrialExpirationDate', js: 'trialExpDate', typ: u(undefined, 0) },
    { json: 'bProSubscriptionExpirationDate', js: 'subExpDate', typ: u(undefined, 0) },
  ], false),
  SMSProduct: o([
    { json: 'balance', js: 'balance', typ: u(undefined, 0) },
    { json: 'sender', js: 'sender', typ: u(undefined, '') },
    { json: 'autorenewPriceID', js: 'autorenewPriceID', typ: u(undefined, '') },
    { json: 'balanceNotificationThreshold', js: 'balanceNotificationThreshold', typ: u(undefined, 0) },
    { json: 'balanceNotificationEnabled', js: 'balanceNotificationEnabled', typ: u(undefined, true) },
    { json: 'latestInvoiceStatus', js: 'latestInvoiceStatus', typ: u(undefined, '') },
    { json: 'latestInvoiceError', js: 'latestInvoiceError', typ: u(undefined, '') },
    { json: 'status', js: 'status', typ: u(undefined, '') },
  ], false),
  Invoice: o([
    { json: 'ID', js: 'ID', typ: u(undefined, '') },
    { json: 'number', js: 'number', typ: u(undefined, '') },
    { json: 'total', js: 'total', typ: u(undefined, 0) },
    { json: 'subtotal', js: 'subtotal', typ: u(undefined, 0) },
    { json: 'startingBalance', js: 'startingBalance', typ: u(undefined, 0) },
    { json: 'endingBalance', js: 'endingBalance', typ: u(undefined, 0) },
    { json: 'amountDue', js: 'amountDue', typ: u(undefined, 0) },
    { json: 'amountPaid', js: 'amountPaid', typ: u(undefined, 0) },
    { json: 'amountRemaining', js: 'amountRemaining', typ: u(undefined, 0) },
    { json: 'currency', js: 'currency', typ: u(undefined, '') },
    { json: 'status', js: 'status', typ: u(undefined, '') },
    { json: 'created', js: 'created', typ: u(undefined, 0) },
    { json: 'url', js: 'url', typ: u(undefined, '') },
    { json: 'pdf', js: 'pdf', typ: u(undefined, '') },
    { json: 'paymentIntent', js: 'paymentIntent', typ: u(undefined, r('PaymentIntent')) },
    { json: 'discount', js: 'discount', typ: u(undefined, r('Discount')) },
    { json: 'lines', js: 'lines', typ: u(undefined, a(r('InvoiceLine'))) },
    { json: 'tax', js: 'tax', typ: u(undefined, 0) },
    { json: 'paymentMethodTypes', js: 'paymentMethodTypes', typ: u(undefined, a('')) },
    { json: 'collectionMethod', js: 'collectionMethod', typ: u(undefined, '') },
  ], false),
  InvoiceLine: o([
    { json: 'ID', js: 'ID', typ: u(undefined, '') },
    { json: 'amount', js: 'amount', typ: u(undefined, 0) },
    { json: 'currency', js: 'currency', typ: u(undefined, '') },
    { json: 'description', js: 'description', typ: u(undefined, '') },
    { json: 'quantity', js: 'quantity', typ: u(undefined, 0) },
    { json: 'price', js: 'price', typ: u(undefined, r('Price')) },
  ], false),
  SetupIntent: o([
    { json: 'ID', js: 'ID', typ: u(undefined, '') },
    { json: 'clientSecret', js: 'clientSecret', typ: u(undefined, '') },
    { json: 'usage', js: 'usage', typ: u(undefined, '') },
    { json: 'status', js: 'status', typ: u(undefined, '') },
    { json: 'paymentMethodTypes', js: 'paymentMethodTypes', typ: u(undefined, a('')) },
    { json: 'lastError', js: 'lastError', typ: u(undefined, '') },
    { json: 'lastErrorType', js: 'lastErrorType', typ: u(undefined, '') },
    { json: 'lastErrorCode', js: 'lastErrorCode', typ: u(undefined, '') },
    { json: 'lastDeclineCode', js: 'lastDeclineCode', typ: u(undefined, '') },
    { json: 'mandate', js: 'mandate', typ: u(undefined, r('Mandate')) },
  ], false),
  PaymentIntent: o([
    { json: 'ID', js: 'ID', typ: u(undefined, '') },
    { json: 'amount', js: 'amount', typ: u(undefined, 0) },
    { json: 'clientSecret', js: 'clientSecret', typ: u(undefined, '') },
    { json: 'usage', js: 'usage', typ: u(undefined, '') },
    { json: 'status', js: 'status', typ: u(undefined, '') },
    { json: 'paymentMethodTypes', js: 'paymentMethodTypes', typ: u(undefined, a('')) },
    { json: 'nextAction', js: 'nextAction', typ: u(undefined, r('PaymentIntentNextAction')) },
    { json: 'lastError', js: 'lastError', typ: u(undefined, '') },
    { json: 'lastErrorType', js: 'lastErrorType', typ: u(undefined, '') },
    { json: 'lastErrorCode', js: 'lastErrorCode', typ: u(undefined, '') },
    { json: 'lastDeclineCode', js: 'lastDeclineCode', typ: u(undefined, '') },
    { json: 'mandate', js: 'mandate', typ: u(undefined, r('Mandate')) },
  ], false),
  Mandate: o([
    { json: 'type', js: 'type', typ: u(undefined, '') },
    { json: 'reference', js: 'reference', typ: u(undefined, '') },
    { json: 'url', js: 'url', typ: u(undefined, '') },
  ], false),
  PaymentMethod: o([
    { json: 'ID', js: 'ID', typ: u(undefined, '') },
    { json: 'type', js: 'type', typ: u(undefined, '') },
    { json: 'card', js: 'card', typ: u(undefined, r('Card')) },
    { json: 'sepaDebit', js: 'sepaDebit', typ: u(undefined, r('SepaDebit')) },
    { json: 'mandate', js: 'mandate', typ: u(undefined, r('Mandate')) },
  ], false),
  Card: o([
    { json: 'brand', js: 'brand', typ: u(undefined, '') },
    { json: 'expMonth', js: 'expMonth', typ: u(undefined, 0) },
    { json: 'expYear', js: 'expYear', typ: u(undefined, 0) },
    { json: 'last4', js: 'last4', typ: u(undefined, '') },
    { json: 'wallet', js: 'wallet', typ: u(undefined, '') },
  ], false),
  SepaDebit: o([
    { json: 'bankCode', js: 'bankCode', typ: u(undefined, '') },
    { json: 'branchCode', js: 'branchCode', typ: u(undefined, '') },
    { json: 'country', js: 'country', typ: u(undefined, '') },
    { json: 'fingerprint', js: 'fingerprint', typ: u(undefined, '') },
    { json: 'last4', js: 'last4', typ: u(undefined, '') },
    { json: 'generatedFromType', js: 'generatedFromType', typ: u(undefined, '') },
  ], false),
  Country: o([
    { json: 'city', js: 'city', typ: u(undefined, '') },
    { json: 'country', js: 'country', typ: u(undefined, '') },
    { json: 'region', js: 'region', typ: u(undefined, '') },
    { json: 'countryCode', js: 'countryCode', typ: u(undefined, '') },
    { json: 'postalCode', js: 'postalCode', typ: u(undefined, '') },
    { json: 'timeZone', js: 'timeZone', typ: u(undefined, '') },
    { json: 'timeZoneID', js: 'timeZoneID', typ: u(undefined, 0) },
    { json: 'isEU', js: 'isEU', typ: u(undefined, true) },
  ], false),
  PremiumBalanceTransaction: o([
    { json: 'ID', js: 'ID', typ: u(undefined, '') },
    { json: 'created', js: 'created', typ: u(undefined, 0) },
    { json: 'amount', js: 'amount', typ: u(undefined, 0) },
    { json: 'currency', js: 'currency', typ: u(undefined, '') },
    { json: 'description', js: 'description', typ: u(undefined, '') },
    { json: 'endingBalance', js: 'endingBalance', typ: u(undefined, 0) },
    { json: 'type', js: 'type', typ: u(undefined, '') },
    { json: 'oldExpireTime', js: 'oldExpireTime', typ: u(undefined, 0) },
    { json: 'newExpireTime', js: 'newExpireTime', typ: u(undefined, 0) },
  ], false),
  Discount: o([
    { json: 'start', js: 'start', typ: u(undefined, 0) },
    { json: 'end', js: 'end', typ: u(undefined, 0) },
    { json: 'name', js: 'name', typ: u(undefined, '') },
    { json: 'amountOff', js: 'amountOff', typ: u(undefined, { props: [], additional: 0 }) },
    { json: 'percentOff', js: 'percentOff', typ: u(undefined, 0) },
    { json: 'duration', js: 'duration', typ: u(undefined, '') },
    { json: 'durationInMonths', js: 'durationInMonths', typ: u(undefined, 0) },
    { json: 'expiresAt', js: 'expiresAt', typ: u(undefined, 0) },
    { json: 'originalAmount', js: 'originalAmount', typ: u(undefined, 0) },
    { json: 'discountedAmount', js: 'discountedAmount', typ: u(undefined, 0) },
    { json: 'currency', js: 'currency', typ: u(undefined, '') },
  ], false),
  PaymentIntentNextAction: o([
    { json: 'displayIBANBankTransferInstructions', js: 'displayIBANBankTransferInstructions', typ: u(undefined, r('NextActionDisplayIBANBankTransferInstructions')) },
  ], false),
  NextActionDisplayIBANBankTransferInstructions: o([
    { json: 'amountRemaining', js: 'amountRemaining', typ: u(undefined, 0) },
    { json: 'currency', js: 'currency', typ: u(undefined, '') },
    { json: 'hostedInstructionsURL', js: 'hostedInstructionsURL', typ: u(undefined, '') },
    { json: 'reference', js: 'reference', typ: u(undefined, '') },
    { json: 'accountHolderName', js: 'accountHolderName', typ: u(undefined, '') },
    { json: 'BIC', js: 'BIC', typ: u(undefined, '') },
    { json: 'country', js: 'country', typ: u(undefined, '') },
    { json: 'IBAN', js: 'IBAN', typ: u(undefined, '') },
  ], false),
};
