/* eslint-disable import/no-cycle */
import {
  GetterTree, MutationTree, ActionTree, Dispatch,
} from 'vuex';
import httpClient from '@/api/http-client';
import { IUpdate } from '@/api/api-update';
import Account, { BillingInfo, BillingInfoSMSStatus, BillingInfoSubscriptionStatus } from '@/model/Account';
import AccountSettings from '@/model/AccountSettings';
import Tab from '@/model/Tab';
import TabItem from '@/model/TabItem';
import Employee from '@/model/Employee';
import TagNote from '@/model/TagNote';
import Label from '@/model/Label';
import Service from '@/model/Service';
import User from '@/model/User';
import OpeningHour from '@/model/OpeningHour';
import DurationRule from '@/model/DurationRule';
import {
  mergeToModelEntities, toSaveModelItems, IApi, ISingleModel, IListModel,
  toMapById, toMapByFieldFirst, cloneModel,
} from '@/model/model-utils';
import storage from '@/services/local-storage';
import { toFloorplanDTO } from '@/api/api-floorplan';
import AppStoreApp from '@/model/AppStoreApp';
import { activeApps, installApps } from '@/util/apps';
import GstplnApp, { GstplnAppCategory } from '@/components/apps/app';
import { isPaymentWarningBarInfo, isSMSWarningBarInfo, isSubWarningBarInfo } from '@/services/billing-utils';
import { hostedWidgetURL } from '@/services/configuration';
import { performUpdatesAction, UpdateOriginator } from '@/services/store-utils';
import grpcClient from '@/grpc-api/grpc-client';
import IRootState, { ISetStoreState, ISettingsState } from './store-state';

export class SettingsState implements ISettingsState {
  account: Account = storage.getAccount() ?? new Account();

  accountSettings: AccountSettings = storage.getAccountSetting() ?? new AccountSettings();

  tabs: Tab[] = [];

  users: User[] = [];

  employees: Employee[] = [];

  tagNotes: TagNote[] = [];

  labels: Label[] = [];

  services: Service[] = [];

  openingHours: OpeningHour[] = [];

  durationRules: DurationRule[] = [];

  apps: AppStoreApp[] = [];

  gstplnApps: GstplnApp[] = [];
}

const mutations = <MutationTree<ISettingsState>>{
  RESET(state: SettingsState) {
    Object.assign(state, new SettingsState());
  },
  SET(state: SettingsState, p: { state: ISetStoreState}) {
    if (!p.state.settings) return;
    console.log('SET settings:', p.state.settings);
    Object.assign(state, p.state.settings);
  },
  UPDATE_TABS(state: SettingsState, p: { tabs: Tab[] }) {
    state.tabs = p.tabs;
    console.log('tabs:', state.tabs);
  },
  UPDATE_ACCOUNT(state: SettingsState, p: { account: Account }) {
    state.account = p.account;
    console.log('account:', state.account);
  },
  UPDATE_SETTINGS(state: SettingsState, p: {
    accountSettings?: AccountSettings,
    users?: User[],
    employees?: Employee[],
    tagNotes?: TagNote[],
    labels?: Label[],
    services?: Service[],
    openingHours?: OpeningHour[],
    durationRules?: DurationRule[],
    apps?: AppStoreApp[],
  }) {
    if (p.accountSettings) state.accountSettings = p.accountSettings;
    if (p.users) state.users = p.users;
    if (p.employees) state.employees = p.employees;
    if (p.tagNotes) state.tagNotes = p.tagNotes;
    if (p.labels) state.labels = p.labels;
    if (p.services) state.services = p.services;
    if (p.openingHours) state.openingHours = p.openingHours;
    if (p.durationRules) state.durationRules = p.durationRules;
    if (p.apps) {
      state.apps = p.apps;
      state.gstplnApps = activeApps(p.apps);
    }

    console.log('accountSettings: ', state.accountSettings);
    console.log('users: ', state.users);
    console.log('employees: ', state.employees);
    console.log('tagNotes: ', state.tagNotes);
    console.log('labels: ', state.labels);
    console.log('services: ', state.services);
    console.log('openingHours: ', state.openingHours);
    console.log('durationRules: ', state.durationRules);
    console.log('apps: ', state.apps);
  },
};

async function sendSingleEntity<A extends IApi, M extends ISingleModel<A>>(
  dispatch: Dispatch,
  entity: M,
  sendEntity: (dto: IApi) => Promise<IUpdate>,
  isDeleted?: boolean,
) {
  const dto = entity.toDto();
  if (isDeleted) dto.isDeleted = true;
  await performUpdatesAction(dispatch, () => sendEntity(dto), { originator: UpdateOriginator.SettingsSingle });
}

async function sendListEntities<A extends IApi, M extends IListModel<A>>(
  dispatch: Dispatch,
  entities: M[],
  oldEntities: M[],
  sendEntities: (dtos: IApi[]) => Promise<IUpdate>,
) {
  const dtos = toSaveModelItems(entities, oldEntities);
  await performUpdatesAction(dispatch, () => sendEntities(dtos), { originator: UpdateOriginator.SettingsList });
}

const actions = <ActionTree<ISettingsState, IRootState>>{
  updateAccount({ commit }, update: IUpdate) {
    if (!update.account) return;

    console.log('updateAccount');

    const account = new Account(update.account);
    storage.setAccount(account ?? null);
    this.commit('UPDATE_ACCOUNT', { account });
  },
  updateTabs({ state, commit }, update: IUpdate) {
    if (!update.tabs) return;

    console.log('updateTabs');

    const oldTabs = update.isFullUpdate ? [] : state.tabs;
    const tabs = mergeToModelEntities(Tab, oldTabs, update.tabs);
    this.commit('UPDATE_TABS', { tabs });
  },
  updateSettings({ state, commit }, update: IUpdate) {
    if (true
      && !update.accountSettings
      && !update.accountUsers
      && !update.employees
      && !update.tagNotes
      && !update.labels
      && !update.services
      && !update.openingHours
      && !update.durationRules
      && !update.apps
    ) return;

    console.log('updateSettings');

    const ifu = update.isFullUpdate;

    const accountSettings = update.accountSettings && update.accountSettings[0]
      ? new AccountSettings(update.accountSettings[0]) : undefined;

    const users = mergeToModelEntities(User, ifu ? [] : state.users, update.accountUsers, { stringOrderField: 'name' });
    const employees = mergeToModelEntities(Employee, ifu ? [] : state.employees, update.employees);
    const tagNotes = mergeToModelEntities(TagNote, ifu ? [] : state.tagNotes, update.tagNotes);
    const labels = mergeToModelEntities(Label, ifu ? [] : state.labels, update.labels);
    const services = mergeToModelEntities(Service, ifu ? [] : state.services, update.services);
    const openingHours = mergeToModelEntities(OpeningHour, ifu ? [] : state.openingHours, update.openingHours);
    const durationRules = mergeToModelEntities(DurationRule, ifu ? [] : state.durationRules, update.durationRules);
    const apps = mergeToModelEntities(AppStoreApp, ifu ? [] : state.apps, update.apps);

    installApps(apps);

    storage.setAccountSetting(accountSettings ?? null);

    this.commit('UPDATE_SETTINGS', {
      accountSettings, users, employees, tagNotes, labels, services, openingHours, durationRules, apps,
    });
  },
  async sendAccount({ state, commit, dispatch }, p: { entity: Account }) {
    return sendSingleEntity(dispatch, p.entity, httpClient.sendAccount.bind(httpClient));
  },
  async sendUser({ state, commit, dispatch }, p: { entity: User, delete: boolean }) {
    return sendSingleEntity(dispatch, p.entity, httpClient.sendUser.bind(httpClient), p.delete);
  },
  async sendDurationRule({ state, commit, dispatch }, p: { entity: DurationRule, delete: boolean }) {
    return sendSingleEntity(dispatch, p.entity, httpClient.sendDurationRule.bind(httpClient), p.delete);
  },
  async sendAccountSettings({ state, commit, dispatch }, p: { entity: AccountSettings }) {
    return sendSingleEntity(dispatch, p.entity, httpClient.sendAccountSettings.bind(httpClient));
  },
  async sendTabs({ state, commit, dispatch }, p: { entities: Tab[] }) {
    return sendListEntities(dispatch, p.entities, state.tabs, httpClient.sendTabs.bind(httpClient));
  },
  async sendTabItems({ dispatch, getters }, p: { entities: TabItem[], tabId: number }) {
    const oldEntities = getters.tabsById.get(p.tabId).tabItems;
    const dtos = toSaveModelItems(p.entities, oldEntities);
    await performUpdatesAction(
      dispatch,
      () => httpClient.sendTabItems(p.tabId, dtos),

      { originator: UpdateOriginator.SettingsTabItems },
    );
  },
  async sendFloorplanTabItems({ dispatch, rootGetters, state }, p: { entities: TabItem[], tabId: number }) {
    const token = (rootGetters.token ?? '') as string;
    const accountName = state.account.accountName ?? '';
    const tabs = this.state.settings.tabs.map((t) => {
      if (t.id !== p.tabId) return t;
      const nt = cloneModel(t);
      nt.tabItems = p.entities;
      return nt;
    });

    const floorplan = toFloorplanDTO(token, accountName, tabs);
    await performUpdatesAction(
      dispatch,
      () => httpClient.sendFloorplan(floorplan),

      { originator: UpdateOriginator.SettingsFloorplan },
    );
  },
  async sendEmployees({ state, commit, dispatch }, p: { entities: Employee[] }) {
    return sendListEntities(dispatch, p.entities, state.employees, httpClient.sendEmployees.bind(httpClient));
  },
  async sendTagNotes({ state, commit, dispatch }, p: { entities: TagNote[] }) {
    return sendListEntities(dispatch, p.entities, state.tagNotes, httpClient.sendTagNotes.bind(httpClient));
  },
  async sendLabels({ state, commit, dispatch }, p: { entities: Label[] }) {
    return sendListEntities(dispatch, p.entities, state.labels, httpClient.sendLabels.bind(httpClient));
  },
  async sendServices({ state, commit, dispatch }, p: { entities: Service[] }) {
    return sendListEntities(dispatch, p.entities, state.services, httpClient.sendServices.bind(httpClient));
  },
  async sendOpeningHours({ state, commit, dispatch }, p: { entities: OpeningHour[] }) {
    return sendListEntities(dispatch, p.entities, state.openingHours, httpClient.sendOpeningHours.bind(httpClient));
  },

  async changePassword({ state, commit, dispatch }, p: { username: string, password: string, newPassword: string }) {
    const updates = await httpClient.changePassword(p.username, p.password, p.newPassword);
    dispatch('processUpdates', { updates, noroot: true });
  },
  async resetPassword({ state, commit, dispatch }, p: { username: string }) {
    const updates = await httpClient.resetPassword(p.username);
    dispatch('processUpdates', { updates, noroot: true });
  },
  async updateApp({ state, dispatch, getters }, p: { gstplnAppId: string, install: boolean, params?: string }) {
    const gstplnApps = getters.gstplnApps as GstplnApp[];

    const gapp = gstplnApps.find((o: GstplnApp) => o.id === p.gstplnAppId);
    if (gapp === undefined) throw Error(`Guestplan App ${p.gstplnAppId} not found`);

    const app = state.apps.find((o) => o.name === gapp.id);
    if (app === undefined) throw Error(`App with name ${p.gstplnAppId} not found`);

    const uapp = await grpcClient.updateApp(app.id, p.install, p.params);
    await dispatch('update');

    return uapp;
  },
  async getApp({ state, dispatch, getters }, p: { gstplnAppId: string, install: boolean }) {
    const gstplnApps = getters.gstplnApps as GstplnApp[];

    const gapp = gstplnApps.find((o: GstplnApp) => o.id === p.gstplnAppId);
    if (gapp === undefined) throw Error(`Guestplan App ${p.gstplnAppId} not found`);

    const app = state.apps.find((o) => o.name === gapp.id);
    if (app === undefined) throw Error(`App with name ${p.gstplnAppId} not found`);

    return grpcClient.getApp(app.id);
  },
};

const getters = <GetterTree<ISettingsState, IRootState>>{
  tabsById(state) { return toMapById(state.tabs); },
  tabItemsById(state) { return toMapById(state.tabs.flatMap((o) => o.tabItems)); },
  servicesById(state) { return toMapById(state.services); },
  employeesById(state) { return toMapById(state.employees); },
  labelsByText(state) { return toMapByFieldFirst(state.labels, 'text'); },
  openingHoursInWeekdaysByTimeIndex(state, localGetters, rootState, rootGetters): Map<number, OpeningHour>[] {
    const days = [...new Array(8)].map(() => new Map<number, OpeningHour>());
    state.openingHours.forEach((o) => days[o.weekdayNumber].set(o.timeIndex, o));
    return days;
  },
  maxOnlineCapacity(state, localGetters, rootState, rootGetters): number {
    return state.tabs.map((o) => (o.usingWeekdaysAndTimes ? o.capacity ?? 0 : 0)).reduce((a, b) => a + b, 0);
  },
  employeeNameIsNeeded(state, localGetters): boolean {
    const user = localGetters.activeUser as User | undefined;
    if (user?.sharedUser !== true) return false;
    return state.accountSettings.employeeNameIsNeeded ?? false;
  },
  timeZoneId(state): number | undefined {
    return state.account.timeZoneId;
  },
  activeUser(state): User | undefined {
    const user = state.users.find((o) => o.id === state.account.userId);
    console.log('activeUser: ', user);
    return user;
  },
  billingInfo(state): BillingInfo {
    console.log('billingInfo: ', state.account.proSubscriptionExpirationDate);
    return {
      subStatus: state.account.proStatus ?? BillingInfoSubscriptionStatus.None,
      data: state.account.proData,
      trialExpDate: state.account.proTrialExpirationDate,
      subExpDate: state.account.proSubscriptionExpirationDate,
      smsStatus: state.account.smsStatus ?? BillingInfoSMSStatus.None,
      smsBalance: state.account.smsBalance ?? 0,
    };
  },
  isBillingAllowed(state, localGetters): boolean {
    const user = localGetters.activeUser as User | undefined;
    return user?.allowBilling === true;
  },
  isReservationsAllowed(state, localGetters): boolean {
    const user = localGetters.activeUser as User | undefined;
    return user?.allowReservations === true;
  },
  isSettingsAllowed(state, localGetters): boolean {
    const user = localGetters.activeUser as User | undefined;
    return user?.allowSettings === true;
  },
  isBillingAppAccess(state, localGetters): boolean {
    const billingInfo = localGetters.billingInfo as BillingInfo;
    return billingInfo.subStatus !== BillingInfoSubscriptionStatus.None;
  },
  isBillingLanding(state, localGetters, rootState, rootGetters): boolean {
    const isLoaded = rootGetters.isLoaded as boolean;
    const appHasBilling = process.env.VUE_APP_HAS_BILLING === 'true';
    const billingInfo = localGetters.billingInfo as BillingInfo;
    return isLoaded && appHasBilling && [
      BillingInfoSubscriptionStatus.None,
      BillingInfoSubscriptionStatus.TrialExpired,
      BillingInfoSubscriptionStatus.SubExpired,
      BillingInfoSubscriptionStatus.SubExpiredPayment,
    ].includes(billingInfo.subStatus);
  },
  isBillingSubWarning(state, localGetters, rootState, rootGetters): boolean {
    const isLoaded = rootGetters.isLoaded as boolean;
    const appHasBilling = process.env.VUE_APP_HAS_BILLING === 'true';
    return isLoaded && appHasBilling && isSubWarningBarInfo(localGetters.billingInfo);
  },
  isBillingPaymentWarning(state, localGetters, rootState, rootGetters): boolean {
    const isLoaded = rootGetters.isLoaded as boolean;
    const appHasBilling = process.env.VUE_APP_HAS_BILLING === 'true';
    return isLoaded && appHasBilling && isPaymentWarningBarInfo(localGetters.billingInfo);
  },
  isBillingSMSWarning(state, localGetters, rootState, rootGetters): boolean {
    const isLoaded = rootGetters.isLoaded as boolean;
    const appHasBilling = process.env.VUE_APP_HAS_BILLING === 'true';
    return isLoaded && appHasBilling && isSMSWarningBarInfo(localGetters.billingInfo);
  },
  isBillingPageAllowed(state, localGetters, rootState, rootGetters): boolean {
    const isLoaded = rootGetters.isLoaded as boolean;
    const isVIP = localGetters.billingInfo.status === BillingInfoSubscriptionStatus.VIP;
    const appHasBilling = process.env.VUE_APP_HAS_BILLING === 'true';
    console.log('isBillingPageAllowed (loaded, vip, env): ', isLoaded, isVIP, appHasBilling);
    return appHasBilling && isLoaded && !isVIP;
  },
  isBillingSMSPageFull(state, localGetters, rootState, rootGetters): boolean {
    return localGetters.isBillingPageAllowed;
  },
  gstplnApps(state, localGetters, rootState, rootGetters): GstplnApp[] {
    const country = rootState.settings.account.countryCode;

    const gstplnApps = state.gstplnApps.filter((app) => {
      // app is installed -> allow
      if (app.isInstalled) return true;

      if (!country) {
        // no country -> allow if no filter, otherwise disallow
        return app.disallowedCountries.length === 0 && app.allowedCountries.length === 0;
      }

      // if disallowd country -> do not allow
      if (app.disallowedCountries.includes(country)) return false;
      // allowed country or no allowed filter -> allow
      if (app.allowedCountries.length === 0 || app.allowedCountries.includes(country)) return true;

      return false;
    });

    // group the apps into categories ordered by enum order, then alphabethically
    const catOrd = Object.values(GstplnAppCategory) as string[];
    gstplnApps.sort(
      (a, b) => catOrd.indexOf(a.categories[0]) - catOrd.indexOf(b.categories[0]) || a.id.localeCompare(b.id),
    );

    return gstplnApps;
  },
  isFeedbackAllowed(state, localGetters, rootState, rootGetters): boolean {
    return state.accountSettings.allowFeedback ?? false;
  },
  hostedWidgetURL(state) {
    const apiKey = state.account.defaultAccessKey;
    return apiKey ? hostedWidgetURL({ apiKey }) : undefined;
  },
};

const SettingsStore = {
  namespaced: false,
  state: new SettingsState(),
  mutations,
  actions,
  getters,
};

export default SettingsStore;
