/* eslint-disable max-len */
/* eslint-disable vue/max-len */
/* eslint-disable import/prefer-default-export */
import {
  Currency, Price, PriceInterval, Customer, PaymentMethod, Subscription, Invoice, SubscriptionStatus, InvoiceStatus,
  PremiumBalanceTransaction,
  Product,
  NextActionDisplayIBANBankTransferInstructions,
  PaymentMethodType,
  CollectionMethod,
  Discount,
  DiscountDuration,
  PaymentIntent,
  SetupIntent,
  Card,
  SepaDebit,
  IntentStatus,
  PaymentMethodCardBrand,
} from '@/api/api-billing';
import Country from '@/model/Country';
import { sprintf } from 'sprintf-js';
import localized from '@/plugins/vue-localized-formatter/src/localized';
import { BillingInfo, BillingInfoSMSStatus, BillingInfoSubscriptionStatus } from '@/model/Account';
import i18n from '@/plugins/i18n';

export function yearlyAmountForPrice(p: Price): number {
  let count = 1;
  switch (p.interval) {
    case PriceInterval.Day: count = 365; break;
    case PriceInterval.Week: count = 52; break;
    case PriceInterval.Month: count = 12; break;
    case PriceInterval.Year: count = 1; break;
    default: break;
  }

  count /= (p.intervalCount || 1);

  const amount = p.amount! * count;
  return amount;
}

export function billingCurrencyText(currency: string): string {
  return currency.toUpperCase();
}

export function billingAmountText(amount: number, currency: string): string {
  const fixedAmount = amount < 0 ? -amount : amount;
  const atext = sprintf('%.2f', fixedAmount / 100);

  const sign = amount < 0 ? '−' : '';

  if (currency === Currency.EUR) { return `${sign}€ ${atext}`; }
  if (currency === Currency.USD) { return `${sign}$ ${atext}`; }
  if (currency === Currency.GBP) { return `${sign}£ ${atext}`; }

  return `${sign} ${atext} ${currency.toUpperCase()}`;
}

export function intervalText(p: Price): string {
  if (p.intervalCount === 1) {
    let itext = '';
    switch (p.interval) {
      case PriceInterval.Day: itext = i18n.tc('label.daily'); break;
      case PriceInterval.Week: itext = i18n.tc('label.weekly'); break;
      case PriceInterval.Month: itext = i18n.tc('label.monthly'); break;
      case PriceInterval.Year: itext = i18n.tc('label.yearly'); break;
      default: itext = p.interval!; break;
    }
    return itext;
  }

  if (p.interval === PriceInterval.Month && p.intervalCount === 3) {
    return i18n.tc('label.quarterly');
  }

  let itext = '';
  switch (p.interval) {
    case PriceInterval.Day: itext = i18n.tc('label.days'); break;
    case PriceInterval.Week: itext = i18n.tc('label.weeks'); break;
    case PriceInterval.Month: itext = i18n.tc('label.months'); break;
    case PriceInterval.Year: itext = i18n.tc('label.years'); break;
    default: itext = p.interval!; break;
  }

  return i18n.t('label.every_x', [`${p.intervalCount} ${itext}`]) as string;
}

export function billedIntervalText(p: Price): string {
  const text = intervalText(p);
  const words = text.split(' ');
  const uwords = words.map((w) => w.charAt(0).toUpperCase() + w.slice(1));
  return uwords.join(' ');
}

// info

export interface CardInfo {
  number: string;
  exp: string;
  brand: string;
  card: Card;
}
export interface SepaDebitInfo {
  number: string;
  country: string;
  bankCode: string;
  branchCode: string;
  generatedFromType: string;
  sepaDebit: SepaDebit;
}

export interface PaymentMethodInfo {
  cardInfo: CardInfo;
  sepaDebitInfo: SepaDebitInfo;
  type: string;
  logoSrc: string;
  active: boolean;
  paymentMethod: PaymentMethod;
}

function paymentMethodName(pm: PaymentMethod): string {
  const type = pm.type ?? PaymentMethodType.Unknown;
  const brand = pm.card?.brand ?? PaymentMethodCardBrand.Unknown;

  switch (type) {
    case PaymentMethodType.CustomerBalance: return 'Bank Transfer';
    case PaymentMethodType.SepaDebit: return 'SEPA Debit';
    case PaymentMethodType.Card:
      switch (brand) {
        case PaymentMethodCardBrand.Amex: return 'Amex';
        case PaymentMethodCardBrand.Diners: return 'Diners';
        case PaymentMethodCardBrand.Discover: return 'Discover';
        case PaymentMethodCardBrand.JCB: return 'JCB';
        case PaymentMethodCardBrand.Mastercard: return 'Mastercard';
        case PaymentMethodCardBrand.Unionpay: return 'Unionpay';
        case PaymentMethodCardBrand.Visa: return 'Visa';
        default: return 'Unknown';
      }
    case PaymentMethodType.Ideal: return 'iDEAL';
    case PaymentMethodType.Sofort: return 'Sofort';
    case PaymentMethodType.Bancontact: return 'Bancontact';
    default: return 'Unknown';
  }
}
function paymentMethodLogo(pm: PaymentMethod): string {
  const type = pm.type ?? PaymentMethodType.Unknown;
  const brand = pm.card?.brand ?? PaymentMethodCardBrand.Unknown;
  const genereatedFromType = pm.sepaDebit?.generatedFromType ?? PaymentMethodCardBrand.Unknown;

  const fname = ((t, b, gft) => {
    switch (t) {
      case PaymentMethodType.CustomerBalance: return 'sepa';
      case PaymentMethodType.SepaDebit:
        switch (gft) {
          case PaymentMethodType.Ideal: return 'ideal';
          case PaymentMethodType.Sofort: return 'sofort';
          case PaymentMethodType.Bancontact: return 'bancontact';
          default: return 'sepa';
        }
      case PaymentMethodType.Card:
        switch (b) {
          case PaymentMethodCardBrand.Amex: return 'amex';
          case PaymentMethodCardBrand.Diners: return 'diners';
          case PaymentMethodCardBrand.Discover: return 'discover';
          case PaymentMethodCardBrand.JCB: return 'jcb';
          case PaymentMethodCardBrand.Mastercard: return 'mastercard';
          case PaymentMethodCardBrand.Unionpay: return 'unionpay';
          case PaymentMethodCardBrand.Visa: return 'visa';
          default: return 'unknown';
        }
      case PaymentMethodType.Ideal: return 'ideal';
      case PaymentMethodType.Sofort: return 'sofort';
      case PaymentMethodType.Bancontact: return 'bancontact';
      default: return 'unknown';
    }
  })(type, brand, genereatedFromType);

  return `/assets/payment-method/${fname}.svg`;
}

export function paymentMethodInfo(pm: PaymentMethod): PaymentMethodInfo | null {
  const info = { paymentMethod: pm, active: false, type: pm.type ?? PaymentMethodType.Unknown } as PaymentMethodInfo;

  const label = paymentMethodName(pm);
  info.logoSrc = paymentMethodLogo(pm);

  if (pm.card) {
    const number = `${label} •••• ${pm.card?.last4!}`;
    const brand = pm.card?.brand ?? PaymentMethodCardBrand.Unknown;
    const exp = `${pm.card?.expMonth!}/${pm.card?.expYear!}`;
    const { card } = pm;

    info.cardInfo = {
      number, exp, brand, card,
    };
  }

  if (pm.sepaDebit) {
    const number = `${label} •••• ${pm.sepaDebit?.last4!}`;
    const country = pm.sepaDebit.country ?? '';
    const branchCode = pm.sepaDebit.branchCode ?? '';
    const bankCode = pm.sepaDebit.bankCode ?? '';
    const generatedFromType = pm.sepaDebit.generatedFromType ?? '';
    const { sepaDebit } = pm;

    info.sepaDebitInfo = {
      number, country, bankCode, branchCode, generatedFromType, sepaDebit,
    };
  }

  return info;
}

export interface CustomerInfo {
  name?: string;
  street?: string;
  city?: string;
  country?: string;
  balance?: string;
}

export function balanceText(c: Customer): string | undefined {
  if (!c.currency) return undefined;

  // cash balance amounts by currency
  const balances = new Map<string, number>();
  c.cashBalances?.forEach((cb) => { if (cb.amount) balances.set(c.currency!, cb.amount!); });

  // balance parts
  const parts = [] as string[];

  // first customer balance part (credit balance + cash balance)
  const amount = (balances.get(c.currency) ?? 0) - (c.balance ?? 0);
  if (amount) parts.push(billingAmountText(amount, c.currency!));

  // remaining cash balances
  balances.forEach((ba, bc) => {
    if (bc === c.currency) return;
    if (ba) parts.push(billingAmountText(ba, bc));
  });

  return parts.join(', ');
}

export function customerInfo(c: Customer, countryByCode: Map<string, Country>): CustomerInfo | null {
  const { name } = c;
  const street = c.addressLine1;
  const country = countryByCode.get(c.country!)?.name;
  const city = `${c.postalCode} ${c.city}, ${c.state}`;
  // negative balance will decrease the amount due on the customer's next invoice -> show as positive number
  const balance = balanceText(c);
  return {
    name, street, city, country, balance,
  };
}

export interface PriceInfo {
  name: string;
  amount: string;
  interval?: string;
  billedInterval?: string;
  quantity?: number;
  price: Price;
  // eslint-disable-next-line no-use-before-define
  discountInfo?: DiscountInfo,
}

export function productName(product?: Product) {
  const name = product?.name ?? 'N/A';
  const key = `code.billing_product.${name.toLowerCase().replaceAll(' ', '_')}`;
  const text = i18n.tc(key);
  if (key === text) return name;
  return text;
}

export function priceInfo(p: Price): PriceInfo {
  const name = productName(p.product);
  const interval = intervalText(p);
  const billedInterval = i18n.t('label.billed_x', [billedIntervalText(p)]) as string;
  const amount = billingAmountText(p.amount!, p.currency!);
  const price = p;
  // eslint-disable-next-line no-use-before-define
  const dinfo = p.discount ? discountInfo(p.discount!) : undefined;
  return {
    name, interval, billedInterval, amount, price, discountInfo: dinfo,
  };
}

export function priceText(p: Price): string {
  const amount = billingAmountText(p.amount!, p.currency!);
  const name = productName(p.product);

  if (p.quantity && p.quantity > 1) return `${p?.quantity!} ${name} (${amount})`;
  return `${name} (${amount})`;
}

export function smsPriceInfo(p: Price): PriceInfo {
  const name = productName(p.product);
  const amount = billingAmountText(p.amount!, p.currency!);
  const quantity = p.quantity!;
  const price = p;
  return {
    name, quantity, amount, price,
  };
}

export interface SubscriptionInfo {
  name?: string;
  interval?: string;
  billedInterval?: string;
  amount?: string;
  paidUntil?: string;
  active?: boolean;
  canceled?: boolean;
  awaitingPayment?: boolean;
  ended?: boolean;
  sentInvoice?: boolean;
  subscription?: Subscription;
  // eslint-disable-next-line no-use-before-define
  discountInfo?: DiscountInfo,
}

export function subscriptionInfo(s: Subscription): SubscriptionInfo {
  const paidUntil = s.paidUntil ? localized.dateText(new Date(s.paidUntil! * 1000))! : undefined;
  const name = productName(s.price?.product);
  const interval = s.price ? intervalText(s.price!) : undefined;
  const billedInterval = s.price ? i18n.t('label.billed_x', [billedIntervalText(s.price!)]) as string : undefined;
  const amount = s.price ? billingAmountText(s.price!.amount!, s.price!.currency!) : undefined;
  const active = s.status === SubscriptionStatus.Active;
  const ended = s.status === SubscriptionStatus.Ended;
  const canceled = s.status === SubscriptionStatus.Canceled || !!s.canceledAt;
  const awaitingPayment = s.status === SubscriptionStatus.AwaitingPayment;
  const sentInvoice = s.collectionMethod === CollectionMethod.SendInvoice;
  const subscription = s;
  // eslint-disable-next-line no-use-before-define
  const dinfo = s.discount ? discountInfo(s.discount!) : undefined;
  return {
    name, interval, billedInterval, amount, paidUntil, canceled, awaitingPayment, active, ended, sentInvoice, subscription, discountInfo: dinfo,
  };
}

export interface InvoiceInfo {
  number: string;
  total: string;
  appliedBalance: string | undefined;
  amountDue: string;
  amountDueNumber: number;
  created: string;
  currency: string;
  color: string;
  open: boolean;
  paid: boolean;
  processing: boolean;
  status: string;
  items: { description: string, amount: string, priceInfo?: PriceInfo}[]
  invoice: Invoice;
  tax: string | undefined;
  // error: string | undefined;
  description: string;
  // eslint-disable-next-line no-use-before-define
  discountInfo?: DiscountInfo,
  paymentStatus: string;
}

export interface BankTransferInfo {

}

function invoiceColor(i: Invoice): string {
  const paid = i.status === InvoiceStatus.Paid;
  const open = i.status === InvoiceStatus.Open || i.status === InvoiceStatus.Uncollectible;
  const processing = i.paymentIntent?.status === IntentStatus.Processing;

  if (paid) return 'success';
  if (open && processing) return 'gray';
  if (open) return 'error';
  return '';
}

export interface IntentInfo {
  error?: string;
  mandateUrl?: string;
  mandateReference?: string;
  intent: PaymentIntent | SetupIntent;
  isSetup: boolean;
}

export interface IntentError {
  message?: string;
  type?: string;
  code?: string;
  declineCode?: string;
}

export function intentErrorText(error: IntentError): string | undefined {
  if (!error.type) return undefined;

  if (error.type === 'validation_error') return 'Validation failed';
  if (error.type === 'card_error') {
    if (error.code === 'card_declined') {
      if (error.declineCode === 'generic_decline') return 'Your card was declined';
      if (error.declineCode === 'insufficient_funds') return 'Your card was declined. Reason: insufficient funds';
      if (error.declineCode === 'lost_card') return 'Your card was declined. Reason: card is reported as lost';
      if (error.declineCode === 'stolen_card') return 'Your card was declined. Reason: card is reported as stolen';
      if (error.declineCode === 'card_velocity_exceeded') return 'Your card was declined. Reason: balance or credit limit or amount limit exceeded';

      if (error.declineCode === 'expired_card') return 'Your card was declined. Reason: card expired';
      if (error.declineCode === 'incorrect_cvc') return 'Your card was declined. Reason: incorrect cvc';
      if (error.declineCode === 'incorrect_number') return 'Your card was declined. Reason: incorrect number';
      if (error.declineCode === 'processing_error') return 'Your card was declined. Reason: processing error';

      return 'Your card was declined';
    }

    if (error.declineCode === 'generic_decline') return 'Your payment was declined';

    if (error.code === 'expired_card') return 'Your card was declined. Reason: card expired';
    if (error.code === 'incorrect_cvc') return 'Your card was declined. Reason: incorrect cvc';
    if (error.code === 'incorrect_number') return 'Your card was declined. Reason: incorrect number';
    if (error.code === 'processing_error') return 'Your payment was declined. Reason: processing error';

    // message only for card errors
    if (error.message) return error.message;
  }

  return 'Operation failed';
}

function errorFromIntent(intent: PaymentIntent | SetupIntent): IntentError | undefined {
  const error = {
    type: intent.lastErrorType,
    message: intent.lastError,
    code: intent.lastErrorCode,
    declineCode: intent.lastDeclineCode,
  };

  if (error.type) return error;

  if (
    // all ok
    intent.status === IntentStatus.Succeeded
  || (
  // customer balance
    (intent.status === IntentStatus.RequiresAction
      || intent.status === IntentStatus.RequiresPaymentMethod
      || intent.status === IntentStatus.RequiresConfirmation
    ) && (intent as PaymentIntent)?.nextAction)
  // sepa
  || intent.status === IntentStatus.Processing) {
    return undefined;
  }

  return {
    type: 'operation_failed',
  };
}

export function intentInfo(i: PaymentIntent | SetupIntent): IntentInfo {
  const intent = i;
  const ie = errorFromIntent(i);
  const error = ie ? intentErrorText(ie) : undefined;
  const mandateUrl = i.mandate?.url;
  const mandateReference = i.mandate?.reference;
  const isSetup = (i as PaymentIntent).amount === undefined && !i.ID?.startsWith('pi_');

  return {
    error,
    mandateReference,
    mandateUrl,
    intent,
    isSetup,
  };
}

export function effectiveAmountDue(i: Invoice, p?: { balance?: number, cashBalance?: number }): { amountDue: number, appliedBalance: number } {
  // initial amount due
  let amountDue = i.total ?? 0;

  // invoice balance from invoice (if finalized)
  let appliedBalance = i.status !== InvoiceStatus.Draft ? (i.startingBalance ?? 0) - (i.endingBalance ?? 0) : 0;

  // draft invoice -> invoice balance from params
  if (p?.balance && i.status === InvoiceStatus.Draft) {
    const applied = amountDue + p.balance < 0 ? -amountDue : p.balance;
    appliedBalance = applied;
    amountDue += applied;
  }

  // customer balance invoice -> cash balance from params
  if (p?.cashBalance && i.status === InvoiceStatus.Draft) {
    const applied = amountDue + p.cashBalance <= 0 ? -amountDue : 0; // whole amount or nothing
    appliedBalance += applied;
    amountDue += applied;
  }

  return { amountDue, appliedBalance };
}

export function invoiceInfo(i: Invoice, p?: { balance?: number, cashBalance?: number }): InvoiceInfo {
  const items = (i.lines ?? []).map((il) => {
    const amount = billingAmountText(il.amount!, il.currency!);
    const quantity = il.quantity ?? 1;
    let pinfo = undefined as PriceInfo | undefined;

    let description = il.description!;
    if (il.price) {
      pinfo = priceInfo(il.price);
      if (il.price.quantity && il.price.quantity > 1) {
        description = `${il.price.quantity!} ${productName(il.price.product)}`;
      } else {
        description = `${productName(il.price.product)}`;
      }
    }
    if (quantity > 1) description = `${quantity}x - ${description}`;

    return { description, amount, priceInfo: pinfo };
  });

  const total = billingAmountText(i.total!, i.currency!);
  const created = localized.dateText(new Date(i.created! * 1000))!;
  const number = i.number!;
  const currency = i.currency!;
  const color = invoiceColor(i);
  const open = i.status === InvoiceStatus.Open || i.status === InvoiceStatus.Uncollectible;
  const paid = i.status === InvoiceStatus.Paid;
  const processing = i.paymentIntent?.status === IntentStatus.Processing;
  const status = open && processing ? IntentStatus.Processing : i.status!;
  const error = i.paymentIntent?.lastError ?? undefined;
  const tax = i.tax ? billingAmountText(i.tax!, i.currency!) : undefined;
  const description = items.map((item) => item.description).join(', ');
  const invoice = i;

  // simulate apply balances (negative values = customer has credit)
  const { amountDue: amountDueNumber, appliedBalance: appliedBalanceNumber } = effectiveAmountDue(i, p);

  // negative values = customer has credit -> display as positive value
  const appliedBalance = appliedBalanceNumber ? billingAmountText(-appliedBalanceNumber, i.currency!) : undefined;
  const amountDue = billingAmountText(amountDueNumber, i.currency!);

  // eslint-disable-next-line no-use-before-define
  const dinfo = i.discount ? discountInfo(i.discount!) : undefined;

  // extended status
  const paymentStatus = i.paymentIntent?.status ?? 'none';

  return {
    number,
    total,
    appliedBalance,
    amountDueNumber,
    amountDue,
    created,
    currency,
    color,
    open,
    paid,
    processing,
    status,
    items,
    tax,
    // error,
    description,
    invoice,
    discountInfo: dinfo,
    paymentStatus,
  };
}

// premium subscription credit info
export interface PremiumBalanceTransactionInfo {
  amount: string;
  oldExpireTime: string;
  newExpireTime: string;
  transaction: PremiumBalanceTransaction;
}

export function premiumBalanceTransactionInfo(bt: PremiumBalanceTransaction)
  : PremiumBalanceTransactionInfo {
  const btamount = bt.amount! > 0 ? bt.amount : -bt.amount!;
  const amount = billingAmountText(btamount!, bt.currency!);
  const oldExpireTime = localized.dateText(new Date(bt.oldExpireTime! * 1000))!;
  const newExpireTime = localized.dateText(new Date(bt.newExpireTime! * 1000))!;
  const transaction = bt;

  return {
    amount,
    oldExpireTime,
    newExpireTime,
    transaction,
  };
}

// discount info
export interface DiscountInfo {
  name :string;
  start? :string;
  end? :string;
  percentOff? :string;
  amountOff? :string;
  originalAmount? :string;
  discountedAmount? :string;
  discountAmount? :string;
  expiresAt? :string;
  duration? :string;
  description? :string;
  discount? :Discount;
}

// TODO: translate
function billingDiscountDuration(duration: DiscountDuration, durationInMonths?: number): string | undefined {
  switch (duration) {
    case DiscountDuration.Once: return 'applicable once';
    case DiscountDuration.Forever: return undefined;
    case DiscountDuration.Repeating: return durationInMonths === 0 ? 'for 1 month' : `for ${durationInMonths} months`;
    default: return undefined;
  }
}

function discountDescription(d: Discount): string {
  const name = d.name ?? 'N/A';

  const percentOff = d.percentOff ? `${d.percentOff}%` : undefined;
  const amountOff = d.amountOff && d.currency && d.amountOff[d.currency ?? ''] ? billingAmountText(d.amountOff![d.currency!], d.currency!) : undefined;
  const duration = billingDiscountDuration(d.duration!, d.durationInMonths);
  const discount = `${d.percentOff ?? amountOff} off${duration ? ` ${duration}` : ''}`;

  const expiresAt = d.expiresAt ? localized.dateText(new Date(d.expiresAt! * 1000))! : undefined;
  const end = d.end ? localized.dateText(new Date(d.end! * 1000))! : undefined;
  const endOrExpire = (end ? ` (until ${end}) ` : undefined)
    ?? (!d.start && expiresAt ? ` (offer expires on ${expiresAt})` : undefined)
    ?? '';

  return `${name}: ${discount} ${endOrExpire}`;
}

export function discountInfo(d: Discount): DiscountInfo {
  const name = d.name ?? 'N/A';
  const start = d.start ? localized.dateText(new Date(d.start! * 1000))! : undefined;
  const end = d.end ? localized.dateText(new Date(d.end! * 1000))! : undefined;
  const percentOff = d.percentOff ? `${d.percentOff}%` : undefined;
  const amountOff = d.amountOff && d.currency && d.amountOff[d.currency ?? ''] ? billingAmountText(d.amountOff![d.currency!], d.currency!) : undefined;
  const originalAmount = d.originalAmount ? billingAmountText(d.originalAmount, d.currency!) : undefined;
  const discountedAmount = d.discountedAmount ? billingAmountText(d.discountedAmount, d.currency!) : undefined;
  const discountAmount = d.originalAmount && d.discountedAmount ? billingAmountText(-d.originalAmount + d.discountedAmount, d.currency!) : undefined;
  const expiresAt = d.expiresAt ? localized.dateText(new Date(d.expiresAt! * 1000))! : undefined;
  const duration = billingDiscountDuration(d.duration!, d.durationInMonths);
  const description = discountDescription(d);
  const discount = d;

  return {
    name,
    start,
    end,
    percentOff,
    amountOff,
    originalAmount,
    discountedAmount,
    discountAmount,
    expiresAt,
    duration,
    description,
    discount,
  };
}

// subscription actions
export interface SubscriptionActions {
  subscribe?: boolean,
  modify?: boolean,
  cancel?: boolean,
  delete?: boolean,
}

export function subscriptionActions(s?: Subscription | null): SubscriptionActions {
  const status = (s?.status ?? SubscriptionStatus.None) as SubscriptionStatus;

  switch (status) {
    case SubscriptionStatus.None:
    case SubscriptionStatus.Ended:
    case SubscriptionStatus.Canceled:
      return { subscribe: true };

    case SubscriptionStatus.AwaitingPayment:
    case SubscriptionStatus.Active:
      if (s?.canceledAt) return { subscribe: true };
      return { cancel: true };

    default: return {};
  }
}

// currencies
export function preferedCountryCurrency(country?: string): string | null {
  if (!country) return null;

  const eur = [
    // EU
    'BE', // Belgium
    'GR', // Greece
    'LT', // Lithuania
    'PT', // Portugal

    'BG', // Bulgaria
    'ES', // Spain
    'LU', // Luxembourg
    'RO', // Romania

    'CZ', // Czechia
    'FR', // France
    'HU', // Hungary
    'SI', // Slovenia

    'DK', // Denmark
    'HR', // Croatia
    'MT', // Matla
    'SK', // Slovakia

    'DE', // Germany
    'IT', // Italy
    'NL', // Netherlands
    'FI', // Finland

    'EE', // Estonia
    'CY', // Cyprus
    'AT', // Austria
    'SE', // Sweden

    'IE', // Ireland
    'LV', // Latvia
    'PL', // Poland

    // European Free Trade Association(EFTA)
    'IS', // Iceland
    'NO', // Norway
    'LI', // Liechtenstein
    'CH', // Switzerland
    'GB', // United Kingdom

    // EU candidate countries
    'ME', // Montenegro
    'MK', // North Macedonia
    'AL', // Albania
    'RS', // Serbia
    'TR', // Turkey
    // 'CS', // 'Serbia and Montenegro'

    'BA', // Bosnia and Herzegovina
    // 'XK', // Kosovo
  ];

  const gbp = [
    // 'IE', // Ireland
    'GB', // United Kingdom
  ];

  const chf = [
    'CH', // Switzerland
  ];

  // test
  // [...eur, ...gbp].forEach((cc) => {
  //   // eslint-disable-next-line @typescript-eslint/no-shadow
  //   const country = countries.find((c) => c.code === cc);
  //   console.log('Country: ', country?.code, country?.name);
  // });

  if (gbp.includes(country)) return 'gpb';
  if (chf.includes(country)) return 'chf';
  if (eur.includes(country)) return 'eur';
  return 'usd';
}

export function isEUCountry(country?: string): boolean | null {
  if (!country) return null;

  const eu = [
    // EU
    'BE', // Belgium
    'GR', // Greece
    'LT', // Lithuania
    'PT', // Portugal

    'BG', // Bulgaria
    'ES', // Spain
    'LU', // Luxembourg
    'RO', // Romania

    'CZ', // Czechia
    'FR', // France
    'HU', // Hungary
    'SI', // Slovenia

    'DK', // Denmark
    'HR', // Croatia
    'MT', // Matla
    'SK', // Slovakia

    'DE', // Germany
    'IT', // Italy
    'NL', // Netherlands
    'FI', // Finland

    'EE', // Estonia
    'CY', // Cyprus
    'AT', // Austria
    'SE', // Sweden

    'IE', // Ireland
    'LV', // Latvia
    'PL', // Poland
  ];

  return eu.includes(country);
}

export function isOriginCountry(country?: string): boolean | null {
  if (!country) return null;
  return country === 'NL';
}

export function isCountryWithStates(country?: string): boolean | null {
  if (!country) return null;
  return country === 'US';
}

export interface WarningBarInfo {
  infoKey: string,
  navKey: string,
  navLinkKey: string,
  buttonKey: string,
  extraText?: string,
}

export function isSubWarningBarInfo(billingInfo?: BillingInfo) {
  return ![
    BillingInfoSubscriptionStatus.VIP,
    BillingInfoSubscriptionStatus.TrialActive,
    BillingInfoSubscriptionStatus.SubActive,
  ].includes(billingInfo?.subStatus ?? BillingInfoSubscriptionStatus.None);
}

export function subWarningBarInfo(
  billingInfo?: BillingInfo,
  billingAllowed = true,
  compact = false,
): WarningBarInfo | null {
  if (!billingInfo) return null;

  console.log('warningText: billingInfo=', billingInfo);

  if ([BillingInfoSubscriptionStatus.VIP,
    BillingInfoSubscriptionStatus.TrialActive,
    BillingInfoSubscriptionStatus.SubActive,
  ].includes(billingInfo?.subStatus ?? BillingInfoSubscriptionStatus.None)) return null;

  const trialExpText = compact
    ? localized.veryShortDateText(billingInfo?.trialExpDate ?? null) ?? 'N/A'
    : localized.dateText(billingInfo?.trialExpDate ?? null) ?? 'N/A';

  const subExpText = compact
    ? localized.veryShortDateText(billingInfo.subExpDate ?? null) ?? 'N/A'
    : localized.dateText(billingInfo.subExpDate ?? null) ?? 'N/A';

  let extraText = 'N/A';

  let infoKey = '';
  let navKey = 'message.billing_warning_subscribe_navigation';
  const navLinkKey = 'message.billing_warning_subscribe_navigation_link';
  const buttonKey = 'message.billing_warning_review_subscription';

  if (billingInfo.subStatus === BillingInfoSubscriptionStatus.TrialWillExpire) {
    infoKey = 'message.billing_warning_trial_will_expire';
    extraText = trialExpText;
  } else if (billingInfo.subStatus === BillingInfoSubscriptionStatus.TrialExpired) {
    infoKey = 'message.billing_warning_trial_expired';
    extraText = trialExpText;
  } else if (
    billingInfo.subStatus === BillingInfoSubscriptionStatus.SubWillExpire
    || billingInfo.subStatus === BillingInfoSubscriptionStatus.SubWillExpirePayment) {
    infoKey = 'message.billing_warning_subscription_will_expire';
    extraText = subExpText;
  } else if (billingInfo.subStatus === BillingInfoSubscriptionStatus.SubExpired) {
    infoKey = 'message.billing_warning_subscription_expired';
    extraText = subExpText;
  } else if (billingInfo.subStatus === BillingInfoSubscriptionStatus.SubExpiredPayment) {
    infoKey = 'message.billing_warning_subscription_expired_payment';
    extraText = subExpText;
  } else {
    infoKey = 'message.billing_warning_no_subscription';
  }

  if (!billingAllowed) {
    navKey = 'message.billing_warning_billing_navigation_notallowed';
  }

  if (compact) infoKey = `${infoKey}_compact`;

  return {
    infoKey, navKey, navLinkKey, buttonKey, extraText,
  };
}

export function isSMSWarningBarInfo(billingInfo?: BillingInfo) {
  return ![
    BillingInfoSMSStatus.VIP,
    BillingInfoSMSStatus.None,
    BillingInfoSMSStatus.Active,
  ].includes(billingInfo?.smsStatus ?? BillingInfoSMSStatus.None);
}

export function smsWarningBarInfo(
  billingInfo?: BillingInfo,
  billingAllowed = true,
  compact = false,
): WarningBarInfo | null {
  if (!billingInfo) return null;

  const extraText = String(billingInfo.smsBalance);

  let infoKey = '';
  let navKey = 'message.billing_warning_sms_navigation';
  const navLinkKey = 'message.billing_warning_sms_navigation_link';
  const buttonKey = 'message.billing_warning_review_sms';

  if (
    billingInfo.smsStatus === BillingInfoSMSStatus.VIP
    || billingInfo.smsStatus === BillingInfoSMSStatus.Active
    || billingInfo.smsStatus === BillingInfoSMSStatus.None
    || billingInfo.smsStatus === BillingInfoSMSStatus.PaymentError // handled by payment warning
  ) {
    return null;
  } if (billingInfo.smsStatus === BillingInfoSMSStatus.LowBalance) {
    infoKey = 'message.billing_warning_sms_low_balance';
  } else if (billingInfo.smsStatus === BillingInfoSMSStatus.ZeroBalance) {
    infoKey = 'message.billing_warning_sms_zero_balance';
  } else {
    return null;
  }

  if (!billingAllowed) {
    navKey = 'message.billing_warning_billing_navigation_notallowed';
  }

  return {
    infoKey, navKey, navLinkKey, buttonKey, extraText,
  };
}

export function isPaymentWarningBarInfo(billingInfo?: BillingInfo) {
  return BillingInfoSMSStatus.PaymentError === billingInfo?.smsStatus;
}

export function paymentWarningBarInfo(
  billingInfo?: BillingInfo,
  billingAllowed = true,
  compact = false,
): WarningBarInfo | null {
  if (!billingInfo) return null;
  if (billingInfo.smsStatus !== BillingInfoSMSStatus.PaymentError) return null;

  const infoKey = 'message.billing_warning_payment_error';
  let navKey = 'message.billing_warning_payment_error_navigation';
  const navLinkKey = 'message.billing_warning_payment_error_navigation_link';
  const buttonKey = 'message.billing_warning_review_payment';

  if (!billingAllowed) {
    navKey = 'message.billing_warning_billing_navigation_notallowed';
  }

  return {
    infoKey, navKey, navLinkKey, buttonKey,
  };
}

// billing alert info
export interface AlertInfo {
  message: string,
  type: string,
  color?: string,
  buttons: {
    text: string,
    color: string,
    action: () => void,
  }[]
}

const ActiveSubscriptionStatuses = [
  SubscriptionStatus.Active,
  SubscriptionStatus.AwaitingPayment,
  SubscriptionStatus.Canceled,
];

export function isSubscriptionActive(s: Subscription): boolean {
  return ActiveSubscriptionStatuses.includes(s.status ?? SubscriptionStatus.None);
}

// vat validation
export function validateVatNumberFormat(number: string): boolean {
  // fix number
  let n = number;
  n = n.replace(/ /g, '');
  n = n.replace(/\./g, '');
  n = n.replace(/-/g, '');

  // validate number format
  const patterns = new Map<string, string>([
    ['AT', 'U[A-Z0-9]{8}'],
    ['BE', '(0[0-9]{9}|[0-9]{10})'],
    ['BG', '[0-9]{9,10}'],
    // ['CH', '(?:E(?:-| )[0-9]{3}(?:\.| )[0-9]{3}(?:\.| )[0-9]{3}( MWST)?|E[0-9]{9}(?:MWST)?)'],
    ['CH', '(?:E(?:-| )[0-9]{3}(?:.| )[0-9]{3}(?:.| )[0-9]{3}( MWST)?|E[0-9]{9}(?:MWST)?)'],
    ['CY', '[0-9]{8}[A-Z]'],
    ['CZ', '[0-9]{8,10}'],
    ['DE', '[0-9]{9}'],
    ['DK', '[0-9]{8}'],
    ['EE', '[0-9]{9}'],
    ['EL', '[0-9]{9}'],
    ['ES', '[A-Z][0-9]{7}[A-Z]|[0-9]{8}[A-Z]|[A-Z][0-9]{8}'],
    ['FI', '[0-9]{8}'],
    ['FR', '([A-Z]{2}|[0-9]{2})[0-9]{9}'],
    ['GB', '[0-9]{9}|[0-9]{12}|(GD|HA)[0-9]{3}'],
    ['HR', '[0-9]{11}'],
    ['HU', '[0-9]{8}'],
    ['IE', '[A-Z0-9]{7}[A-Z]|[A-Z0-9]{7}[A-W][A-I]'],
    ['IT', '[0-9]{11}'],
    ['LT', '([0-9]{9}|[0-9]{12})'],
    ['LU', '[0-9]{8}'],
    ['LV', '[0-9]{11}'],
    ['MT', '[0-9]{8}'],
    ['NL', '[0-9]{9}B[0-9]{2}'],
    ['PL', '[0-9]{10}'],
    ['PT', '[0-9]{9}'],
    ['RO', '[0-9]{2,10}'],
    ['SE', '[0-9]{12}'],
    ['SI', '[0-9]{8}'],
    ['SK', '[0-9]{10}'],
  ]);

  if (n.length < 3) {
    return false;
  }

  const un = n.toUpperCase();
  const pattern = patterns.get(un.slice(0, 2));
  if (!pattern) {
    return false;
  }

  return new RegExp(`^${pattern}$`).test(un.slice(2)); // added start and end of string anchors
}

export interface BankTransferInstructionsInfo {
  amountRemaining?: string;
  reference?: string;
  accountHolderName?: string;
  BIC?: string;
  IBAN?: string;
  formattedIBAN?: string;
}

function formatIBAN(iban: string): string {
  return iban.replace(/.{4}/g, (a) => `${a} `);
}

export function bankTransferInstructionsInfo(i: NextActionDisplayIBANBankTransferInstructions): BankTransferInstructionsInfo {
  const amountRemaining = billingAmountText(i.amountRemaining!, i.currency!);
  const {
    reference, accountHolderName, BIC, IBAN,
  } = i;

  const formattedIBAN = IBAN ? formatIBAN(IBAN) : undefined;

  return {
    amountRemaining,
    reference,
    accountHolderName,
    BIC,
    IBAN,
    formattedIBAN,
  };
}

export function toDTOCustomer(bc: Customer): Customer {
  const {
    name, email, city, country, addressLine1, addressLine2, postalCode, state, vatNumber, locale,
  } = { ...bc };
  return {
    name, email, city, country, addressLine1, addressLine2, postalCode, state, vatNumber, locale,
  };
}

export function fixedPaymentInfo(info: string): string {
  const matched = info.match(/^(\d+)[\\.,](\d?\d?) (\w{3})$/);
  if (matched && matched?.length === 4) {
    // const amount = parseInt(matched[1] + matched[2], 10);
    const amount = parseInt(matched[1] + matched[2], 10);
    const currency = matched[3].toLowerCase();
    if (amount > 0) return billingAmountText(amount, currency);
  }

  return info;
}
