import { defineComponent } from 'vue';
import Mustache from 'mustache';
import Reservation, { ReservationStatus } from '@/model/Reservation';
import templateGuests from '@/../static/guests.mustache';
import VueI18n from 'vue-i18n';
import Contact from '@/model/Contact';
import VueLocalizedFormatter from '@/plugins/vue-localized-formatter/src/formatter';
import DayNote from '@/model/DayNote';
import { mapBySlotToMapByTimeText, toSortedMapBySlot } from '@/services/reservations-utils';

class ReservationForExport {
  tab?: string | null;

  partySize: number;

  dateEndText: string | null;

  fixedEnding: boolean;

  contact: Contact;

  status: string;

  label?: string | null;

  service?: string | null;

  isStarred: boolean;

  isFlagged: boolean;

  notes: string | null;

  tabItems: string | null;

  paymentInfo?: string;

  constructor(r: Reservation, i18n: VueI18n, localized: VueLocalizedFormatter) {
    this.tab = r.tab?.name;
    this.partySize = r.partySize;
    this.dateEndText = localized.hourMinText(r.dateEnd);
    this.fixedEnding = r.fixedEnding || false;
    this.contact = r.contact;
    this.status = i18n.tc(`code.status.${r.statusText}`);
    this.label = r.label?.text;
    this.service = r.service?.text;
    this.isStarred = r.isStarred || false;
    this.isFlagged = r.isFlagged || false;
    this.notes = '';
    if (r.contact.phone) {
      this.notes = `✆ ${r.contact.phone}`;
    }
    if (r.notes) {
      this.notes = `${this.notes} ${this.notes.length > 0 ? ' | ' : ''}${i18n.t('label.note')}: ${r.notes}`;
    }
    this.tabItems = r.tabItems.map((t) => t.no).join(', ');
    this.paymentInfo = r.paymentInfo;
  }
}

export default defineComponent({
  data() {
    return { };
  },
  computed: {
    printReservations(): Reservation[] {
      return [];
    },
    dayNotes(): DayNote[] {
      return this.$tstore.getters.dayDayNotes;
    },
    reservationsByTime(): Map<string, Reservation[]> | [] {
      if (this.printReservations.length === 0) {
        return [];
      }

      const reservationsBySlot = toSortedMapBySlot(this.printReservations);
      const reservationsByTime = mapBySlotToMapByTimeText(reservationsBySlot);

      const ret = [] as any;
      reservationsByTime.forEach((value: Reservation[], key: string) => {
        const r = [] as any;
        value.forEach((reservation) => {
          r.push(new ReservationForExport(reservation, this.$i18n, this.$localized));
        });
        ret.push({ label: this.$localized.hourMinTextFrom24hr(key), reservations: r });
      });
      return ret;
    },
    accountName(): string {
      return this.$tstore.state.settings.account.accountName ?? 'N/A';
    },
    dayStats() {
      return this.$tstore.getters.dayStats;
    },
    showDayStats() {
      return this.$tstore.state.settings.accountSettings.showLunchDinner ?? true;
    },
    filterText(): string {
      let ret = '';

      if (this.$tstore.state.filter.from !== '06:00') {
        ret = `${this.$i18n.tc('label.filter_time_from')}:
        <b>${this.$localized.hourMinTextFrom24hr(this.$tstore.state.filter.from.toString())}</b>`;
      }

      if (this.$tstore.state.filter.to !== '24:00') {
        ret = `${ret} ${this.$i18n.tc('label.filter_time_until')}:
        <b>${this.$localized.hourMinTextFrom24hr(this.$tstore.state.filter.to.toString())}</b>`;
      }

      if (this.$tstore.state.filter.tab !== null) {
        ret = `${ret} ${this.$i18n.tc('label.section')}:
            <b>${this.$tstore.state.filter.tab.name.replace(/ /g, '\u00a0')}</b>`;
      }

      if (this.$tstore.state.filter.status !== ReservationStatus.Valid) {
        ret = `${ret} ${this.$i18n.tc('label.status')}: <b>${this.$tstore.state.filter.status}</b>`;
      }

      return ret;
    },
  },
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    print() {
      let totalGuests = 0;
      this.printReservations.forEach((r) => { totalGuests += r.partySize; });

      const tabItems = new Set<number>();
      this.printReservations.forEach((r) => { r.tabItems.forEach((ti) => { tabItems.add(ti.id); }); });

      const rendered = Mustache.render(templateGuests, {
        i18n: {
          noReservations: this.$i18n.tc('placeholder.no_reservations'),
          note: this.$i18n.tc('label.note'),
          printedOn: this.$i18n.tc('label.printed_on'),
          noName: this.$i18n.tc('placeholder.no_name'),
          ends: this.$i18n.tc('label.reservation_ending_time'),
          print: this.$i18n.tc('button.print'),
          close: this.$i18n.tc('button.close'),
        },
        printDebug: process.env.VUE_APP_DEBUG_EXPORT_PRINT_DIALOG === 'true',
        reservations: this.printReservations,
        reservationsByTime: this.reservationsByTime,
        reportDate: this.$localized.dateText(this.$tstore.state.update.date),
        printDate: this.$localized.dateTimeText(new Date()),
        filterText: this.filterText,
        accountName: this.accountName,
        stats: this.$i18n.t(
          'label.reservations_x_tables_x_guests_x',
          [this.printReservations.length, tabItems.size, totalGuests],
        ),
        showDayStats: this.showDayStats,
        dayStats:
        `${this.$i18n.t('label.lunch_dinner')} <b>${this.dayStats.lunch}</b> / <b>${this.dayStats.dinner}</b>`,
        dayNotes: this.dayNotes,
      });

      const newWin = window.open('', 'windowName', 'height=924,width=810');
      newWin!.document.write(rendered);
      newWin!.document.close();
      newWin!.focus();
    },
  },
});
