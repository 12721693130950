/* eslint-disable max-len */
/* eslint-disable vue/max-len */
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import grpcClient from '@/grpc-api/grpc-client';
import { deleteModelEntities } from '@/grpc-api/model/model-utils';
import Feedback, {
  FeedbackMapping, FeedbackFilter, FeedbackSortFncType, FeedbackSortType,
} from '@/grpc-api/model/feedback';
import {
  DateRange, DateRangePeriod, areDateRangesEqual, dateRangeFromPeriod,
} from '@/services/date-range';
import { dateIndexFromDate, unixFromDate } from '@/services/time-utils';
import FeedbackSettings, { FeedbackSettingsMapping } from '@/grpc-api/model/feedback-settings';
import IRootState, { IFeedbackState } from './store-state';

// eslint-disable-next-line no-use-before-define
function filterFeedbacks(feedbacks: Feedback[], filter: FeedbackFilter): Feedback[] {
  const filteredFeedbacks = feedbacks.filter((f) => {
    if (filter.name.length >= 3 && (f.contactName === undefined || !f.contactName.toLowerCase().includes(filter.name.toLowerCase()))) return false;
    if (filter.comments.length >= 3 && (f.comments === undefined || !f.comments.toLowerCase().includes(filter.comments.toLowerCase()))) return false;

    if (filter.foodRating.length > 0 && !filter.foodRating.includes(f.foodRating ?? 0)) return false;
    if (filter.serviceRating.length > 0 && !filter.serviceRating.includes(f.serviceRating ?? 0)) return false;
    if (filter.ambienceRating.length > 0 && !filter.ambienceRating.includes(f.ambienceRating ?? 0)) return false;
    if (filter.overallRating.length > 0 && !filter.overallRating.includes(f.overallRating ?? 0)) return false;
    return true;
  });
  return filteredFeedbacks;
}

export class FeedbackState implements IFeedbackState {
  settings: FeedbackSettings | null = null;

  dateRange: DateRange = dateRangeFromPeriod(DateRangePeriod.Last90Days);

  filter: FeedbackFilter = {
    name: '',
    comments: '',
    foodRating: [1, 2, 3, 4, 5],
    serviceRating: [1, 2, 3, 4, 5],
    ambienceRating: [1, 2, 3, 4, 5],
    overallRating: [1, 2, 3, 4, 5],
  };

  selectedFeedback: Feedback | null = null;

  feedbacks: Feedback[] = [];

  filteredFeedbacks: Feedback[] = [];

  isLoading: boolean = false;

  isLoaded: boolean = false;

  isFullyLoaded: boolean = false;

  visiblePageStart: number = 0;

  visiblePageSize: number = 25;

  feedbackSortType: FeedbackSortType = FeedbackSortType.Created;

  feedbackSortDesc: boolean = true;

  feedbackCompareFnc: FeedbackSortFncType = Feedback.compareFnc(FeedbackSortType.Created);
}

const mutations = <MutationTree<IFeedbackState>>{
  RESET(state: FeedbackState) {
    Object.assign(state, new FeedbackState());
  },
  RESET_FEEDBACK(state: FeedbackState) {
    Object.assign(state, new FeedbackState());
  },
  UPDATE_FEEDBACK_SETTINGS(state: FeedbackState, p: { settings: FeedbackSettings }) {
    state.settings = p.settings;
  },
  UPDATE_FEEDBACK_SELECTED(state: FeedbackState, p: { feedback: Feedback|null }) {
    if (p.feedback !== undefined) state.selectedFeedback = p.feedback;
  },
  UPDATE_FEEDBACK_REFRESHED(state: FeedbackState, p: { feedbacks: Feedback[] }) {
    const newSelectedFeedback = p.feedbacks.find((f) => state.selectedFeedback?.id === f.id);
    if (newSelectedFeedback) state.selectedFeedback = newSelectedFeedback;

    deleteModelEntities(state.feedbacks, p.feedbacks);
    state.feedbacks.push(...p.feedbacks);
    state.filteredFeedbacks = filterFeedbacks(state.feedbacks, state.filter);
    state.filteredFeedbacks.sort(state.feedbackCompareFnc);
    console.log('UPDATE_FEEDBACK_REFRESHED: refreshed feedbacks=', p.feedbacks);
  },
  UPDATE_FEEDBACK_LOADED(state: FeedbackState, p: { dateRange?: DateRange, feedbacks?: Feedback[], additionalFeedbacks?: Feedback[], isLoading?: boolean, isLoaded?: boolean, isFullyLoaded?: boolean }) {
    if (p.dateRange) { state.dateRange = p.dateRange; }

    if (p.isLoading !== undefined) state.isLoading = p.isLoading;
    if (p.isLoaded !== undefined) state.isLoaded = p.isLoaded;
    if (p.isFullyLoaded !== undefined) state.isFullyLoaded = p.isFullyLoaded;

    if (p.feedbacks !== undefined) {
      state.feedbacks = [...p.feedbacks];
      state.filteredFeedbacks = [...p.feedbacks];
    }

    if (p.additionalFeedbacks !== undefined) {
      state.feedbacks.push(...p.additionalFeedbacks);
      state.filteredFeedbacks.push(...p.additionalFeedbacks);
    }

    if (state.isFullyLoaded) {
      state.filteredFeedbacks = filterFeedbacks(state.feedbacks, state.filter);
      state.filteredFeedbacks.sort(state.feedbackCompareFnc);
    }

    console.log('UPDATE_FEEDBACK_LOADED: ', `${state.feedbacks.length} feedbacks loaded`);
  },
  UPDATE_FEEDBACK_PAGE(state: FeedbackState, p: { pageSize?: number, pageStart?: number }) {
    if (p.pageSize) state.visiblePageSize = p.pageSize;
    if (p.pageStart !== undefined) state.visiblePageStart = p.pageStart;
  },
  APPLY_FEEDBACK_FILTER(state: FeedbackState, p: { filter: FeedbackFilter }) {
    state.filter = p.filter;
    state.filteredFeedbacks = filterFeedbacks(state.feedbacks, state.filter);
    state.filteredFeedbacks.sort(state.feedbackCompareFnc);
  },
  UPDATE_FEEDBACK_SORT(state: FeedbackState, p: { sortType?: FeedbackSortType, sortDesc?: boolean }) {
    if (p.sortType !== undefined) state.feedbackSortType = p.sortType;
    if (p.sortDesc !== undefined) state.feedbackSortDesc = p.sortDesc;

    state.feedbackCompareFnc = state.feedbackSortDesc ? Feedback.compareFncDesc(state.feedbackSortType) : Feedback.compareFnc(state.feedbackSortType);
    state.filteredFeedbacks.sort(state.feedbackCompareFnc);
  },
};

const actions = <ActionTree<IFeedbackState, IRootState>>{
  async resetFeedback({ commit, rootGetters }) {
    commit('RESET_FEEDBACK');
  },
  async loadFeedback({
    state, rootState, commit, rootGetters,
  }, p?: { errorFnc?: (e: any) => void, dateRange?: DateRange }): Promise<boolean> {
    if (!rootGetters.isLoaded || state.isLoading) return false;

    commit('UPDATE_FEEDBACK_LOADED', {
      dateRange: p?.dateRange, feedbacks: [], isLoading: true, isLoaded: false, isFullyLoaded: false,
    });

    if (!state.settings) {
      const settings = await grpcClient.getFeedbackSettings(rootGetters as FeedbackSettingsMapping);
      commit('UPDATE_FEEDBACK_SETTINGS', { settings });
    }

    const onData = (additionalFeedbacks: Feedback[]) => {
      const isLoaded = true;
      const isFullyLoaded = additionalFeedbacks.length === 0;
      commit('UPDATE_FEEDBACK_LOADED', {
        additionalFeedbacks, isLoading: !isFullyLoaded, isLoaded, isFullyLoaded,
      });
    };

    const onError = (error: Error) => {
      commit('UPDATE_FEEDBACK_LOADED', { isLoading: false });
      if (p?.errorFnc) p.errorFnc(error);
    };

    grpcClient.listFeedbacks(
      dateIndexFromDate(state.dateRange.beginDate),
      dateIndexFromDate(state.dateRange.endDate),
      undefined,
      onData,
      onError,
      rootGetters as FeedbackMapping,
    );

    return true;
  },
  async refreshFeedback({
    state, dispatch, commit, rootGetters,
  }, p?: { errorFnc?: (e: any) => void }): Promise<boolean> {
    if (!rootGetters.isLoaded || state.isLoading) return false;

    const dateRange = dateRangeFromPeriod(state.dateRange.period, state.dateRange.firstDayOfWeek);
    const areEqual = areDateRangesEqual(state.dateRange, dateRange);
    if (!areEqual) {
      commit('UPDATE_FEEDBACK_LOADED', { dateRange });
    }

    // not loaded -> regular load
    if (!state.isFullyLoaded) {
      return dispatch('loadFeedback', {});
    }

    // refresh
    const onData = (feedbacks: Feedback[]) => {
      commit('UPDATE_FEEDBACK_REFRESHED', { feedbacks });
    };

    const newestDtCreate = state.feedbacks.reduce((maxdtc, f) => (maxdtc < f.created! ? f.created! : maxdtc), new Date(0));

    grpcClient.listFeedbacks(
      dateIndexFromDate(state.dateRange.beginDate),
      dateIndexFromDate(state.dateRange.endDate),
      newestDtCreate,
      onData,
      (error: Error) => { if (p?.errorFnc) p.errorFnc(error); },
      rootGetters as FeedbackMapping,
    );

    return true;
  },
  async feedbackApplyFilter({ rootState, commit }, filter: FeedbackFilter) {
    commit('APPLY_FEEDBACK_FILTER', { filter });
  },
  async updateFeedbackSort({ commit }, p: { sortType?: FeedbackSortType, sortDesc?: boolean }) {
    commit('UPDATE_FEEDBACK_SORT', p);
  },
  async updateFeedbackPage({ commit }, p: { pageSize?: number, pageStart?: number }) {
    commit('UPDATE_FEEDBACK_PAGE', p);
  },
  async getFeedback({ state, commit, rootGetters }, p: { id: number }): Promise<Feedback|undefined> {
    if (!rootGetters.isLoaded || !state.isLoaded) return undefined;
    const feedback = await grpcClient.getFeedback(p.id, rootGetters as FeedbackMapping);
    commit('UPDATE_FEEDBACK_REFRESHED', { feedbacks: [feedback] });
    return feedback;
  },
  async setSelectedFeedback({ state, commit, rootGetters }, p: { feedback: Feedback|null }) {
    commit('UPDATE_FEEDBACK_SELECTED', p);
  },
  async loadFeedbackSettings({ state, commit, rootGetters }): Promise<FeedbackSettings|undefined> {
    if (!rootGetters.isLoaded) return undefined;
    const settings = await grpcClient.getFeedbackSettings(rootGetters as FeedbackSettingsMapping);
    commit('UPDATE_FEEDBACK_SETTINGS', { settings });
    return settings;
  },
  async setFeedbackSettings({ state, commit, rootGetters }, p: { settings: FeedbackSettings}): Promise<FeedbackSettings|undefined> {
    if (!rootGetters.isLoaded) return undefined;
    const settings = await grpcClient.setFeedbackSettings(p.settings, rootGetters as FeedbackSettingsMapping);
    commit('UPDATE_FEEDBACK_SETTINGS', { settings });
    return settings;
  },
};

const getters = <GetterTree<IFeedbackState, IRootState>>{
  isFeedbackLoaded(state: FeedbackState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return state.isLoaded && rootGetters.isLoaded;
  },
  isFeedbackLoading(state: FeedbackState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return state.isLoading;
  },
  feedbackVisibleCount(state: FeedbackState): number {
    return state.filteredFeedbacks.length;
  },
  feedbackDateRange(state: FeedbackState): DateRange {
    return state.dateRange;
  },
  feedbackFilter(state: FeedbackState): FeedbackFilter {
    return state.filter;
  },
  feedbackVisibleFeedbacks(state: FeedbackState): Feedback[] {
    return state.filteredFeedbacks.slice(state.visiblePageStart, state.visiblePageStart + state.visiblePageSize);
  },
  selectedFeedback(state: FeedbackState): Feedback|undefined {
    return state.selectedFeedback ?? undefined;
  },
  feedbackSettings(state: FeedbackState): FeedbackSettings|undefined {
    return state.settings ?? undefined;
  },
};

const FeedbackStore = {
  namespaced: false,
  state: new FeedbackState(),
  mutations,
  actions,
  getters,
};

export default FeedbackStore;
