
import Vue from 'vue';
import { performAction } from '@/services/vue-utils';
import Reservation, { ReservationStatus, ReservationType } from '@/model/Reservation';
import GuestResultsList from '@/components/views/guests/GuestResultsList.vue';
import SearchList from '@/components/views/main/SearchList.vue';
import MainFloatingButton from '@/components/views/main/MainFloatingButton.vue';
import MainMenu from '@/components/views/main/MainMenu.vue';
import { tryEditReservation, tryCloseEditReservation } from '@/services/reservation-edit-utils';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import { isSafariOS } from '@/util/common';
import { startAutoUpdates } from '@/services/autoupdater';
import { startTodayUpdatesAsync } from '@/services/now-updater';
import '@/services/push-updater';
import { ApiError } from '@/api/api-error';
import OnboardingSteps from '@/components/views/onboarding/OnboardingSteps.vue';
import Welcome from '@/components/views/onboarding/Welcome.vue';
import BillingWarningBar from '@/components/views/settings/billing/BillingWarningBar.vue';
import LanguageSwitchDialog from '@/components/views/main/LanguageSwitchDialog.vue';
import {
  paymentWarningBarInfo, smsWarningBarInfo, subWarningBarInfo, WarningBarInfo,
} from '@/services/billing-utils';
import storage from '@/services/local-storage';
import { extraDocsLocales, supportedLanguages } from '@/plugins/i18n';
import PrivacyScreenView from '@/components/common/PrivacyScreenView.vue';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import requestFcmNotificationPermission from '@/services/fcm';
import PwaInstallBar from '@/components/util/PwaInstallBar.vue';

export default Vue.extend({
  name: 'Main',
  components: {
    GuestResultsList,
    SearchList,
    MainFloatingButton,
    MainMenu,
    ConfirmationDialog,
    Welcome,
    OnboardingSteps,
    BillingWarningBar,
    LanguageSwitchDialog,
    PrivacyScreenView,
    PwaInstallBar,
    MainFooter: () => import('@/components/views/main/MainFooter.vue'),
    BillingDialog: () => import('@/components/views/settings/billing/BillingDialog.vue'),
  },
  props: {},
  data() {
    return {
      drawer: this.$vuetify.breakpoint.mdAndUp,
      calendarDrawer: this.$vuetify.breakpoint.lgAndUp && this.$router.currentRoute.meta?.hasDatePicker === true,
      reservationDrawer: false,
      // this flag allows drawer to be dismissed by clicking onto overlay (stateless)
      // only after transition has completed (the drawer was presented)
      reservationDrawerDismissAllowed: false,
      manualCalendarState: true,
      searchMenu: false,
      miniMenu: this.$vuetify.breakpoint.mdAndUp,
      logoutConfirmation: false,
      showFooter: process.env.VUE_APP_SHOW_FOOTER === 'true',

      welcomeOpened: false,
      welcomeClosed: false,
      billingDialogOpened: false,
      billingDialogClosed: false,
      welcomeFabClosed: false,

      languagePrompt: undefined as string | undefined,
    };
  },
  computed: {
    privacyLeftOffset(): string {
      if (this.miniMenu && this.miniMenuAllowed) {
        return '56px';
      } if (!this.miniMenu && this.miniMenuAllowed) {
        return '267px';
      }
      return '0px';
    },
    billingSettingsVisible(): boolean {
      return this.$route.name === 'billing';
    },
    billingDialogVisible(): boolean {
      if (this.billingDialogOpened) return true;
      const isBillingLanding = this.$tstore.getters.isBillingLanding as boolean;
      const { isLoaded } = this.$tstore.state.update;
      return isLoaded && isBillingLanding && (!this.billingDialogClosed || !this.billingCanClose)
        && !this.welcomeVisible && !this.billingSettingsVisible;
    },
    billingCanClose(): boolean {
      const isBillingAppAccess = this.$tstore.getters.isBillingAppAccess as boolean;
      return isBillingAppAccess;
    },
    welcomeVisible(): boolean {
      if (this.welcomeOpened) return true;

      const { accountSettings } = this.$tstore.state.settings;
      const { isLoaded } = this.$tstore.state.update;
      return isLoaded && accountSettings.useSetupWelcome === true && !this.welcomeClosed && !this.billingDialogClosed;
    },
    welcomeFabVisible(): boolean {
      const { accountSettings } = this.$tstore.state.settings;
      const { isLoaded } = this.$tstore.state.update;
      return isLoaded && !this.welcomeVisible && !this.welcomeFabClosed
      && (
        accountSettings.useSetupContact === true
        || accountSettings.useSetupTables === true
        || accountSettings.useSetupHours === true
        || accountSettings.useSetupBranding === true
      );
    },
    isChangedReservation(): boolean {
      return this.$tstore.getters.isChangedEditReservation;
    },
    reservationEditorActive(): boolean {
      return this.$tstore.getters.isEditReservation;
    },
    dateText(): string | null {
      if (this.$tstore.getters.isToday) { return this.$i18n.tc('label.today'); }
      if (this.$tstore.getters.isThisYear) {
        return this.$vuetify.breakpoint.mdAndUp
          ? this.$localized.dayMonthText(this.$tstore.state.update.date)
          : this.$localized.shortDayMonthText(this.$tstore.state.update.date);
      }
      return this.$localized.shortWeekdayYearText(this.$tstore.state.update.date);
    },
    unreadReservations(): Map<Date, Reservation[]> {
      return this.$tstore.getters.unreadReservationsByDate;
    },
    unreadReservationsCount(): number {
      return this.$tstore.getters.unreadReservationsCount;
    },
    miniMenuAllowed(): boolean {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    updateError(): Error | null {
      return this.$tstore.state.update.updateError;
    },
    isLoaded(): boolean {
      return this.$tstore.getters.isLoaded;
    },
    isRegistered(): boolean {
      return this.$tstore.getters.isRegistered;
    },
    // warning bar
    isBillingWarning(): boolean {
      return this.$tstore.getters.isBillingSubWarning
        || this.$tstore.getters.isBillingSMSWarning
        || this.$tstore.getters.isBillingPaymentWarning;
    },
    subWarningBarInfo(): WarningBarInfo | null {
      return subWarningBarInfo(
        this.$store.getters.billingInfo,
        this.$tstore.getters.isBillingAllowed,
        this.$vuetify.breakpoint.xsOnly,
      );
    },
    smsWarningBarInfo(): WarningBarInfo | null {
      return smsWarningBarInfo(
        this.$store.getters.billingInfo,
        this.$tstore.getters.isBillingAllowed,

        this.$vuetify.breakpoint.xsOnly,
      );
    },
    paymentWarningBarInfo(): WarningBarInfo | null {
      return paymentWarningBarInfo(
        this.$store.getters.billingInfo,
        this.$tstore.getters.isBillingAllowed,

        this.$vuetify.breakpoint.xsOnly,
      );
    },
  },
  watch: {
    $route(to, from) {
      this.calendarDrawer = (this.manualCalendarState && this.$vuetify.breakpoint.lgAndUp)
      && to.meta.hasDatePicker === true;

      if (to.name === 'floorplan' && this.miniMenuAllowed) {
        this.miniMenu = true;
      }
    },
    async updateError(error: Error | ApiError | null) {
      if (error && (error as ApiError).isLogoutError) {
        console.log('Forced logout');
        try {
          await this.$tstore.dispatch('logout');
        } catch (e) {
          console.log('Logout error: ', e);
        }
        this.$vuetify.theme.dark = false;
        this.$router.push('/login');
      }
    },
    isLoaded(value: boolean) {
      if (!value) return;

      const authRequired = !this.$route.meta?.public;
      if (!authRequired) return;

      if (!this.isRegistered) {
        this.$vuetify.theme.dark = false;
        this.$router.push('/login');
      }
    },
    miniMenuAllowed() {
      this.miniMenu = this.miniMenuAllowed;
    },
    reservationEditorActive() {
      // console.log(`reservationEditorActive: ${this.reservationEditorActive}`);
      this.reservationDrawer = this.reservationEditorActive;

      // This is a Terrible Fix™️ for awful safari keyboard behavior
      // Safari will offset-shift all content upwards when there is a soft keyboard popup event
      // ref: https://github.com/vuetifyjs/vuetify/issues/3875

      if (!isSafariOS()) {
        return;
      }
      if (this.reservationEditorActive) {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = 'fixed';
        document.body.style.maxWidth = '100vw';
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.maxWidth = '';
        window.scrollTo({ top: parseInt(scrollY || '0', 10) * -1 });
      }
    },
  },
  created() {
    this.initialLoad();

    if (storage.getLanguagePromptDisplayed()) { return; }
    const browserLang = (navigator.languages[0] || '').split('-')[0];
    const otherLangs = supportedLanguages.map((l) => l.value).filter((l) => l !== 'en');
    if (storage.getLanguage()?.startsWith('en') && otherLangs.includes(browserLang)) {
      this.languagePrompt = browserLang;
    }

    if (window.matchMedia('(display-mode: standalone)').matches) {
      requestFcmNotificationPermission();
    }
  },
  methods: {
    async reservationDrawerStateChanged(val: boolean) {
      // console.log(`reservationDrawerStateChanged: ${val}`);
      if (val) { return; }
      this.reservationDrawerDismissAllowed = false;
      await tryCloseEditReservation();
    },
    reservationDrawerTransitionEnd(obj: any) {
      // set flag accordingly - allow empty dismiss only after the dialog was fully shown
      this.reservationDrawerDismissAllowed = this.reservationEditorActive;
    },
    toggleCalendar() {
      this.calendarDrawer = !this.calendarDrawer;
      this.manualCalendarState = this.calendarDrawer;
    },
    calendarDateSelected() {
      // dismiss on calendar selection when drawer presented as temporary
      if (this.$vuetify.breakpoint.smAndDown) {
        this.calendarDrawer = false;
      }
    },
    async logout() {
      await performAction(
        null,
        this.$i18n.tc('error.logout'),
        async () => this.$tstore.dispatch('logout'),
      );
      this.$vuetify.theme.dark = false;
      this.$router.push('/login');
    },
    async nextDay() {
      await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => this.$tstore.dispatch('changeToNextDate'),
      );
    },
    async previousDay() {
      await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => this.$tstore.dispatch('changeToPreviousDate'),
      );
    },
    async initialLoad() {
      await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => { await startTodayUpdatesAsync(); await startAutoUpdates(); },
      );
    },
    async reload() {
      await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => this.$tstore.dispatch('update'),
      );
    },
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    showHelp() {
      if (this.$route.meta?.helpUrl === undefined) { return; }
      const docsLocale = extraDocsLocales.find((l) => this.$localized.locale.startsWith(l)) || '';
      const l = docsLocale === '' ? '' : `${docsLocale}/`;
      const newWin = window.open(
        `https://docs.guestplan.com/#/${l}${this.$route.meta.helpUrl}`,
        'helpWindow',
        'height=600,width=600',
      );
      newWin!.document.close();
      newWin!.focus();
    },
    async editReservation() {
      await tryEditReservation(this.$router, undefined, { bookingType: ReservationType.Reservation });
    },
    async createWalkin() {
      await tryEditReservation(
        this.$router,
        undefined,
        { bookingType: ReservationType.Walkin, status: ReservationStatus.Seated },
      );
    },
  },
});
