
import Vue from 'vue';
import Tab from '@/model/Tab';
import Reservation from '@/model/Reservation';
import {
  slotFromMinutes, minutesFromTimeString, timeIndexFromSlot, dateFromDateAndTimeIndex, TIME_SLOTS_COUNT,
} from '@/services/time-utils';
import storage from '@/services/local-storage';
import { DEFAULT_TIMETABLE_START_TIME, DEFAULT_TIMETABLE_END_TIME } from '@/services/configuration';
import { findLastIndex, clamp } from '@/services/common';
import TimeSlot from '@/model/TimeSlot';
import { timetableEntities } from '@/util/timetable-entities';
import { performAction, performSaveAction } from '@/services/vue-utils';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import { mapActions } from 'vuex';
import { isSafari } from '@/util/common';
import KeyboardControl from '@/mixins/keyboard-control';
import MainToolbar from '@/components/views/main/MainToolbar.vue';
import TimeTableGrid from '@/components/views/tables/TimeTableGrid.vue';
import TableAllocationCTA from '@/components/util/TableAllocationCTA.vue';

export default Vue.extend({
  name: 'Tables',
  components: {
    TimeTableGrid, MainToolbar, ConfirmationDialog, TableAllocationCTA,
  },
  mixins: [KeyboardControl],
  props: { },
  data() {
    return {
      zoom: 1,
      zoomLevels: [
        { value: 0.51, text: '50%' },
        { value: 0.75, text: '75%' },
        { value: 1, text: '100%' },
        { value: 1.25, text: '125%' },
        { value: 1.50, text: '150%' },
        { value: 1.75, text: '175%' }],
      dayPart: null as null | number,
      gotoDayPart: null as null | number,
      dayParts: [
        { text: this.$i18n.t('label.morning'), value: 6 },
        { text: this.$i18n.t('label.afternoon'), value: 12 },
        { text: this.$i18n.t('label.evening'), value: 18 }],
      filterVisibleReq: false,
      relocateConfirmation: false,
      relocateConfirmationMessage: '',
      reservationsToRelocate: [] as Reservation[],
      // This is to account the case when the debug footer is shown
      heightOverrideForFooter: process.env.VUE_APP_SHOW_FOOTER === 'true'
        ? 'height: calc(100vh - 64px - 36px); margin-bottom: -36px !important;'
        : '',
    };
  },
  computed: {
    hasFilter(): boolean {
      return this.$tstore.state.filter.tablesTab !== null;
    },
    isToday(): boolean {
      return this.$tstore.getters.isToday;
    },
    heightForAppBar(): string {
      return this.$vuetify.breakpoint.smAndDown ? 'height: calc(100vh - 56px);' : '';
    },
    timetableEntities(): { tabs: Tab[], reservations: Reservation[] } {
      return timetableEntities(this.tabs, this.reservations);
    },
    tabFilter(): Tab | null {
      return this.$tstore.state.filter.tablesTab;
    },
    filterVisible(): boolean {
      return this.tabFilter !== null || this.filterVisibleReq;
    },
    //  null as Tab | null | { id?: number },
    tabs(): Tab[] {
      if (this.tabFilter?.id !== undefined) return [this.tabFilter as Tab];
      return this.$tstore.state.settings.tabs;
    },
    reservations(): Reservation[] {
      const dayValidReservationsAndBlocks = this.$tstore.getters.dayValidReservationsAndBlocks as Reservation[];
      return dayValidReservationsAndBlocks.filter((r) => !this.tabFilter?.id || r.tab?.id === this.tabFilter.id);
    },
    closedSlots(): {id: number, begin: Date, end: Date}[] {
      const closed: {id: number, begin: Date, end: Date}[] = [];

      const { dateIndex } = this.$tstore.state.update;

      let bci: number | null = null;
      let eci: number | null = null;

      for (let i = 0; i < this.slots.length + 1; i += 1) {
        let isOpen = i < this.slots.length ? this.slots[i].isOpen : true; // last dummy open slot
        const index = this.slots[0].slot + i;

        // quarter slot (:15, :45)
        if (index % 2 === 1 && i > 0) isOpen = isOpen || this.slots[i - 1].isOpen;

        if (!isOpen && !bci) bci = index; // begin slot index
        if (isOpen && bci) eci = index; // end slot index

        // closed space found
        if (bci && eci) {
          closed.push({
            id: closed.length,
            begin: dateFromDateAndTimeIndex(dateIndex, timeIndexFromSlot(bci))!,
            end: dateFromDateAndTimeIndex(dateIndex, timeIndexFromSlot(eci))!,
          });
          bci = null;
          eci = null;
        }
      }

      return closed;
    },
    filterSectionItems(): any {
      return [
        { text: this.$i18n.t('label.all_sections'), value: null },
        { divider: true },
        ...this.$tstore.state.settings.tabs,
      ];
    },
    // timetable
    slots(): TimeSlot[] {
      const margin = 2; // minimum 30 min margin

      // find first and last available slot
      const timeSlots = this.$tstore.getters.dayTimeSlots as TimeSlot[];
      let firstSlot = timeSlots.findIndex((o) => o.isOpen);
      let lastSlot = findLastIndex(timeSlots, (o: TimeSlot) => o.isOpen);

      // fallback to defaults
      if (firstSlot === -1) firstSlot = slotFromMinutes(minutesFromTimeString(DEFAULT_TIMETABLE_START_TIME));
      else firstSlot -= margin;
      if (lastSlot === -1) lastSlot = slotFromMinutes(minutesFromTimeString(DEFAULT_TIMETABLE_END_TIME));
      else lastSlot += margin;

      // check reservations
      this.reservations.forEach((o: Reservation) => {
        if (firstSlot > o.slotBegin - margin) firstSlot = o.slotBegin - margin;
        if (lastSlot < o.slotEnd + margin) lastSlot = o.slotEnd + margin;
      });

      // truncate slot to 60 min
      firstSlot -= firstSlot % 4;
      lastSlot += lastSlot % 4;
      lastSlot += 1; // extra ending 15min slot

      firstSlot = clamp(firstSlot, 0, TIME_SLOTS_COUNT - 1);
      lastSlot = clamp(lastSlot, 0, TIME_SLOTS_COUNT - 1);
      return timeSlots.slice(firstSlot, lastSlot);
    },
    timeSlots(): TimeSlot[] {
      const timeSlots: TimeSlot[] = [];
      this.slots.forEach((o, i) => { if (i % 2 === 0) timeSlots.push(o); });
      return timeSlots;
    },
    timelineStart(): Date | null {
      const { dateIndex } = this.$tstore.state.update;
      const timeIndex = timeIndexFromSlot(this.timeSlots[0].slot);
      const date = dateFromDateAndTimeIndex(dateIndex, timeIndex);
      return date;
    },
    timelineEnd(): Date | null {
      const { dateIndex } = this.$tstore.state.update;
      const timeIndex = timeIndexFromSlot(this.timeSlots[this.timeSlots.length - 1].slot + 1);
      const date = dateFromDateAndTimeIndex(dateIndex, timeIndex);
      return date;
    },
  },
  watch: {
    zoom() {
      this.dayPart = null;
      storage.setTimetableZoom(this.zoom);
    },
  },
  mounted() {
    const zoom = storage.getTimetableZoom();
    if (zoom) this.zoom = zoom;
  },
  methods: {
    ...mapActions(['sendReservation']),
    applySafariFix(): string {
      return isSafari() ? 'height: -webkit-fill-available' : '';
    },
    showRelocateConfirmation(reservations: Reservation[]) {
      this.relocateConfirmationMessage = reservations.length > 1
        ? this.$i18n.tc('message.confirm_move_other_reservations')
        : this.$i18n.tc('message.confirm_move_other_reservation');

      this.relocateConfirmation = true;
      this.reservationsToRelocate = reservations;
    },
    async relocateReservations() {
      console.log('relocateReservations:', this.reservationsToRelocate);
      const ok = await performSaveAction(
        undefined,
        async () => {
          for (let ri = 0; ri < this.reservationsToRelocate.length; ri += 1) {
            const reservation = this.reservationsToRelocate[ri];
            // eslint-disable-next-line no-await-in-loop
            await this.sendReservation({ reservation, relocate: true });
          }
        },
      );
      this.reservationsToRelocate = [];
    },
    tabFilterChanged(tab: Tab | null) {
      this.$tstore.dispatch('applyFilter', { tablesTab: tab });
    },
    filterBtnClicked() {
      this.filterVisibleReq = !this.filterVisibleReq;
      this.$tstore.dispatch('applyFilter', { tablesTab: null });
    },
    clearFilter() {
      this.filterVisibleReq = false;
      this.$tstore.dispatch('applyFilter', { tablesTab: null });
    },
    async scrollToNow() {
      if (!this.isToday) {
        await performAction(
          null,
          this.$i18n.tc('error.load_data'),
          async () => this.$tstore.dispatch('changeToDate', new Date()),
        );
      }
      this.gotoDayPart = -1;
    },
    updateGotoDayPart(val: number) {
      this.gotoDayPart = val;
    },
  },
});
