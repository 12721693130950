
import DialogClosable from '@/mixins/dialog-closable';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ConfirmationDialog',
  mixins: [DialogClosable],
  props: {
    title: { type: String as PropType<string>, required: true },
    message: { type: String as PropType<string>, required: true },
    confirmTitle: { type: String as PropType<string>, required: true },
    confirmColor: { type: String as PropType<string>, required: false, default: 'success' },
    cancelTitle: { type: String as PropType<string>, required: true },
    hideCancel: { type: Boolean, required: false, default: false },
    disableEsc: { type: Boolean, required: false, default: false },
  },
  data: () => ({ }),
  methods: {
    onClick(ret: number) {
      switch (ret) {
        case 0:
          this.$emit('confirm');
          break;
        case 1:
          this.$emit('cancel');
          break;
        default:
          break;
      }
      this.$emit('close', true);
    },
  },
});

export interface ConfirmationDialogData {
  // visible: boolean,
  title: string,
  message: string,
  confirmTitle: string,
  cancelTitle: string,
  confirm: () => void,
  // cancel: () => void,
  // close: () => void,
}

