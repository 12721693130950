
import Vue, { PropType } from 'vue';
import DayNoteBar from '@/components/views/main/DayNoteBar.vue';
import DayNoteEditDialog from '@/components/views/main/DayNoteEditDialog.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import DayNote from '@/model/DayNote';
import { performSaveAction, performAction } from '@/services/vue-utils';
import { cloneModel } from '@/model/model-utils';
import Print from '@/components/views/main/mixins/print';
import Reservation from '@/model/Reservation';

export default Vue.extend({
  name: 'MainToolbar',
  components: {
    DayNoteBar,
    DayNoteEditDialog,
    ConfirmationDialog,
  },
  mixins: [Print],
  props: {
    printVisible: { type: Boolean as PropType<boolean>, required: false, default: true },
    daynoteVisible: { type: Boolean as PropType<boolean>, required: false, default: true },
    reservations: { type: Array as PropType<Array<Reservation>>, required: false, default: () => [] },
  },
  data() {
    return {
      dayNoteDialog: false,
      deleteDayNoteConfirmation: false,
      selectedDayNote: new DayNote(),
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    printReservations(): Reservation[] {
      return this.reservations;
    },
  },
  methods: {
    removeDayNote(dayNote: DayNote) {
      this.selectedDayNote = cloneModel(dayNote);
      delete this.selectedDayNote.text;
      this.deleteDayNoteConfirmation = true;
    },
    editDayNote(dayNote: DayNote) {
      this.selectedDayNote = cloneModel(dayNote);
      this.dayNoteDialog = true;
    },
    addDayNote() {
      this.selectedDayNote = new DayNote();
      this.selectedDayNote.date = this.$tstore.state.update.date;
      this.dayNoteDialog = true;
    },
    closeDayNoteDialog() {
      this.dayNoteDialog = false;
    },
    async saveSelectedDayNote(dayNote: DayNote) {
      const ok = await performSaveAction(
        undefined,
        async () => this.$tstore.dispatch('sendDayNote', { entity: dayNote }),
      );

      if (ok) this.closeDayNoteDialog();
    },
    async removeSelectedDayNote(dayNote: DayNote) {
      if (!this.selectedDayNote) return;

      const ok = await performAction(
        this.$i18n.tc('message.success_daynote_deleted'),
        this.$i18n.tc('error.deleting_day_note'),
        async () => this.$tstore.dispatch('sendDayNote', { entity: this.selectedDayNote }),
      );
    },
  },
});

