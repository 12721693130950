/* eslint-disable max-len */
/* eslint-disable vue/max-len */

import * as pbm from '@/grpc-pb/giftcard_transaction_pb';
import * as pbs from '@/grpc-pb/giftcards_service_pb';
import { DateRange, DateRangePeriod, dateRangeFromPeriod } from '@/services/date-range';

export interface GiftcardTransactionMapping {
}

export enum GiftcardTransactionType {
  None = 'none',
  Sale = 'sale',
  Fee = 'fee',
  Redeem = 'redeem',
  Expiration = 'expiration',
}

export default class GiftcardTransaction {
  type: GiftcardTransactionType = GiftcardTransactionType.None;

  amount?: number;

  currency?: string;

  date?: Date;

  giftcardId?: number;

  giftcardCode?: string;

  static fromGrpcResponse(r: pbs.GiftcardsListTransactionsResponse, m?: GiftcardTransactionMapping): GiftcardTransaction {
    const o = r.getTransaction();
    return GiftcardTransaction.fromGrpcModel(o, m);
  }

  static fromGrpcModel(o?: pbm.GiftcardTransaction, m?: GiftcardTransactionMapping): GiftcardTransaction {
    const p = new GiftcardTransaction();

    switch (o?.getType() ?? 0) {
      case pbm.GiftcardTransaction.GiftcardTransactionType.NONE: p.type = GiftcardTransactionType.None; break;
      case pbm.GiftcardTransaction.GiftcardTransactionType.SALE: p.type = GiftcardTransactionType.Sale; break;
      case pbm.GiftcardTransaction.GiftcardTransactionType.FEE: p.type = GiftcardTransactionType.Fee; break;
      case pbm.GiftcardTransaction.GiftcardTransactionType.REDEEM: p.type = GiftcardTransactionType.Redeem; break;
      case pbm.GiftcardTransaction.GiftcardTransactionType.EXPIRATION: p.type = GiftcardTransactionType.Expiration; break;
      default: break;
    }

    p.amount = o?.getAmount();
    p.currency = o?.getCurrency();
    p.date = o?.getDate() ? new Date(o.getDate() * 1000) : undefined;
    p.giftcardId = o?.getGiftcardId();
    p.giftcardCode = o?.getGiftcardCode();

    return p;
  }
}
export interface GiftcardTransactionListParams {
  beginDateIndex?: number,
  endDateIndex?: number,
  types?: GiftcardTransactionType[],
  codes?: string[],
  code?: string,
}

export interface GiftcardTransactionFilter {
  dateRange: DateRange;

  types: GiftcardTransactionType[];

  codes: string[];

  code: string;
}

export function defaultGiftcardTransactionFilter(): GiftcardTransactionFilter {
  return {
    dateRange: dateRangeFromPeriod(DateRangePeriod.Last90Days),
    types: [],
    codes: [],
    code: '',
  };
}

export function areGiftcardTransactionFiltersSame(o1: GiftcardTransactionFilter, o2: GiftcardTransactionFilter): boolean {
  if (o1.dateRange.beginDate.getTime() !== o2.dateRange.beginDate.getTime()) return false;
  if (o1.dateRange.endDate.getTime() !== o2.dateRange.endDate.getTime()) return false;
  if (o1.code !== o2.code) return false;
  if (o1.types.join(',') !== o2.types.join(',')) return false;
  if (o1.codes.join(',') !== o2.codes.join(',')) return false;
  return true;
}
