// source: giftcard.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var giftcard_transaction_pb = require('./giftcard_transaction_pb.js');
goog.object.extend(proto, giftcard_transaction_pb);
var contact_pb = require('./contact_pb.js');
goog.object.extend(proto, contact_pb);
goog.exportSymbol('proto.grpc.api.v1.Giftcard', null, global);
goog.exportSymbol('proto.grpc.api.v1.GiftcardRecipient', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardRecipient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardRecipient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardRecipient.displayName = 'proto.grpc.api.v1.GiftcardRecipient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.Giftcard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.Giftcard.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.Giftcard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.Giftcard.displayName = 'proto.grpc.api.v1.Giftcard';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardRecipient.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardRecipient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardRecipient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardRecipient.toObject = function(includeInstance, msg) {
  var f, obj = {
    giftcardMessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dtDelivery: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardRecipient}
 */
proto.grpc.api.v1.GiftcardRecipient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardRecipient;
  return proto.grpc.api.v1.GiftcardRecipient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardRecipient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardRecipient}
 */
proto.grpc.api.v1.GiftcardRecipient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGiftcardMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtDelivery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardRecipient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardRecipient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardRecipient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardRecipient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGiftcardMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDtDelivery();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string giftcard_message = 1;
 * @return {string}
 */
proto.grpc.api.v1.GiftcardRecipient.prototype.getGiftcardMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.GiftcardRecipient} returns this
 */
proto.grpc.api.v1.GiftcardRecipient.prototype.setGiftcardMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.grpc.api.v1.GiftcardRecipient.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.GiftcardRecipient} returns this
 */
proto.grpc.api.v1.GiftcardRecipient.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.grpc.api.v1.GiftcardRecipient.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.GiftcardRecipient} returns this
 */
proto.grpc.api.v1.GiftcardRecipient.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 dt_delivery = 4;
 * @return {number}
 */
proto.grpc.api.v1.GiftcardRecipient.prototype.getDtDelivery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.GiftcardRecipient} returns this
 */
proto.grpc.api.v1.GiftcardRecipient.prototype.setDtDelivery = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.Giftcard.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.Giftcard.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.Giftcard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.Giftcard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.Giftcard.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    backgroundImageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    serviceId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    locale: jspb.Message.getFieldWithDefault(msg, 6, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 7, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    code: jspb.Message.getFieldWithDefault(msg, 10, ""),
    color: jspb.Message.getFieldWithDefault(msg, 11, ""),
    dtCreate: jspb.Message.getFieldWithDefault(msg, 12, 0),
    dtUpdate: jspb.Message.getFieldWithDefault(msg, 13, 0),
    dtExpiry: jspb.Message.getFieldWithDefault(msg, 14, 0),
    price: jspb.Message.getFieldWithDefault(msg, 15, 0),
    showLogo: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    footerText: jspb.Message.getFieldWithDefault(msg, 17, ""),
    recipient: (f = msg.getRecipient()) && proto.grpc.api.v1.GiftcardRecipient.toObject(includeInstance, f),
    contact: (f = msg.getContact()) && contact_pb.Contact.toObject(includeInstance, f),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    giftcard_transaction_pb.GiftcardTransaction.toObject, includeInstance),
    isExpired: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    dtExpiryMil: jspb.Message.getFieldWithDefault(msg, 22, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.Giftcard}
 */
proto.grpc.api.v1.Giftcard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.Giftcard;
  return proto.grpc.api.v1.Giftcard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.Giftcard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.Giftcard}
 */
proto.grpc.api.v1.Giftcard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundImageUrl(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServiceId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBalance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtCreate(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtUpdate(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtExpiry(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowLogo(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFooterText(value);
      break;
    case 18:
      var value = new proto.grpc.api.v1.GiftcardRecipient;
      reader.readMessage(value,proto.grpc.api.v1.GiftcardRecipient.deserializeBinaryFromReader);
      msg.setRecipient(value);
      break;
    case 19:
      var value = new contact_pb.Contact;
      reader.readMessage(value,contact_pb.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    case 20:
      var value = new giftcard_transaction_pb.GiftcardTransaction;
      reader.readMessage(value,giftcard_transaction_pb.GiftcardTransaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpired(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDtExpiryMil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.Giftcard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.Giftcard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.Giftcard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.Giftcard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBackgroundImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getServiceId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDtCreate();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getDtUpdate();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getDtExpiry();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getShowLogo();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getFooterText();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getRecipient();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.grpc.api.v1.GiftcardRecipient.serializeBinaryToWriter
    );
  }
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      contact_pb.Contact.serializeBinaryToWriter
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      giftcard_transaction_pb.GiftcardTransaction.serializeBinaryToWriter
    );
  }
  f = message.getIsExpired();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getDtExpiryMil();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.grpc.api.v1.Giftcard.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.grpc.api.v1.Giftcard.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.grpc.api.v1.Giftcard.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string background_image_url = 4;
 * @return {string}
 */
proto.grpc.api.v1.Giftcard.prototype.getBackgroundImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setBackgroundImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 service_id = 5;
 * @return {number}
 */
proto.grpc.api.v1.Giftcard.prototype.getServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string locale = 6;
 * @return {string}
 */
proto.grpc.api.v1.Giftcard.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 balance = 7;
 * @return {number}
 */
proto.grpc.api.v1.Giftcard.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string currency = 8;
 * @return {string}
 */
proto.grpc.api.v1.Giftcard.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.grpc.api.v1.Giftcard.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string code = 10;
 * @return {string}
 */
proto.grpc.api.v1.Giftcard.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string color = 11;
 * @return {string}
 */
proto.grpc.api.v1.Giftcard.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 dt_create = 12;
 * @return {number}
 */
proto.grpc.api.v1.Giftcard.prototype.getDtCreate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setDtCreate = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 dt_update = 13;
 * @return {number}
 */
proto.grpc.api.v1.Giftcard.prototype.getDtUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setDtUpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 dt_expiry = 14;
 * @return {number}
 */
proto.grpc.api.v1.Giftcard.prototype.getDtExpiry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setDtExpiry = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 price = 15;
 * @return {number}
 */
proto.grpc.api.v1.Giftcard.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional bool show_logo = 16;
 * @return {boolean}
 */
proto.grpc.api.v1.Giftcard.prototype.getShowLogo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setShowLogo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string footer_text = 17;
 * @return {string}
 */
proto.grpc.api.v1.Giftcard.prototype.getFooterText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setFooterText = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional GiftcardRecipient recipient = 18;
 * @return {?proto.grpc.api.v1.GiftcardRecipient}
 */
proto.grpc.api.v1.Giftcard.prototype.getRecipient = function() {
  return /** @type{?proto.grpc.api.v1.GiftcardRecipient} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.GiftcardRecipient, 18));
};


/**
 * @param {?proto.grpc.api.v1.GiftcardRecipient|undefined} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
*/
proto.grpc.api.v1.Giftcard.prototype.setRecipient = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.clearRecipient = function() {
  return this.setRecipient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.Giftcard.prototype.hasRecipient = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Contact contact = 19;
 * @return {?proto.grpc.api.v1.Contact}
 */
proto.grpc.api.v1.Giftcard.prototype.getContact = function() {
  return /** @type{?proto.grpc.api.v1.Contact} */ (
    jspb.Message.getWrapperField(this, contact_pb.Contact, 19));
};


/**
 * @param {?proto.grpc.api.v1.Contact|undefined} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
*/
proto.grpc.api.v1.Giftcard.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.Giftcard.prototype.hasContact = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated GiftcardTransaction transactions = 20;
 * @return {!Array<!proto.grpc.api.v1.GiftcardTransaction>}
 */
proto.grpc.api.v1.Giftcard.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.GiftcardTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, giftcard_transaction_pb.GiftcardTransaction, 20));
};


/**
 * @param {!Array<!proto.grpc.api.v1.GiftcardTransaction>} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
*/
proto.grpc.api.v1.Giftcard.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.grpc.api.v1.GiftcardTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GiftcardTransaction}
 */
proto.grpc.api.v1.Giftcard.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.grpc.api.v1.GiftcardTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional bool is_expired = 21;
 * @return {boolean}
 */
proto.grpc.api.v1.Giftcard.prototype.getIsExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setIsExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional int32 dt_expiry_mil = 22;
 * @return {number}
 */
proto.grpc.api.v1.Giftcard.prototype.getDtExpiryMil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Giftcard} returns this
 */
proto.grpc.api.v1.Giftcard.prototype.setDtExpiryMil = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


goog.object.extend(exports, proto.grpc.api.v1);
