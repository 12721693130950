import { IDTOMessageRule } from '@/api/api-messenger';
import MessageTemplate from './MessageTemplate';
import Campaign from './Campaign';
import Tab from './Tab';
import Service from './Service';
import MessageType from './MessageType';
import MessageEventType from './MessageEventType';
import { toModelEntities, cloneModel } from './model-utils';
import MessageHandlebar from './MessageHandlebar';

export interface MessageRuleMapping {
  eventTypesById: Map<number, MessageEventType>;
  campaignsById: Map <number, Campaign>;
  handlebarsById: Map<number, MessageHandlebar>,
  messageTypesById: Map<number, MessageType>,
  servicesById: Map<number, Service>,
  tabsById: Map<number, Tab>,
}

export default class MessageRule {
  id: number;

  sendBcc: boolean;

  isDefault: boolean;

  isInactive: boolean;

  isDeleted: boolean;

  sendAtSpecificTime?: string;

  sendDaysInAdvance?: number;

  sendDaysAfter?: number;

  templates: MessageTemplate[];

  campaign?: Campaign | null = null;

  service?: Service | null = null;

  tab?: Tab | null = null;

  eventType?: MessageEventType;

  messageType?: MessageType;

  constructor(o?: IDTOMessageRule, m?: MessageRuleMapping) {
    this.id = o?.messageRuleId ?? 0;
    this.sendBcc = o?.sendBcc ?? false;
    this.isDefault = o?.isDefault ?? false;
    this.isInactive = o?.isInactive ?? o?.isDeleted ?? false;
    this.isDeleted = o?.isDeleted ?? false;
    this.sendAtSpecificTime = o?.sendAtSpecificTime || '12:00';
    this.sendDaysInAdvance = o?.sendDaysInAdvance;
    this.sendDaysAfter = o?.sendDaysAfter;

    this.campaign = m?.campaignsById.get(o?.campaignId ?? NaN) ?? null;
    this.service = m?.servicesById.get(o?.serviceId ?? NaN) ?? null;
    this.eventType = m?.eventTypesById.get(o?.eventTypeId ?? NaN);
    this.messageType = m?.messageTypesById.get(o?.messageTypeId ?? NaN);
    this.tab = m?.tabsById.get(o?.tabId ?? NaN) ?? null;

    this.templates = toModelEntities(MessageTemplate, o?.messageTemplates, { stringOrderField: 'locale' });
  }

  toDto(): IDTOMessageRule {
    const dto: IDTOMessageRule = {
      messageRuleId: this.id > 0 ? this.id : 0,
      campaignId: this.campaign?.id,
      serviceId: this.service?.id,
      tabId: this.tab?.id,
      eventTypeId: this.eventType?.id,
      messageTypeId: this.messageType?.id,
      sendBcc: this.sendBcc,
      isDefault: this.isDefault,
      isInactive: this.isInactive,
      messageTemplates: this.templates.map((o) => o.toDto()),
      sendAtSpecificTime: this.sendAtSpecificTime,
      sendDaysInAdvance: this.sendDaysInAdvance,
      sendDaysAfter: this.sendDaysAfter,
      isDeleted: this.isDeleted,
    };
    return dto;
  }

  clone(): MessageRule {
    const copy = new MessageRule();
    Object.assign(copy, this);
    copy.templates = this.templates.map((o) => cloneModel(o));
    return copy;
  }
}
