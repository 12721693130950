
import Vue from 'vue';
import SettingsForm from '@/components/layouts/SettingsForm.vue';
import UnsavedGuard from '@/mixins/unsaved-guard';
import SettingsBreadcrumbs from '@/mixins/settings-breadcrumbs';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import MessageCenterTemplateDialog from '@/components/views/settings/MessageCenterTemplateDialog.vue';
import { strcmp } from '@/services/common';
import { performAction } from '@/services/vue-utils';
import toast from '@/plugins/toast-plugin/toast';
import MessageRule from '@/model/MessageRule';
import { cloneModel, isModelEqualToModel, uniqueMinId } from '@/model/model-utils';
import Campaign from '@/model/Campaign';
import Service from '@/model/Service';
import Tab from '@/model/Tab';
import MessageEventType from '@/model/MessageEventType';
import MessageTemplate from '@/model/MessageTemplate';
import Locale from '@/model/Locale';
import { loadStoreModule, unloadStoreModule } from '@/services/module-loader';
import MessageCenterStore from '@/store/message-center-store';
import MessageType, { MessageTypeChannel } from '@/model/MessageType';

export default Vue.extend({
  name: 'MessageCenterRuleEditor',
  components: { ConfirmationDialog, SettingsForm, MessageCenterTemplateDialog },
  mixins: [UnsavedGuard, SettingsBreadcrumbs],

  data() {
    return {
      deleteConfirmation: false,
      r: new MessageRule(),
      oldr: new MessageRule(),
      template: new MessageTemplate(),
      breadcrumbs: [] as any,
      days: [...new Array(11)].map((e, i) => (i)),
      headers: [
        {
          text: this.$i18n.t('label.custom_templates'),
          value: 'language',
          align: 'start',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      templateEditDialog: false,
    };
  },
  computed: {
    isChanged(): boolean {
      return !isModelEqualToModel(this.r, this.oldr);
    },
    isNew(): boolean {
      return this.r.id === 0;
    },
    isDefault(): boolean {
      return this.r.isDefault === true;
    },
    messageTypes(): MessageType[] {
      return this.$tstore.state.messageCenter.messageTypes;
    },
    campaigns(): ({text: string, value: null} | { divider?: boolean} | Campaign)[] {
      return [
        { text: this.$i18n.tc('label.all_campaigns'), value: null },
        { divider: true },
        ...this.$tstore.state.messageCenter.campaigns ?? [],
      ];
    },
    services(): ({text: string, value: null} | { divider?: boolean} | Service)[] {
      return [
        { text: this.$i18n.tc('label.all_services'), value: null },
        { divider: true },
        ...this.$tstore.state.settings.services ?? [],
      ];
    },
    sections(): ({text: string, value: null} | { divider?: boolean} | Tab)[] {
      return [
        { text: this.$i18n.tc('label.all_sections'), value: null },
        { divider: true },
        ...this.$tstore.state.settings.tabs ?? [],
      ];
    },
    eventTypes(): MessageEventType[] {
      return this.$tstore.state.messageCenter.eventTypes ?? [];
    },
    templates(): {id: number, language: string}[] {
      const localesByLocale = this.$tstore.getters.localesByLocale as Map<string, Locale>;
      const customTemplates = this.r.templates.filter((o) => !o.isDefault && !o.isDeleted);
      const languages = customTemplates.map((o) => {
        const locale = o.locale ?? '';
        const language = localesByLocale.get(locale)?.language ?? locale;
        return { id: o.id, language };
      });
      const sortedLanguages = languages.sort(
        (o1: {language: string }, o2: {language: string }) => strcmp(o1.language, o2.language),
      );
      return sortedLanguages;
    },
    sendBccAllowed(): boolean {
      return this.r.messageType?.channel === MessageTypeChannel.EMAIL || false;
    },
  },
  created() {
    loadStoreModule(this, 'messageCenter', MessageCenterStore, async () => { this.load(); });
  },
  beforeDestroy() {
    unloadStoreModule(this, 'messageCenter', async () => { this.$tstore.dispatch('resetMessageCenter'); });
  },
  mounted() {
    this.reset();
    this.breadcrumbs[this.breadcrumbs.length - 1].text = this.r.eventType;
  },
  methods: {
    async load() {
      if (this.$tstore.getters.isMessageCenterLoaded) return;
      const ok = await performAction(
        null,
        this.$i18n.tc('error.load_message_center'),
        async () => this.$tstore.dispatch('loadMessageCenter'),
      );
      if (ok) this.reset();
    },
    allowExtraTimeText() {
      return this.r.eventType!.isTimed ?? false
        ? this.$tc('message.allow_change_24h')
        : this.$tc('message.allow_change_2_5min');
    },
    async save() {
      const ok = await performAction(
        `${this.$tc('message.success_changes_saved')} ${this.allowExtraTimeText()}`,
        this.$i18n.tc('error.saving_changes'),
        async () => this.$tstore.dispatch('sendMessageRule', { entity: this.r }),
        true,
      );

      if (ok) {
        this.reset();
        this.$router.back();
      }
    },
    async remove() {
      const ok = await performAction(
        `${this.$tc('message.success_message_rule_deleted')}. ${this.allowExtraTimeText()}`,
        this.$tc('error.deleting_message_rule'),
        async () => this.$tstore.dispatch('sendMessageRule', { entity: this.r, delete: true }),
        true,
      );

      if (ok) {
        this.reset();
        this.$router.back();
      }
    },
    async reset() {
      this.templateEditDialog = false;

      this.breadcrumbs[this.breadcrumbs.length - 1].text = this.isNew
        ? this.$i18n.t('title.new_message_rule')
        : this.$i18n.t('title.message_rule');

      const id = Number(this.$route.params.id) || 0;
      const rules = (id < 0 ? this.$tstore.getters.builtinRules : this.$tstore.state.messageCenter.rules) ?? [];
      const r = rules.find((o: MessageRule) => o.id === id) ?? new MessageRule();
      this.r = cloneModel(r);
      this.oldr = r;
    },
    editTemplate(item: {id: number}) {
      this.templateEditDialog = true;
      this.template = this.r.templates.find((o) => o.id === item.id) ?? new MessageTemplate();
    },
    addTemplate() {
      this.templateEditDialog = true;
      this.template = new MessageTemplate();
      this.template.id = uniqueMinId(this.r.templates);
    },
    removeTemplate(id: number) {
      const index = this.r.templates.findIndex((o) => o.id === id);
      if (index < 0) return;

      if (this.oldr.templates.some((o) => o.id === id)) {
        this.r.templates[index].isDeleted = true;
      } else {
        this.r.templates.splice(index, 1);
      }
    },
    updateTemplate(t: MessageTemplate) {
      const isNewTemplate = this.template.id <= 0;
      if (isNewTemplate) {
        this.r.templates.push(t);
        this.r.templates.sort((o1, o2) => strcmp(o1.locale!, o2.locale!));
      } else {
        this.template.body = t.body;
        this.template.subject = t.subject;
      }
      this.templateEditDialog = false;
    },
    updateValue(value: string) {
      if (this.r.campaign?.value === null) this.r.campaign = null;
      if (this.r.service?.value === null) this.r.service = null;
      if (this.r.tab?.value === null) this.r.tab = null;
    },
  },
});
