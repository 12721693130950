/* eslint-disable no-use-before-define */
import { IAccount } from '@/api/api-update';
import { dateFromUTCDateTimeSecondsString } from '@/services/time-utils';
import { boolFromValue, numberFromValue } from '@/api/api-utils';

export interface IDTOAccount extends IAccount {
  isDeleted?: string | boolean, // dummy field to satisfy IApi
}

export enum BillingInfoSubscriptionStatus {
  None = 'none',
  VIP = 'vip',
  TrialActive = 'trialActive',
  SubActive = 'subActive',
  TrialWillExpire = 'trialWillExpire',
  TrialExpired = 'trialExpired',
  SubWillExpire = 'subWillExpire',
  SubWillExpirePayment = 'subWillExpirePayment',
  SubExpired = 'subExpired',
  SubExpiredPayment = 'subExpiredPayment',
}

export enum BillingInfoSMSStatus {
  None = 'none',
  VIP = 'vip',
  Active = 'active',
  LowBalance = 'lowBalance',
  ZeroBalance = 'zeroBalance',
  PaymentError = 'paymentError',
}

export interface BillingInfo {
  subStatus: BillingInfoSubscriptionStatus;
  data?: string;
  trialExpDate?: Date;
  subExpDate?: Date;
  smsStatus: BillingInfoSMSStatus;
  smsBalance: number;
}

export default class Account {
  id: number;

  accountName?: string;

  streetAddress?: string;

  postalCode?: string;

  city?: string;

  countryState?: string;

  // countryName?: string;

  countryCode?: string;

  phone?: string;

  email?: string;

  website?: string;

  locale?: string;

  timeZoneId?: number;

  // firstDayOfWeek?: number;

  // is12HourClock?: boolean;

  // timeZone?: string;

  employeeNameIsNeeded?: boolean;

  userId?: number;

  username?: string;

  isRegistered?: boolean;

  isPremium?: boolean;

  subscriptionExpirationDate?: Date;

  upgradeIsNeeded?: boolean;

  color?: string;

  widgetDocumentationURL?: string;

  defaultAccessKey?: string;

  logoLink: string;

  serviceManagerAccessKey?: string;

  proSubscriptionExpirationDate?: Date;

  proTrialExpirationDate?: Date;

  proStatus?: BillingInfoSubscriptionStatus;

  smsStatus?: BillingInfoSMSStatus;

  smsBalance?: number;

  proData?: string;

  constructor(o?: IAccount) {
    this.id = o?.accountId ?? 0;
    this.accountName = o?.accountName;
    this.streetAddress = o?.streetAddress;
    this.postalCode = o?.postalCode;
    this.city = o?.city;
    this.countryState = o?.countryState;
    //   this.countryName = o?.country;
    this.countryCode = o?.countryCode;
    this.phone = o?.phone;
    this.email = o?.email;
    this.website = o?.website;
    this.locale = o?.locale;
    this.timeZoneId = numberFromValue(o?.timeZoneId);
    //   this.timeZone = o?.timeZone;
    // this.firstDayOfWeek = o?.firstDayOfWeek;
    // this.is12HourClock = boolFromValue(o?.is12HourClock);
    this.employeeNameIsNeeded = boolFromValue(o?.employeeNameIsNeeded);
    this.userId = numberFromValue(o?.userId, 0);
    this.username = o?.username ?? '';
    this.isRegistered = boolFromValue(o?.isRegistered);
    this.isPremium = boolFromValue(o?.isPremium);
    this.upgradeIsNeeded = boolFromValue(o?.upgradeIsNeeded);
    this.color = o?.color?.toUpperCase() ?? '#3355FF';

    this.widgetDocumentationURL = o?.widgetDocumentationURL;
    this.defaultAccessKey = o?.defaultAccessKey;
    this.serviceManagerAccessKey = o?.serviceManagerAccessKey;
    this.logoLink = o?.logoLink ?? ''; // empty string - no image

    this.proSubscriptionExpirationDate = dateFromUTCDateTimeSecondsString(
      o?.bProSubscriptionExpirationDate,
    ) ?? undefined;
    this.proTrialExpirationDate = dateFromUTCDateTimeSecondsString(o?.bProTrialExpirationDate) ?? undefined;
    this.proStatus = o?.bSubscriptionInfo as BillingInfoSubscriptionStatus;
    this.smsStatus = BillingInfoSMSStatus.None as BillingInfoSMSStatus; // TODO: finish api
    this.smsBalance = 10 ?? 0; // TODO: finish api
  }

  toDto(): IDTOAccount {
    const dto: IDTOAccount = {
      ...this,
      accountId: this.id,
      id: undefined,
      subscriptionExpirationDate: undefined,
      proSubscriptionExpirationDate: undefined,
      proTrialExpirationDate: undefined,
      proStatus: undefined,
      proData: undefined,
      smsStatus: undefined,
    };
    return dto;
  }

  static fromData(data: Pick<Account, keyof Account>): Account {
    const copy = new Account();
    Object.assign(copy, data);

    copy.subscriptionExpirationDate = fixDate(copy.subscriptionExpirationDate);
    copy.proSubscriptionExpirationDate = fixDate(copy.proSubscriptionExpirationDate);
    copy.proTrialExpirationDate = fixDate(copy.proTrialExpirationDate);

    return copy;
  }
}

function fixDate(date: Date | string | undefined): Date | undefined {
  if (typeof date === 'string') return new Date(date);
  return date;
}
