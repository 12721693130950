
import Vue, { PropType } from 'vue';
import StatusSelectionMenu from '@/components/views/guests/StatusSelectionMenu.vue';
import Reservation, { ReservationStatus, ReservationType } from '@/model/Reservation';
import ReservationChips from '@/components/views/guests/ReservationChips.vue';
import { mapActions } from 'vuex';
import { performSaveAction } from '@/services/vue-utils';
import { cloneModel, toMapById } from '@/model/model-utils';
import HideableBadge from '@/components/util/HideableBadge.vue';

export default Vue.extend({
  name: 'GuestListRow',
  components: { StatusSelectionMenu, ReservationChips, HideableBadge },
  props: {
    reservation: { type: Object as PropType<Reservation>, required: true },
    waitlistListOrder: { type: Number as PropType<number>, required: false, default: 0 },
    overbookedTabItems: { type: Set as PropType<Set<number>|undefined>, required: false, default: undefined },
    compact: { type: Boolean, required: false, default: false },
    searchResult: { type: Boolean, required: false, default: false },
    floorplanResult: { type: Boolean, required: false, default: false },
    unreadReservations: { type: Boolean, required: false, default: false },
  },
  computed: {
    r(): Reservation {
      return this.reservation;
    },
    isWalkin(): boolean {
      return this.r.bookingType === ReservationType.Walkin;
    },
    waitlistBadgeContent(): string | null {
      return this.waitlistListOrder.toString();
    },
    waitlistBadgeVisible(): boolean {
      return this.r.status === ReservationStatus.WaitingList;
    },
    colorStatusButton(): string {
      switch (this.r.status) {
        case ReservationStatus.Planned: return 'grey lighten-1';
        case ReservationStatus.Confirmed: return 'info white--text';
        case ReservationStatus.CheckedIn: return 'orange white--text';
        case ReservationStatus.CheckedOut: return 'grey-50 lighten-2 white--text';
        case ReservationStatus.Seated: return 'green white--text';
        case ReservationStatus.NonConfirmed: return 'purple white--text';
        case ReservationStatus.Cancelled: return 'red white--text';
        case ReservationStatus.Spam: return 'red white--text';
        case ReservationStatus.Deleted: return 'red white--text';
        case ReservationStatus.NoShow: return 'red white--text';
        case ReservationStatus.WaitingList: return 'white--text purple darken-4';
        default: return 'grey lighten-1';
      }
    },
    colorStatusLabel(): string {
      switch (this.r.status) {
        case ReservationStatus.Planned: return 'grey--text text--lighten-1';
        case ReservationStatus.Confirmed: return 'info--text';
        case ReservationStatus.CheckedIn: return 'orange--text';
        case ReservationStatus.CheckedOut: return 'grey-50--text';
        case ReservationStatus.Seated: return 'green--text';
        case ReservationStatus.NonConfirmed: return 'purple--text';
        case ReservationStatus.Cancelled: return 'red--text';
        case ReservationStatus.Spam: return 'red--text';
        case ReservationStatus.Deleted: return 'red--text';
        case ReservationStatus.NoShow: return 'red--text';
        case ReservationStatus.WaitingList: return 'black--text';
        default: return 'grey--text text--lighten-1';
      }
    },
    tablesHtmlText(): string {
      return this.r.tabItems.map((ti) => (this.overbookedTabItems?.has(ti.id)
        ? `<span class="error--text">${ti.no}</span>`
        : `${ti.no}`)).join(', ');
    },
  },
  methods: {
    ...mapActions(['sendReservation', 'setReservationFromContextStatusChange']),
    statusButtonOutlined(s: ReservationStatus) {
      return s === ReservationStatus.Planned;
    },
    async statusChanged(value: string) {
      const r = cloneModel(this.r);
      r.setStatus(value as ReservationStatus);
      const ok = await performSaveAction(
        undefined,
        async () => this.sendReservation({ reservation: r }),
      );
      if (ok) this.setReservationFromContextStatusChange(r);
    },
    async markAsRead() {
      const r = cloneModel(this.r);
      r.isUnread = false;
      await performSaveAction(
        undefined,
        async () => this.sendReservation({ reservation: r }),
      );
    },
  },
});
