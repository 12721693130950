
import MessageRule from '@/model/MessageRule';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'MessageCenterRulesTable',
  components: { },
  mixins: [],
  props: {
    rules: { type: Object as PropType<Array<MessageRule>>, required: true },
    showDetails: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
    };
  },
  computed: {
    headers(): any[] {
      const headers : any[] = [
        {
          text: this.$i18n.t('label.event_type'),
          value: 'eventType',
          align: 'start',
          sortable: false,
        },
      ];

      if (this.showDetails) {
        headers.push(
          {
            text: this.$i18n.t('label.campaign'),
            value: 'campaign.description',
            align: 'center',
            sortable: false,
          },
          {
            text: this.$i18n.t('label.service'),
            value: 'service.title',
            align: 'center',
            sortable: false,
          },
          {
            text: this.$i18n.t('label.section'),
            value: 'tab.name',
            align: 'center',
            sortable: false,
          },
        );
      }

      headers.push(
        {
          text: this.$i18n.t('title.message_type'),
          value: 'messageType.text',
          align: 'center',
          sortable: false,
          width: '120px',
        },
        {
          text: this.$i18n.t('label.active'),
          value: 'isInactive',
          align: 'center',
          sortable: false,
          width: '100px',
        },
      );
      return headers;
    },
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    edit(o: any, prop: any) {
      this.$emit('row-clicked', o);
    },
  },
});
