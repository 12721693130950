/* eslint-disable no-use-before-define */
import { IDTOEventType } from '@/api/api-messenger';
import i18n from '@/plugins/i18n';

export default class MessageEventType {
  id: number;

  type: string;

  name: string;

  constructor(o: IDTOEventType) {
    this.id = o.eventTypeId;
    this.type = o.eventType;
    this.name = o.eventName;
  }

  get value() { return this.id; }

  get text() {
    const key = `code.message_event.${this.type}`;
    const text = i18n.t(key);
    return key !== text ? text : this.name;
  }

  get isTimedAfter() { return TimedAfterEventTypes.includes(this.type); }

  get isTimedBefore() { return TimedBeforeEventTypes.includes(this.type); }

  get isTimed() { return this.isTimedAfter || this.isTimedBefore; }
}

export const BuiltInEventTypes = [
  'etc_insert:planned',
  'etc_update:cancelled',
  'etc_update:planned',
  'tbe_reminder',
  'tbe_thankyou',
];

export const TimedAfterEventTypes = [
  'tbe_thankyou',
];

export const TimedBeforeEventTypes = [
  'tbe_reminder',
];
