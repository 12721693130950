
import Vue from 'vue';
import SettingsBreadcrumbs from '@/mixins/settings-breadcrumbs';
import SettingsForm from '@/components/layouts/SettingsForm.vue';
import PaymentWizard from '@/components/views/settings/billing/PaymentWizard.vue';
import SectionHeader from '@/components/forms/SectionHeader.vue';
import SmsBalanceChart from '@/components/views/settings/SmsBalanceChart.vue';
import { loadStoreModule, unloadStoreModule } from '@/services/module-loader';
import BillingStore from '@/store/billing-store';
import { performAction, performSaveAction } from '@/services/vue-utils';
import {
  Currency,
  Customer,
  InvoiceStatus,
  Price, Product, SMSProduct,
} from '@/api/api-billing';
import { AlertInfo, billingCurrencyText, priceText } from '@/services/billing-utils';
import { ValidationObserver } from 'vee-validate';
import UnsavedGuard from '@/mixins/unsaved-guard';
import CurrencyPicker from '@/components/pickers/CurrencyPicker.vue';

type ListInfo<T> = { text: string, value: T, divider?: boolean }[]

export default Vue.extend({
  name: 'Sms',
  components: {
    SettingsForm, SectionHeader, SmsBalanceChart, PaymentWizard, CurrencyPicker,
  },
  mixins: [UnsavedGuard, SettingsBreadcrumbs],
  data() {
    return {
      breadcrumbs: [],

      // balance: 0,
      sender: '',
      autorenewPriceID: null as string | null,
      balanceNotificationThreshold: 100,
      balanceNotificationEnabled: true,

      selectedPriceID: null as string | null,
      paymentWizardPrices: [] as Price[],
      paymentWizardVisible: false,

      selectedCurrency: Currency.EUR as string,
    };
  },
  computed: {
    senderHint(): string {
      return `${this.sender.length}/11`;
    },
    purchaseButtonTitle(): string {
      let priceID = this.selectedPriceID;
      if (this.isInitialOrder) {
        // initial payment with autorenew price id if enabled
        if (this.autorenewPriceID) priceID = this.autorenewPriceID;
      }

      const priceInfo = this.pricesInfo.find((pi) => pi.value === priceID);
      return `${this.$tc('button.purchase')} ${priceInfo?.text ?? ''}`;
    },
    isBillingSMSPageFull(): boolean {
      return this.$tstore.getters.isBillingSMSPageFull as boolean;
    },
    isBillingAllowed(): boolean {
      return this.$tstore.getters.isBillingAllowed as boolean;
    },
    isInitialOrder(): boolean {
      return this.smsProduct === null || !this.smsProduct.latestInvoiceStatus || !this.customer;
    },
    prices(): Price[] {
      const pricesByCurrency = this.$tstore.getters.billingSMSProductPricesByCurrency as Map<string, Price[]>;
      if (pricesByCurrency.size === 0) return [];

      const prices = pricesByCurrency.get(this.selectedCurrency);
      return prices ?? [];
    },
    pricesInfo(): ListInfo<string> {
      return this.prices.map((p) => ({ text: priceText(p), value: p.ID! }));
    },
    isBillingLoaded(): boolean {
      return this.$tstore.getters.isBillingLoaded === true;
    },
    balance(): number { return this.smsProduct?.balance ?? 0; },
    autorenewEnabled(): boolean {
      console.log('autorenewEnabled: ', this.autorenewPriceID);
      return this.autorenewPriceID !== null;
    },
    isChanged(): boolean {
      if (this.isInitialOrder) return false; // for unsaved guard

      return this.sender.trim() !== (this.smsProduct?.sender ?? '')
        || this.autorenewPriceID !== (this.smsProduct?.autorenewPriceID ?? null)
        || this.balanceNotificationThreshold !== this.smsProduct?.balanceNotificationThreshold
        || this.balanceNotificationEnabled !== (this.smsProduct?.balanceNotificationEnabled ?? false);
    },
    saveButtonVisible(): boolean {
      return this.isBillingLoaded && !this.isInitialOrder;
    },
    saveButtonEnabled(): boolean {
      return this.isBillingLoaded && !this.isInitialOrder && this.isChanged;
    },
    // smsApproved(): boolean {
    //   return this.smsProduct !== null;
    // },
    alertInfo(): AlertInfo | null {
      if (!this.isBillingLoaded) return null;

      if (!this.isBillingAllowed) {
        return ({
          message: this.$tc('message.billing_warning_billing_navigation_notallowed'),
          type: 'warning',
          color: 'orange',
          buttons: [],
        });
      }

      // billing data not loaded -> no alert info yet
      if (!this.isBillingLoaded || this.isInitialOrder || !this.smsProduct) return null;

      // failed payment (todo!)
      if (this.smsProduct.latestInvoiceError) {
        return ({
          message: this.$tc('error.payment_latest_invoice_error'),
          type: 'error',
          color: 'red',
          buttons: [
            {
              text: this.$tc('button.show_billing'),
              color: 'error',
              action: () => { this.$router.push('/settings/billing'); },
            },
          ],
        });
      }

      // missing payment (open invoice) (todo!)
      if (this.smsProduct.latestInvoiceStatus === InvoiceStatus.Open) {
        return ({
          message: this.$tc('error.payment_invoice_not_closed_pay_invoice'),
          type: 'warning',
          color: 'orange',
          buttons: [
            {
              text: this.$tc('button.show_billing'),
              color: 'orange',
              action: () => { this.$router.push('/settings/billing'); },
            },
          ],
        });
      }

      // zero balance
      if (this.smsProduct.balance === 0) {
        return ({
          message: this.$tc('message.sms_credits_depleted_please_renew'),
          type: 'error',
          color: 'red',
          buttons: [],
        });
      }

      // low balance
      if (this.smsProduct.balance && this.smsProduct.balance < 10) {
        return ({
          message: this.$t('message.sms_low_credits_warning', [this.smsProduct!.balance]) as string,
          type: 'warning',
          color: 'orange',
          buttons: [],
        });
      }

      return null;
    },
    smsProduct(): SMSProduct | null {
      if (!this.isBillingLoaded) return null;

      const smsProduct = this.$tstore.getters.billingSMSProduct as SMSProduct | null;
      console.log('smsProduct: ', smsProduct);
      return smsProduct;
    },
    customer(): Customer | null {
      return this.$tstore.getters.billingCustomer;
    },
    currencySelectorEnabled(): boolean {
      return this.customer?.currency === undefined;
    },
    currencyText(): string {
      return billingCurrencyText(this.selectedCurrency);
    },
  },
  created() {
    loadStoreModule(this, 'billing', BillingStore, async () => { this.load(); });
  },
  mounted() {
  },
  async beforeDestroy() {
    unloadStoreModule(this, 'billing', async () => { this.$tstore.dispatch('resetBilling'); });
    await performAction(
      null,
      this.$i18n.tc('error.load_data'),
      async () => this.$tstore.dispatch('update'),
    );
  },
  methods: {
    async load() {
      await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => this.$tstore.dispatch('loadBilling', { force: true }),
      );

      await this.$nextTick();

      const customer = this.$tstore.getters.billingCustomer as Customer | null;
      this.selectedCurrency = customer?.currency ?? this.$tstore.getters.billingGuessedCurrency;

      const { account } = this.$tstore.state.settings;
      this.sender = this.smsProduct?.sender ?? account.accountName ?? '';

      const firstPriceID = this?.prices?.find(Boolean)?.ID ?? null;
      if (this.isInitialOrder) {
        this.autorenewPriceID = firstPriceID;
      } else {
        this.autorenewPriceID = this.smsProduct?.autorenewPriceID ?? null;
        // fix autorenew price id
        if (this.autorenewPriceID && !this.prices.find((p) => p.ID === this.autorenewPriceID)) {
          this.autorenewPriceID = firstPriceID;
        }
      }

      this.selectedPriceID = this.autorenewPriceID ?? firstPriceID;
      this.balanceNotificationThreshold = this.smsProduct?.balanceNotificationThreshold ?? 100;
      this.balanceNotificationEnabled = this.smsProduct?.balanceNotificationEnabled ?? false;
    },
    async startPayment() {
      let priceID = this.selectedPriceID;

      // initial payment with autorenew price id if enabled
      if (this.isInitialOrder && this.autorenewPriceID) priceID = this.autorenewPriceID;

      // save form data before payment
      if (this.isInitialOrder || this.isChanged) {
        const ok = await this.save();
        if (!ok) return;
      }

      // open billing wizard
      this.paymentWizardPrices = this.prices?.filter((p) => p.ID === priceID) ?? [];
      this.paymentWizardVisible = true;
    },
    async save(): Promise<boolean> {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) return false;

      const smsProduct: SMSProduct = {
        sender: this.sender.trim(),
        autorenewPriceID: this.autorenewPriceID ?? undefined,
        balanceNotificationThreshold: this.balanceNotificationThreshold,
        balanceNotificationEnabled: this.balanceNotificationEnabled,
      };

      const action = this.smsProduct ? 'billingUpdateSMSProduct' : 'billingCreateSMSProduct';
      const ok = await performSaveAction(
        this.$refs.observer,
        async () => this.$tstore.dispatch(action, { smsProduct }),
      );

      return ok;
    },
    autorenewChanged(value: boolean) {
      if (!value) {
        this.autorenewPriceID = null;
        this.balanceNotificationThreshold = this.smsProduct?.balanceNotificationThreshold ?? 100;
        this.balanceNotificationEnabled = true;
      } else {
        const firstPriceID = this?.prices?.find(Boolean)?.ID ?? null;
        this.autorenewPriceID = this.smsProduct?.autorenewPriceID ?? null;
        // fix autorenew price id
        if (!this.autorenewPriceID || !this.prices.find((p) => p.ID === this.autorenewPriceID)) {
          this.autorenewPriceID = firstPriceID;
        }
        this.balanceNotificationEnabled = false;
      }
    },
    closePaymentWizard() {
      this.paymentWizardVisible = false;
      this.load();
    },
    setCurrency(curr: string) {
      const api = this.autorenewPriceID ? this.prices.findIndex((p) => p.ID === this.autorenewPriceID) : -1;
      const spi = this.selectedPriceID ? this.prices.findIndex((p) => p.ID === this.selectedPriceID) : -1;
      this.selectedCurrency = curr;
      this.autorenewPriceID = api >= 0 && api < this.prices.length ? this.prices[api].ID! : null;
      this.selectedPriceID = spi >= 0 && spi < this.prices.length ? this.prices[spi].ID! : null;
    },
  },
});
