
import Vue from 'vue';

import MainToolbar from '@/components/views/main/MainToolbar.vue';
import HourlyGuestsCard from '@/components/views/dashboard/HourlyGuestsCard.vue';
import NumericListCard from '@/components/views/dashboard/NumericListCard.vue';
import AvailablePartySizeCard from '@/components/views/dashboard/AvailablePartySizeCard.vue';
import KeyboardControl from '@/mixins/keyboard-control';

import Tab from '@/model/Tab';
import {
  timeIndexFromSlot, dateFromDateAndTimeIndex,
  MORNING_TIME_SLOTS_COUNT, DAY_TIME_SLOTS_COUNT, dateIndexFromDate,
} from '@/services/time-utils';
import { performAction } from '@/services/vue-utils';
import Service from '@/model/Service';
import Label from '@/model/Label';
import Reservation from '@/model/Reservation';
import DashboardAvailability, { DashboardLimitData } from '@/grpc-api/model/dashboard-availability';
import { DashboardAllTabs, DashboardOnlineTabs } from '@/store/store';
import { tryEditReservation } from '@/services/reservation-edit-utils';
import { loadStoreModule, unloadStoreModule } from '@/services/module-loader';
import DashboardStore from '@/store/dashboard-store';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    MainToolbar,
    HourlyGuestsCard,
    NumericListCard,
    AvailablePartySizeCard,
  },
  mixins: [KeyboardControl],

  data() {
    return {};
  },
  computed: {
    showDetails(): boolean {
      return this.$tstore.state.dashboard.showDetails ?? false;
    },
    date(): Date {
      return this.$tstore.state.update.date;
    },
    tab(): Tab {
      return this.$tstore.state.dashboard.tab;
    },
    tabs(): any[] {
      return [
        DashboardOnlineTabs, DashboardAllTabs,
        { divider: true },
        ...this.$tstore.state.settings.tabs,
      ];
    },
    reservations(): Reservation[] {
      return this.$tstore.getters.dashboardReservations;
    },
    // availability data
    dayAvailability(): DashboardAvailability | null {
      return this.$tstore.getters.dashboardDayAvailability;
    },
    dayLimits(): DashboardLimitData[] {
      const data = this.$tstore.getters.dashboardGuestsPerTime as DashboardLimitData[];
      return data;
    },
    availabilityHidden(): boolean {
      return !this.$tstore.getters.isTodayOrFuture || !this.$tstore.getters.dashboardIsOnlineTab;
    },
    availabilityDisabled(): boolean {
      return !this.$tstore.getters.isTodayOrFuture
      || !this.$tstore.getters.isDashboardDayAvailabilityLoaded
      || (this.tab.id > 0 && this.tab.usingWeekdaysAndTimes !== true);
    },
    availabilityTabs(): Tab[] {
      const { tabs } = this.$tstore.state.settings;
      if (this.tab === DashboardAllTabs) return tabs;
      if (this.tab === DashboardOnlineTabs) return tabs.filter((t: Tab) => t.usingWeekdaysAndTimes === true);
      return [this.tab];
    },
    // datasets
    guestsPerTime(): {t: Date, s: number, i: number, sl: number, il:number}[] {
      const data = this.$tstore.getters.dashboardGuestsPerTime as {i: number, s: number, il: number, sl: number}[];
      return data.map((sd, si) => {
        const di = dateIndexFromDate(this.date);
        const ti = timeIndexFromSlot(si);
        const date = dateFromDateAndTimeIndex(di, ti)!;
        return {
          t: date, ...sd,
        };
      }).slice(MORNING_TIME_SLOTS_COUNT, DAY_TIME_SLOTS_COUNT);
    },
    datasetSeated(): [Date, number][] {
      return this.guestsPerTime.map((sd) => ([sd.t, sd.s]));
    },
    datasetIncoming(): [Date, number][] {
      return this.guestsPerTime.map((sd) => ([sd.t, sd.i]));
    },
    datasetSeatedLimit(): [Date, number][] {
      return this.guestsPerTime.map((sd) => ([sd.t, sd.sl]));
    },
    datasetIncomingLimit(): [Date, number|undefined][] | undefined {
      return this.guestsPerTime.map((sd) => ([sd.t, Number.isFinite(sd.il) ? sd.il : undefined]));
    },
    datasetSeatedLimitVisible(): boolean {
      return this.tab === DashboardOnlineTabs || this.tab.usingWeekdaysAndTimes === true;
    },
    datasetIncomingLimitVisible(): boolean {
      return this.tab === DashboardOnlineTabs;
    },
    guestsForSections(): {text: String, guests: number, reservations: number}[] {
      const data = this.$tstore.getters.dashboardGuestsForSections as {s: Tab, g: number, r: number}[];
      return data.flatMap((sd) => (sd.r === 0 && sd.g === 0 ? []
        : { text: sd.s.name, guests: sd.g, reservations: sd.r }));
    },
    lunchDinnerForSections(): {text: String, guests: number, reservations: number}[] {
      const data = this.$tstore.getters.dashboardLunchDinnerForSections as {s: Tab, l: number, d: number}[];
      return data.flatMap((sd) => (sd.l === 0 && sd.d === 0 ? []
        : { text: sd.s.name, guests: sd.l, reservations: sd.d }));
    },
    guestsForServices(): {text: String, guests: number, reservations: number}[] {
      const data = this.$tstore.getters.dashboardGuestsForServices as {s: Service, g: number, r: number}[];
      return data.flatMap((sd) => (sd.r === 0 && sd.g === 0 ? []
        : { text: sd.s.text ?? 'N/A', guests: sd.g, reservations: sd.r }));
    },
    guestsForLabels(): {text: String, guests: number, reservations: number}[] {
      const data = this.$tstore.getters.dashboardGuestsForLabels.gr as {l: Label, g: number, r: number}[];
      return data.flatMap((ld) => (ld.r === 0 && ld.g === 0 ? []
        : { text: ld.l.text ?? 'N/A', guests: ld.g, reservations: ld.r }));
    },
    dayStats(): {
      reservations: number, tables: number, guests: number, lunch: number, dinner: number,
      labeledReservations: number, labeledGuests: number, serviceReservations: number, serviceGuests: number,
    } {
      return this.$tstore.getters.dashboardDayStats;
    },
  },
  watch: {
    date(date: Date) {
      this.setDate(date);
    },
  },
  created() {
    loadStoreModule(this, 'dashboard', DashboardStore, async () => {
      const { date } = this.$tstore.state.update;
      this.setDate(date);
    });
  },
  beforeDestroy() {
    unloadStoreModule(this, 'dashboard', async () => { this.$tstore.dispatch('resetDashboard'); });
  },
  methods: {

    async makeReservation(params: {timeSlot: number, partySize:number}) {
      const res = await tryEditReservation(this.$router, undefined, {
        tab: this.tab.id > 0 ? this.tab : undefined,
        tabItems: undefined,
        timeSlot: params.timeSlot + MORNING_TIME_SLOTS_COUNT,
        partySize: params.partySize,
      });
    },
    async setTab(tab: Tab) {
      await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => this.$tstore.dispatch('setDashboardTab', tab),
      );
    },
    async setDate(date: Date) {
      await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => this.$tstore.dispatch('setDashboardDate', date),
      );
    },
    tabSelected(tab: Tab) {
      this.setTab(tab);
    },
    setShowDetails(value: boolean) {
      this.$tstore.dispatch('setDashboardShowDetails', value);
    },
  },
});

