/* eslint-disable class-methods-use-this */
import Country from '@/model/Country';
import i18n from '@/plugins/i18n';
import Vue from 'vue';
import GstplnApp, { GstplnAppCategory } from '../app';

export default class CampaignFilterApp extends GstplnApp {
  constructor(vue: Vue) {
    super(vue, 'app.campaign-filter', {
      categories: [GstplnAppCategory.Extensions],
      hasMenuItem: true,
      routeConfig:
       [{ route: '', f: () => import('@/components/apps/campaign-filter/CampaignFilter.vue') }],
    });
  }
}
