import { IDTOMessageType } from '@/api/api-messenger';
import i18n from '@/plugins/i18n';

export enum MessageTypeChannel {
  SMS = 'sms',
  EMAIL = 'email',
  OTHER = 'other',
}
export default class MessageType {
  id: number;

  messageType: string;

  constructor(o: IDTOMessageType) {
    this.id = o.messageTypeId;
    this.messageType = o.messageType;
  }

  get channel(): MessageTypeChannel {
    switch (this.messageType) {
      case 'SendGrid': return MessageTypeChannel.EMAIL;
      case 'MessageBird': return MessageTypeChannel.SMS;
      default: return MessageTypeChannel.OTHER;
    }
  }

  get text() {
    const key = `code.message_type.${this.channel}`;
    const text = i18n.t(key);
    return key !== text ? text : this.channel;
  }
}
