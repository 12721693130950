/* eslint-disable max-len */
/* eslint-disable vue/max-len */

import * as pbm from '@/grpc-pb/feedback_settings_pb';
import * as pbs from '@/grpc-pb/feedback_service_pb';

export interface FeedbackSettingsMapping {
}

export default class FeedbackSettings {
  id: number = 0;

  allowed: boolean = false;

  enabled: boolean = false;

  useBreakdown?: boolean;

  noRuleError: boolean = false;

  static fromGrpcResponse(r: pbs.FeedbackSettingsResponse, m?: FeedbackSettingsMapping): FeedbackSettings {
    const o = r.getSettings();
    return FeedbackSettings.fromGrpcModel(o, m);
  }

  static fromGrpcModel(o?: pbm.FeedbackSettings, m?: FeedbackSettingsMapping): FeedbackSettings {
    const p = new FeedbackSettings();

    p.allowed = o?.getAllowed() ?? false;
    p.enabled = o?.getEnabled() ?? false;
    p.noRuleError = o?.getNoRuleError() ?? false;

    if (o?.hasUseBreakdown()) p.useBreakdown = o?.getUseBreakdown();

    return p;
  }

  toGrpcModel(): pbm.FeedbackSettings {
    const o = new pbm.FeedbackSettings();

    o.setAllowed(this.allowed);
    o.setEnabled(this.enabled);
    if (this.useBreakdown) o.setUseBreakdown(this.useBreakdown);

    return o;
  }
}
