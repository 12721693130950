/* eslint-disable max-len */
/* eslint-disable vue/max-len */

import * as pbm from '@/grpc-pb/feedback_pb';
import * as pbs from '@/grpc-pb/feedback_service_pb';
import { dateFromDateString, dateFromDateTimeString, unixFromDate } from '@/services/time-utils';
import { strcmp } from '@/services/common';
import { ReservationMapping } from '@/model/Reservation';
import Reservation from './reservation';

// eslint-disable-next-line no-use-before-define
export type FeedbackSortFncType = (o1: Feedback, o2: Feedback) => number;

export enum FeedbackSortType {
  Name = 'name',
  Food = 'foodRating',
  Service = 'serviceRating',
  Ambience = 'ambienceRating',
  Overall = 'overallRating',
  Created = 'created',
  Comments = 'comments',
  Replied = 'replied',
  Visited = 'visited',
}

export interface FeedbackFilter {
  name: string;
  comments: string;

  foodRating: Number[];
  serviceRating: Number[];
  ambienceRating: Number[];
  overallRating: Number[];
}

export interface FeedbackMapping extends ReservationMapping{
}

export default class Feedback {
  id: number = 0;

  reservationId?: number;

  foodRating?: number;

  serviceRating?: number;

  ambienceRating?: number;

  overallRating?: number;

  comments?: string;

  created?: Date;

  isPublic?: boolean;

  reservationDtBegin?: Date;

  contactName?: string;

  contactReservationCount?: number;

  replied?: Date;

  reservation?: Reservation;

  static fromGrpcResponse(r: pbs.FeedbackFeedbackResponse, m?: FeedbackMapping): Feedback {
    const o = r.getFeedback();
    return Feedback.fromGrpcModel(o, m);
  }

  static fromGrpcModel(o?: pbm.Feedback, m?: FeedbackMapping): Feedback {
    const p = new Feedback();

    p.id = o?.getId() ?? 0;
    p.reservationId = o?.getReservationId();
    p.foodRating = o?.getFoodRating();
    p.serviceRating = o?.getServiceRating();
    p.ambienceRating = o?.getAmbienceRating();
    p.overallRating = o?.getOverallRating();
    p.comments = o?.getComments();
    p.created = o?.getDtCreate() ? new Date(o.getDtCreate() * 1000) : undefined;
    p.isPublic = o?.getIsPublic();

    p.reservationDtBegin = dateFromDateTimeString(o?.getReservationDtBegin()) ?? new Date();
    p.contactName = o?.getContactName();
    p.contactReservationCount = o?.getContactReservationCount();

    p.replied = o?.getDtRepliedAt() ? new Date(o.getDtRepliedAt() * 1000) : undefined;

    if (o?.getReservation()) {
      p.reservation = Reservation.fromGrpcModel(o?.getReservation(), m);
    }

    return p;
  }

  static compareFnc(type: FeedbackSortType): FeedbackSortFncType {
    const cmpnumf = (o1: Feedback, o2: Feedback, attrf: (o: Feedback) => number) => (attrf(o1) - attrf(o2) !== 0 ? attrf(o1) - attrf(o2) : o1.id - o2.id);
    const cmpstrf = (o1: Feedback, o2: Feedback, attrf: (o: Feedback) => string) => (strcmp(attrf(o1), attrf(o2)) !== 0 ? strcmp(attrf(o1), attrf(o2)) : o1.id - o2.id);

    switch (type) {
      case FeedbackSortType.Name: return (o1: Feedback, o2: Feedback) => cmpstrf(o1, o2, (o) => o.contactName ?? '');

      case FeedbackSortType.Food: return (o1: Feedback, o2: Feedback) => cmpnumf(o1, o2, (o) => o.foodRating ?? 0);
      case FeedbackSortType.Service: return (o1: Feedback, o2: Feedback) => cmpnumf(o1, o2, (o) => o.serviceRating ?? 0);
      case FeedbackSortType.Ambience: return (o1: Feedback, o2: Feedback) => cmpnumf(o1, o2, (o) => o.ambienceRating ?? 0);
      case FeedbackSortType.Overall: return (o1: Feedback, o2: Feedback) => cmpnumf(o1, o2, (o) => o.overallRating ?? 0);

      case FeedbackSortType.Created: return (o1: Feedback, o2: Feedback) => cmpnumf(o1, o2, (o) => (o.created ?? new Date(0)).getTime());
      case FeedbackSortType.Replied: return (o1: Feedback, o2: Feedback) => cmpnumf(o1, o2, (o) => (o.replied ?? new Date(0)).getTime());
      case FeedbackSortType.Visited: return (o1: Feedback, o2: Feedback) => cmpnumf(o1, o2, (o) => (o.reservationDtBegin ?? new Date(0)).getTime());

      case FeedbackSortType.Comments: return (o1: Feedback, o2: Feedback) => cmpstrf(o1, o2, (o) => o.comments ?? '');

      default: return (o1: Feedback, o2: Feedback) => cmpnumf(o1, o2, (o) => (o.created ?? new Date(0)).getTime());
    }
  }

  static compareFncDesc(type: FeedbackSortType): FeedbackSortFncType {
    const fnc = Feedback.compareFnc(type);
    return (o1: Feedback, o2: Feedback) => -fnc(o1, o2);
  }
}
