/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable vue/max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import {
  Customer, IntentStatus, IntentUsage, Invoice, InvoiceStatus, PaymentMethodType, PremiumBalanceTransaction, ProductType, SetupIntent,
} from '@/api/api-billing';
import {
  PaymentIntent as StripePaymentIntent, SetupIntent as StripeSetupIntent,
  PaymentIntentResult as StripePaymentIntentResult, SetupIntentResult as StripeSetupIntentResult,
  Appearance, StripeElements, StripeElementsOptionsClientSecret, StripeElementsOptionsMode, StripePaymentElementOptions, Stripe, loadStripe,
} from '@stripe/stripe-js';
import store from '@/store/store';
import i18n from '@/plugins/i18n';
import { IntentError, effectiveAmountDue, intentErrorText } from './billing-utils';

export const PAYMENT_METHOD_BANK_TRANSFER = 'PAYMENT_METHOD_BANK_TRANSFER';
export const NEW_PAYMENT_METHOD = 'NEW_PAYMENT_METHOD';

export enum PaymentContextMode {
  None = 'none',
  SubscriptionCreate = 'subscription-create',
  SubscriptionModify = 'subscription-modify',
  Invoice = 'invoice',
  Prices = 'prices',
}

export interface PaymentContext {
  customer: Customer,
  mode: PaymentContextMode,
  idempotencyKey: string,
  priceID?: string;
  previewInvoice?: Invoice;
  previewBalanceTransaction?: PremiumBalanceTransaction;
  paymentMethod?: string|StripeElements;
  paymentMethodType?: string
  invoice?: Invoice;
  setupIntent?: SetupIntent;
  priceIDs?: string[];
  activatePaymentMethod?: boolean;
  disableActivatePaymentMethod?: boolean;
  hideActivatePaymentMethod?: boolean;
  disallowPaymentMethods?: boolean;
  disallowNewPaymentMethod?: boolean;
  allowedPaymentMethodTypes?: string[];
  warningText?: string;
}

// load stripe
let stripe = undefined as Stripe | undefined;

export async function getStripe(): Promise<Stripe> {
  if (stripe) return stripe;

  const publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY as string;
  stripe = (await loadStripe(publishableKey, { locale: i18n.locale as any }))!;

  return stripe;
}

export async function createPaymentElements(p: { customer?: Customer, clientSecret?: string, invoice?: Invoice, darkTheme: boolean}): Promise<StripeElements | undefined> {
  const { customer, invoice } = p;
  const { clientSecret } = p;

  if (!clientSecret && !invoice) return undefined;

  // if (invoice?.paymentIntent?.clientSecret !== undefined && !invoice?.paymentIntent?.paymentMethodTypes?.includes(PaymentMethodType.CustomerBalance)) {
  //   clientSecret = invoice?.paymentIntent.clientSecret;
  // }

  const stripe = await getStripe();
  if (!stripe) throw new Error('Missing payment processor');

  const rules = {
    '.Input': {
      boxShadow: 'none',
      border: '2px #3355ff00 solid',
    },
    '.Input:focus': {
      boxShadow: 'none',
      border: '2px #3355ff solid',
    },
    '.Tab': {
      boxShadow: 'none',
      border: '2px #3355ff00 solid',
    },
    '.Tab:focus': {
      boxShadow: 'none',
    },
    '.Tab--selected:focus': {
      boxShadow: 'none',
    },
    '.Tab--selected': {
      boxShadow: 'none',
      border: '2px #3355ff solid',
    },
  };

  const appearanceLight: Appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: '#3355ff',
      colorPrimaryText: '#ffffff',
      borderRadius: '4px',
    },
    rules,
  };

  const appearanceDark: Appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#3355ff',
      colorPrimaryText: '#ffffff',
      borderRadius: '4px',
      colorBackground: '#30303b',
      colorTextSecondary: '#858593',
    },
    rules,
  };

  const appearance = p.darkTheme ? appearanceDark : appearanceLight;

  const elemenOptionsMode: StripeElementsOptionsMode = {
    mode: 'payment',
    appearance,
    currency: invoice?.currency,
    amount: invoice?.amountRemaining,
    paymentMethodTypes: invoice?.paymentMethodTypes?.filter((pmt) => pmt !== PaymentMethodType.CustomerBalance),
  };

  const elemenOptionsClientSecret: StripeElementsOptionsClientSecret = {
    clientSecret,
    appearance,
  };

  const paymentElemenOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      // radios: false,
      // spacedAccordionItems: true,
    },
  };

  if (customer) {
    paymentElemenOptions.defaultValues = {
      billingDetails: {
        name: customer.name || undefined,
        email: customer.email || undefined,
        // phone: customer.phone || undefined,
        address: {
          line1: customer.addressLine1 || undefined,
          line2: customer.addressLine1 || undefined,
          city: customer.city || undefined,
          state: customer.state || undefined,
          country: customer.country || undefined,
          postal_code: customer.postalCode || undefined,
        },
      },
    };
  }

  const elements = clientSecret ? stripe.elements(elemenOptionsClientSecret) : stripe.elements(elemenOptionsMode);
  elements.create('payment', paymentElemenOptions);

  return elements;
}

// confirm setup
export async function confirmSetup(
  clientSecret: string,
  paymentMethodIDOrElements: string | StripeElements,
  activate: boolean,
  invoiceID: string | undefined = undefined,
): Promise<SetupIntent> {
  const stripe = await getStripe();
  if (!stripe) throw new Error('Missing payment processor');

  const elements = typeof paymentMethodIDOrElements !== 'string' ? paymentMethodIDOrElements : undefined;
  // eslint-disable-next-line camelcase
  const payment_method = typeof paymentMethodIDOrElements === 'string' ? paymentMethodIDOrElements : undefined;

  // submit elements
  if (elements) {
    const { error: submitError } = await elements.submit();
    if (submitError) {
      throw submitError;
    }
  }

  // return url
  let url = window.location.href;
  url = url.slice(0, url.lastIndexOf('/'));
  url += '/payment-result?';
  if (invoiceID) url += `invoice=${invoiceID}`;
  if (invoiceID && activate) url += '&';
  if (activate) url += 'activate=yes';

  // eslint-disable-next-line camelcase
  const return_url = url;
  const response = await stripe.confirmSetup({
    elements,
    clientSecret,
    redirect: 'always',
    // eslint-disable-next-line camelcase
    confirmParams: { return_url, payment_method },
  });

  return handlePaymentResponse(response) as SetupIntent;
}

// confirm payment
export async function confirmPayment(
  clientSecret: string,
  paymentMethodIDOrElements: string | StripeElements,
  activate: boolean,
): Promise<StripePaymentIntent> {
  const stripe = await getStripe();
  if (!stripe) throw new Error('Missing payment processor');

  const elements = typeof paymentMethodIDOrElements !== 'string' ? paymentMethodIDOrElements : undefined;
  // eslint-disable-next-line camelcase
  const payment_method = typeof paymentMethodIDOrElements === 'string' ? paymentMethodIDOrElements : undefined;

  // submit elements
  if (elements) {
    const { error: submitError } = await elements.submit();
    if (submitError) {
      throw submitError;
    }
  }

  // return url
  let url = window.location.href;
  url = url.slice(0, url.lastIndexOf('/'));
  url += '/payment-result?';
  if (activate) url += 'activate=yes';

  // eslint-disable-next-line camelcase
  const return_url = url;
  const response = await stripe.confirmPayment({
    elements,
    clientSecret,
    redirect: 'always',
    // eslint-disable-next-line camelcase
    confirmParams: { return_url, payment_method },
  });

  return handlePaymentResponse(response) as StripePaymentIntent;
}

export async function confirmPaymentBankTransfer(
  clientSecret: string,
) {
  const stripe = await getStripe();
  if (!stripe) throw new Error('Missing payment processor');

  const response = await stripe.confirmCustomerBalancePayment(
    clientSecret,
    {
      payment_method: {
        customer_balance: {},
      },
      payment_method_options: {
        customer_balance: {
          funding_type: 'bank_transfer',
          bank_transfer: {
            type: 'eu_bank_transfer',
            eu_bank_transfer: {
              country: 'NL',
            },
          },
        },
      },
    },
    {
      handleActions: false,
    },
  );

  return handlePaymentResponse(response) as StripePaymentIntent;
}

function handlePaymentResponse(response: StripePaymentIntentResult | StripeSetupIntentResult): StripeSetupIntent | StripePaymentIntent {
  const { error } = response;
  const { paymentIntent } = response as StripePaymentIntentResult;
  const { setupIntent } = response as StripeSetupIntentResult;

  if (error) {
    const {
      type, message, code, decline_code: declineCode,
    } = error;
    const paymentError = {
      type, message, code, declineCode,
    } as IntentError;

    const text = intentErrorText(paymentError);
    if (text) throw new Error(text);
  }

  const intent = paymentIntent ?? setupIntent;

  if (intent?.status === 'succeeded'
  || (intent?.status === 'requires_action' && intent.next_action?.type === 'display_bank_transfer_instructions')
  || intent?.status === 'processing') {
    return intent;
  }

  const text = intentErrorText({ type: 'operation_failed' });
  throw new Error(text);
}

export async function preparePayment(data: PaymentContext) {
  // check price and create preview invoice if needed (if not wizard plan selector)
  if (data.mode === PaymentContextMode.SubscriptionModify || data.mode === PaymentContextMode.SubscriptionCreate) {
    if (!data.priceID) throw new Error('Prepare payment: missing price');
    if (!data.previewInvoice) {
      const { invoice } = await store.dispatch('billingPreviewSubscription', { priceID: data.priceID, invoiceOnly: true });
      data.previewInvoice = invoice;
    }
    data.setupIntent = await store.dispatch('billingAddPaymentMethod');
  }

  // check price(s) and create invoice from prices if invoice not created yet
  if (data.mode === PaymentContextMode.Prices && !data.invoice) {
    if (!data.priceIDs) throw new Error('Prepare payment: missing prices');
    data.invoice = await store.dispatch('billingCreatePaymentInvoice', { priceIDs: data.priceIDs });
    if (!data.invoice) throw new Error('Prepare payment: missing invoice for prices');
  }

  // check invoice
  if (data.mode === PaymentContextMode.Invoice && !data.invoice) {
    throw new Error('Prepare payment: missing invoice for prices');
  }

  // payment methods params: disallow activate, disable activate, default activate, disallow new
  const isOffSession = data.mode === PaymentContextMode.SubscriptionCreate || data.mode === PaymentContextMode.SubscriptionModify
    || (data.mode === PaymentContextMode.Invoice && data.invoice?.paymentIntent?.usage === IntentUsage.OffSession);

  data.disableActivatePaymentMethod = !data.setupIntent;
  data.activatePaymentMethod = isOffSession;
  data.hideActivatePaymentMethod = !data.setupIntent;
  data.disallowNewPaymentMethod = false;

  // disallow all payment metods for 0 amount invoices
  if (data.invoice && !data.setupIntent) {
    const balance = data.customer.balance ?? 0;
    const cashBalance = -(data.customer.cashBalances?.find((cb) => cb.currency === data.invoice?.currency)?.amount ?? 0);
    const { amountDue } = effectiveAmountDue(data.invoice, { balance, cashBalance });
    data.disallowPaymentMethods = !amountDue || undefined;
  }

  // allowed payment method types: from invoice payment intent, invoice or preview invoice
  data.allowedPaymentMethodTypes = data.invoice?.paymentIntent?.paymentMethodTypes
  ?? data.invoice?.paymentMethodTypes
  ?? data.previewInvoice?.paymentMethodTypes
  ?? undefined;
}

export async function submitPayment(data: PaymentContext) {
  const setupNewPaymentMethod = !!data.paymentMethod && typeof data.paymentMethod !== 'string';
  let methodAlreadyActivated = false;

  // 1.) activate payment method if not new and requested
  if (!setupNewPaymentMethod && data.activatePaymentMethod) {
    const paymentMethodID = data.paymentMethod === PAYMENT_METHOD_BANK_TRANSFER ? undefined : data.paymentMethod;
    await store.dispatch('billingActivatePaymentMethod', { paymentMethodID });
    methodAlreadyActivated = true;

    // invoice might get paid if from subscription - get fresh one
    if (data.invoice?.paymentIntent?.usage === IntentUsage.OffSession) {
      data.invoice = await store.dispatch('billingFinishPaymentInvoice', { invoiceID: data.invoice.ID });
    }
  }

  // 2a.) create or modify subscription and create invoice
  if ((data.mode === PaymentContextMode.SubscriptionCreate || data.mode === PaymentContextMode.SubscriptionModify) && !data.invoice) {
    // subscription and no invoice -> create/modify subscription
    let premiumCreditAmount = data.previewBalanceTransaction?.amount ?? undefined;
    if (premiumCreditAmount) premiumCreditAmount = -premiumCreditAmount;
    const paymentMethodTypes = [data.paymentMethodType!];
    const deactivatePaymentMethod = setupNewPaymentMethod && data.activatePaymentMethod;

    const action = data.mode === 'subscription-create' ? 'billingCreateSubscription' : 'billingUpdateSubscription';
    const customer: Customer = await store.dispatch(action, {
      priceID: data.priceID, premiumCreditAmount, idempotencyKey: data.idempotencyKey, paymentMethodTypes, deactivatePaymentMethod,
    });

    data.invoice = customer.subscription?.invoice;
  }

  // 2b.) finalize draft invoice
  if (data.mode === PaymentContextMode.Prices && data.invoice?.status === InvoiceStatus.Draft) {
    const paymentMethodTypes = data.paymentMethod === PAYMENT_METHOD_BANK_TRANSFER ? [PaymentMethodType.CustomerBalance] : [];
    data.invoice = await store.dispatch('billingFinalizePaymentInvoice', { invoiceID: data.invoice.ID, paymentMethodTypes });
  }

  // check invoice exists
  if (!data.invoice) throw new Error('Submit payment: missing invoice');
  if (data.invoice.status === InvoiceStatus.Draft) throw new Error('Submit payment: draft invoice');
  // const invoiceOpen = [InvoiceStatus.Open, InvoiceStatus.Uncollectible].includes(data.invoice?.status!);
  const invoiceOpen = [IntentStatus.RequiresPaymentMethod, IntentStatus.RequiresConfirmation, IntentStatus.RequiresAction].includes(data.invoice?.paymentIntent?.status ?? IntentStatus.None);

  // 3.) bank transfer -> confirm and finalize invoice
  if (data.paymentMethod === PAYMENT_METHOD_BANK_TRANSFER) {
    if (invoiceOpen && (data.invoice?.paymentIntent?.nextAction?.displayIBANBankTransferInstructions === undefined || data.invoice.amountDue === 0)) {
      // give stripe time to process invoice
      if (data.mode === PaymentContextMode.SubscriptionCreate || data.mode === PaymentContextMode.SubscriptionModify) {
        await new Promise((resolve) => { setTimeout(resolve, 5000); });
      }
      data.invoice = await store.dispatch('billingFinishPaymentInvoice', { invoiceID: data.invoice.ID, bankTransfer: true });
    }
    return;
  }

  // 4.) confirm payment by existing or new payment method
  if (invoiceOpen && (!data.setupIntent || methodAlreadyActivated)) {
    let activate = data.activatePaymentMethod && !methodAlreadyActivated;
    const intent = await confirmPayment(data.invoice.paymentIntent?.clientSecret!, data.paymentMethod!, activate ?? false);

    activate = activate && intent.setup_future_usage === 'off_session';
    const invoiceID = data.invoice.ID;
    data.invoice = await store.dispatch('billingFinishPaymentInvoice', { invoiceID, activate });
    return;
  }

  // 5.) confirm setup if new payment method
  if (data.setupIntent && setupNewPaymentMethod) {
    // payment elements with setup intent with client secret
    const setupIntent = await confirmSetup(data.setupIntent.clientSecret!, data.paymentMethod!, data.activatePaymentMethod ?? false, data.invoice.ID);

    // finish setup intent
    const activate = data.activatePaymentMethod;
    const intentID = setupIntent.ID;
    data.setupIntent = await store.dispatch('billingFinishPaymentMethod', { intentID, activate });

    // check invoice -> subscription might get paid
    if (invoiceOpen) {
      const invoiceID = data.invoice.ID;
      data.invoice = await store.dispatch('billingFinishPaymentInvoice', { invoiceID });
    }
  }
}

export async function cancelPayment(data: PaymentContext) {
  if (data.invoice?.status === InvoiceStatus.Draft) {
    await store.dispatch('billingDeletePaymentInvoice', { invoiceID: data.invoice.ID });
  }
}
