/* eslint-disable class-methods-use-this */

import i18n from '@/plugins/i18n';
import { MenuItem } from '@/util/menu';
import Vue, { Component } from 'vue';

// order is important, it is used to sort the apps in the view
export enum GstplnAppCategory {
  Extensions = 'extensions',
  BookingPartners = 'booking_partners',
  Integrations = 'integrations',
  Payments = 'payments',
}

export default abstract class GstplnApp {
  vue: Vue;

  id: string;

  url: string;

  icon?: string;

  installed: boolean = false;

  hasMenuItem: boolean = false;

  categories: string[] = [];

  allowedCountries: string[] = [];

  disallowedCountries: string[] = [];

  configComponentImportFnc?: ()=>any;

  appConfigRoute?: string;

  configComponentLoaded: boolean = false;

  configBeforeInstall: boolean = true;

  routeConfig?: {title?: string, titleKey?: string, route: string, f: ()=>any}[];

  routeComponentsLoaded: boolean = false;

  constructor(
    vue: Vue,
    id: string,
    p?: {
      hasMenuItem?: boolean,
      subitems?: MenuItem[],
      configComponentImportFnc?: ()=>any, configBeforeInstall?: boolean,
      routeConfig?: {title?:string, titleKey?: string, route: string, f: ()=>any}[],
      appConfigRoute?: string,
      categories?: string[], allowedCountries?: string[], disallowedCountries?: string[],
    },
  ) {
    this.vue = vue;
    this.id = id;
    this.url = `/apps/${this.id.replace(/^(app\.)/, '')}`;

    this.hasMenuItem = p?.hasMenuItem ?? false;

    this.appConfigRoute = p?.appConfigRoute;
    this.configComponentImportFnc = p?.configComponentImportFnc ?? undefined;
    this.configBeforeInstall = p?.configBeforeInstall ?? true;
    this.routeConfig = p?.routeConfig ?? undefined;

    this.categories = p?.categories ?? [];
    this.allowedCountries = p?.allowedCountries ?? [];
    this.disallowedCountries = p?.disallowedCountries ?? [];
  }

  install() {
    if (this.routeConfig) this.installRoute(this.vue);
    this.loadConfigComponent();
    this.loadRouteComponent();
    this.installed = true;
  }

  uninstall() {
    if (this.routeConfig) this.uninstallRoute(this.vue);
    this.installed = false;
  }

  get isInstalled(): boolean { return this.installed; }

  protected installRoute(vue: Vue) {
    this.routeConfig?.forEach((rc) => {
      const titleKey = rc.titleKey ?? `${this.id}.title`;
      vue.$router.addRoute(
        {
          path: rc.route.startsWith('/') ? rc.route : `${this.url}/${rc.route}`,
          name: `app-${this.id}-${rc.route}`,
          component: Vue.component('AppContent', {
            data() { return { }; },
            render: (h, _) => h(`${this.id}.${rc.route}`),
          }),
          meta: {
            title: i18n.t(titleKey),
            titleKey,
          },
        },
      );
    });
  }

  get apiName(): string {
    return this.id;
  }

  protected uninstallRoute(vue: Vue) {
    this.routeConfig?.forEach((rc) => {
      vue.$router.addRoute(
        {
          path: `${this.url}/${rc.route}`,
          name: `app-${this.id}-${rc.route}`,
          redirect: '/apps/manage',
        },
      );
    });
  }

  description(): string {
    return i18n.tc(`${this.id}.description`);
  }

  title(): string {
    return i18n.tc(`${this.id}.title`);
  }

  loadConfigComponent() {
    if (!this.configComponentImportFnc || this.configComponentLoaded) return;
    Vue.component(`${this.id}.cfg`, this.configComponentImportFnc);
    this.configComponentLoaded = true;
  }

  configComponent(): string | undefined {
    return this.configComponentImportFnc ? `${this.id}.cfg` : undefined;
  }

  loadRouteComponent() {
    if (!this.routeConfig || this.routeComponentsLoaded) return;
    this.routeConfig.forEach((rc) => {
      Vue.component(`${this.id}.${rc.route}`, rc.f);
    });
    this.routeComponentsLoaded = true;
  }
}
