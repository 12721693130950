
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import Reservation from '@/model/Reservation';
import Contact from '@/model/Contact';
import {
  mapByDateIndexToMapByDate, toSortedMapByDateIndex,
} from '@/services/reservations-utils';
import GuestResultsList from '@/components/views/guests/GuestResultsList.vue';

export default Vue.extend({
  name: 'ReservationResultsDialog',
  components: { GuestResultsList },
  mixins: [DialogClosable],
  props: {
    contact: { type: Object as PropType<Contact | null>, required: false, default: null },
    reservations: { type: Array as PropType<Reservation[] | null>, required: false, default: null },
  },
  data() {
    return { };
  },
  computed: {
    title(): string {
      return this.$t('title.all_reservations_from_x', [this.contact?.name ?? '']) as string;
    },
    reservationsByDate(): Map<Date, Reservation[]> {
      const mapByDateIndex = toSortedMapByDateIndex(this.reservations ?? []);
      const mapByDate = mapByDateIndexToMapByDate(mapByDateIndex);
      return mapByDate;
    },
  },
  watch: { },
  methods: {
    handleUnsavedChanges(shouldClose: boolean) {
      if (shouldClose) { this.$emit('close'); }
    },
  },
});
